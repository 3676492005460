import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const PlusOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6501 5.0001C12.6501 4.64111 12.3591 4.3501 12.0001 4.3501C11.6411 4.3501 11.3501 4.64111 11.3501 5.0001L11.3501 11.3501H5.0001C4.64111 11.3501 4.3501 11.6411 4.3501 12.0001C4.3501 12.3591 4.64111 12.6501 5.0001 12.6501H11.3501L11.3501 19.0001C11.3501 19.3591 11.6411 19.6501 12.0001 19.6501C12.3591 19.6501 12.6501 19.3591 12.6501 19.0001V12.6501H19.0001C19.3591 12.6501 19.6501 12.3591 19.6501 12.0001C19.6501 11.6411 19.3591 11.3501 19.0001 11.3501H12.6501V5.0001Z"
        fill={color}
      />
    </svg>
  );
};

const PlusSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 7C12.359 7 12.65 7.29102 12.65 7.65L12.65 11.35H16.35C16.709 11.35 17 11.641 17 12C17 12.359 16.709 12.65 16.35 12.65H12.65L12.65 16.35C12.65 16.709 12.359 17 12 17C11.641 17 11.35 16.709 11.35 16.35L11.35 12.65H7.65C7.29101 12.65 7 12.359 7 12C7 11.641 7.29101 11.35 7.65 11.35H11.35L11.35 7.65C11.35 7.29102 11.641 7 12 7Z"
        fill={color}
      />
    </svg>
  );
};

export const PlusIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={PlusOutlined}
      solidSvg={PlusSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
