import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const ExitIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.48 15.21C17.3 15.21 17.13 15.14 17 15C16.76 14.73 16.78 14.32 17.04 14.08L18.5976 12.5945H13.65C13.291 12.5945 13 12.3035 13 11.9445C13 11.5855 13.291 11.2945 13.65 11.2945H18.5906L17.04 9.92C16.77 9.68 16.76 9.27 17 9C17.24 8.74 17.65 8.72 17.92 8.96L20.63 11.4245C20.94 11.7045 20.94 12.1845 20.63 12.4645L17.92 15.04C17.79 15.16 17.64 15.21 17.48 15.21Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 4.3H8C7.84802 4.3 7.70069 4.31994 7.5605 4.35736L9.66747 5.56135C10.275 5.90853 10.65 6.55465 10.65 7.25442V16.6361C10.65 17.3359 10.275 17.982 9.66747 18.3292L7.43608 19.6042C7.61255 19.6663 7.80233 19.7 8 19.7H15C15.9389 19.7 16.7 18.9389 16.7 18V17.65C16.7 17.291 16.991 17 17.35 17C17.709 17 18 17.291 18 17.65V18C18 19.6569 16.6569 21 15 21H8C6.34315 21 5 19.6569 5 18V6C5 4.34315 6.34315 3 8 3H15C16.6569 3 18 4.34315 18 6V6.35C18 6.70899 17.709 7 17.35 7C16.991 7 16.7 6.70899 16.7 6.35V6C16.7 5.06112 15.9389 4.3 15 4.3ZM6.43982 18.6763L9.02249 17.2004C9.22501 17.0847 9.35 16.8693 9.35 16.6361V7.25442C9.35 6.32801 7.13425 5.61107 6.48025 5.23735C6.36493 5.46671 6.3 5.72577 6.3 6V18C6.3 18.2403 6.34987 18.469 6.43982 18.6763Z"
        fill={color}
      />
    </svg>
  );
};

const ExitIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 4.3H15C15.9389 4.3 16.7 5.06112 16.7 6V6.35C16.7 6.70899 16.991 7 17.35 7C17.709 7 18 6.70899 18 6.35V6C18 4.34315 16.6569 3 15 3H8C6.34315 3 5 4.34315 5 6V18C5 19.6569 6.34315 21 8 21H15C16.6569 21 18 19.6569 18 18V17.65C18 17.291 17.709 17 17.35 17C16.991 17 16.7 17.291 16.7 17.65V18C16.7 18.9389 15.9389 19.7 15 19.7H8C7.80233 19.7 7.61255 19.6663 7.43608 19.6042L9.66747 18.3292C10.275 17.982 10.65 17.3359 10.65 16.6361V7.25442C10.65 6.55465 10.275 5.90853 9.66747 5.56135L7.5605 4.35736C7.70069 4.31994 7.84802 4.3 8 4.3Z"
        fill={color}
      />
      <path
        d="M17 15C17.13 15.14 17.3 15.21 17.48 15.21C17.64 15.21 17.79 15.16 17.92 15.04L20.63 12.4645C20.94 12.1845 20.94 11.7045 20.63 11.4245L17.92 8.96C17.65 8.72 17.24 8.74 17 9C16.76 9.27 16.77 9.68 17.04 9.92L18.5906 11.2945H13.65C13.291 11.2945 13 11.5855 13 11.9445C13 12.3035 13.291 12.5945 13.65 12.5945H18.5976L17.04 14.08C16.78 14.32 16.76 14.73 17 15Z"
        fill={color}
      />
    </svg>
  );
};

export const ExitIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={ExitIconOutlined}
      solidSvg={ExitIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
