import React from 'react';
import { BasicIcon } from '../_utils/type';
import avatarWorkspace from '../assets/icons/avatar-workspace-icons.svg';

export const AvatarWorkspaceIcon = ({ className, size = '20px', onClick }: BasicIcon) => (
  <img
    onClick={onClick}
    className={className}
    src={avatarWorkspace}
    alt=""
    style={{
      borderRadius: '50%',
      backgroundColor: '#ffffff',
      width: size,
      height: size,
    }}
  />
);
