import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const ImageInsideIconOutlined = ({ color = '#626F84', size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.43529 3.20837C4.13613 3.20837 3.89362 3.45089 3.89362 3.75004C3.89362 4.04919 4.13613 4.29171 4.43529 4.29171H15.5583C15.8575 4.29171 16.1 4.04919 16.1 3.75004C16.1 3.45089 15.8575 3.20837 15.5583 3.20837H4.43529Z"
        fill={color}
      />
      <path
        d="M4.43529 15.7084C4.13613 15.7084 3.89362 15.9509 3.89362 16.25C3.89362 16.5492 4.13613 16.7917 4.43529 16.7917H15.5583C15.8575 16.7917 16.1 16.5492 16.1 16.25C16.1 15.9509 15.8575 15.7084 15.5583 15.7084H4.43529Z"
        fill={color}
      />
      <path
        d="M4.43327 8.16671C4.13412 8.16671 3.89161 7.92419 3.89161 7.62504C3.89161 7.32589 4.13412 7.08337 4.43327 7.08337L8.59994 7.08337C8.89909 7.08337 9.14161 7.32589 9.14161 7.62504C9.14161 7.92419 8.89909 8.16671 8.59994 8.16671L4.43327 8.16671Z"
        fill={color}
      />
      <path
        d="M4.43327 10.5417C4.13411 10.5417 3.8916 10.2992 3.8916 10C3.8916 9.70089 4.13411 9.45837 4.43327 9.45837L8.59993 9.45837C8.89909 9.45837 9.1416 9.70089 9.1416 10C9.1416 10.2992 8.89909 10.5417 8.59993 10.5417L4.43327 10.5417Z"
        fill={color}
      />
      <path
        d="M5.55827 12.375C5.55827 12.6742 5.80079 12.9167 6.09994 12.9167H8.59994C8.89909 12.9167 9.14161 12.6742 9.14161 12.375C9.14161 12.0759 8.89909 11.8334 8.59994 11.8334H6.09994C5.80079 11.8334 5.55827 12.0759 5.55827 12.375Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.0167 7.0834H11.35C10.7517 7.0834 10.2667 7.56842 10.2667 8.16673V11.8334C10.2667 12.4317 10.7517 12.9167 11.35 12.9167H15.0167C15.615 12.9167 16.1 12.4317 16.1 11.8334V8.16673C16.1 7.56842 15.615 7.0834 15.0167 7.0834ZM11.35 8.16673H15.0167V11.8334L11.35 11.8334V8.16673Z"
        fill={color}
      />
    </svg>
  );
};
export const ImageInsideIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={ImageInsideIconOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
