import React from 'react';
import { BasicIcon } from '../../../_utils/type';

interface VkontakteIntegrationsIconProps extends BasicIcon {
  size: 'small' | 'large';
}

export const VkontakteIntegrationsIcon = ({ size, className }: VkontakteIntegrationsIconProps) => (
  <svg
    className={className}
    style={size === 'large' ? { width: '171', height: '30' } : { width: '139', height: '24' }}
    viewBox="0 0 171 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1637_42852)">
      <path
        d="M0 14.375C0 7.59856 0 4.21034 2.1021 2.10517C4.20419 0 7.58747 0 14.354 0H15.6022C22.3687 0 25.752 0 27.8541 2.10517C29.9562 4.21034 29.9562 7.59856 29.9562 14.375V15.625C29.9562 22.4014 29.9562 25.7897 27.8541 27.8948C25.752 30 22.3687 30 15.6022 30H14.354C7.58747 30 4.20419 30 2.1021 27.8948C0 25.7897 0 22.4014 0 15.625L0 14.375Z"
        fill="#2787F5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.11371 9.375H5.9289C5.30466 9.375 5.17981 9.66925 5.17981 9.99363C5.17981 10.5731 5.92051 13.4467 8.62864 17.2474C10.4341 19.843 12.9777 21.25 15.2924 21.25C16.6812 21.25 16.853 20.9375 16.853 20.3993V18.4375C16.853 17.8125 16.9846 17.6877 17.4243 17.6877C17.7484 17.6877 18.3039 17.85 19.6001 19.1014C21.0815 20.5846 21.3257 21.25 22.1589 21.25H24.3437C24.968 21.25 25.2801 20.9375 25.1001 20.3208C24.903 19.7062 24.1958 18.8144 23.2573 17.7573C22.748 17.1547 21.9842 16.5059 21.7527 16.1814C21.4287 15.7642 21.5212 15.5788 21.7527 15.2081C21.7527 15.2081 24.4146 11.4537 24.6923 10.1791C24.8312 9.71556 24.6923 9.375 24.0316 9.375H21.8468C21.2913 9.375 21.0352 9.66925 20.8963 9.99363C20.8963 9.99363 19.7853 12.7051 18.2113 14.4664C17.7021 14.9762 17.4706 15.1385 17.1928 15.1385C17.054 15.1385 16.8529 14.9763 16.8529 14.5128V10.1791C16.8529 9.62288 16.6917 9.375 16.2287 9.375H12.7954C12.4483 9.375 12.2395 9.63312 12.2395 9.87781C12.2395 10.4051 13.0265 10.5267 13.1076 12.0099V15.2313C13.1076 15.9375 12.9802 16.0656 12.7024 16.0656C11.9618 16.0656 10.1601 13.3419 9.09153 10.2254C8.88215 9.61969 8.67208 9.375 8.11371 9.375Z"
        fill="white"
      />
      <path
        d="M51.1079 14.7373C52.2959 14.1821 53.0614 13.2568 53.0614 11.8292C53.0614 9.6613 51.0815 8.12793 48.2304 8.12793H41.6571V21.8754H48.5208C51.4775 21.8754 53.5366 20.2627 53.5366 18.0684C53.5366 16.4029 52.4543 15.1603 51.1079 14.7373ZM44.693 10.6395H48.2304C49.2864 10.6395 50.0256 11.274 50.0256 12.1464C50.0256 13.0189 49.2864 13.6269 48.2304 13.6269H44.693V10.6395ZM48.5208 19.3639H44.693V16.0063H48.5208C49.7088 16.0063 50.5007 16.6937 50.5007 17.6983C50.5007 18.7029 49.7088 19.3639 48.5208 19.3639Z"
        fill="black"
      />
      <path
        d="M64.8274 21.8754H68.8135L62.504 14.7373L68.2855 8.12793H64.6689L60.0755 13.4683H59.3363V8.12793H56.3004V21.8754H59.3363V16.4029H60.0755L64.8274 21.8754Z"
        fill="black"
      />
      <path
        d="M76.5622 22.1395C80.8652 22.1395 83.9014 19.1785 83.9014 15.0014C83.9014 10.8243 80.8652 7.86328 76.5622 7.86328C72.2591 7.86328 69.2235 10.8243 69.2235 15.0014C69.2235 19.1785 72.2591 22.1395 76.5622 22.1395ZM76.5622 19.3636C74.0808 19.3636 72.3914 17.5923 72.3914 15.0014C72.3914 12.4105 74.0808 10.6392 76.5622 10.6392C79.0441 10.6392 80.7336 12.4105 80.7336 15.0014C80.7336 17.5923 79.0441 19.3636 76.5622 19.3636Z"
        fill="black"
      />
      <path
        d="M95.7272 8.12793V13.5476H89.5762V8.12793H86.5406V21.8754H89.5762V16.3236H95.7272V21.8754H98.7634V8.12793H95.7272Z"
        fill="black"
      />
      <path d="M105.621 21.8754H108.657V10.9039H113.409V8.12793H100.87V10.9039H105.621V21.8754Z" fill="black" />
      <path
        d="M120.598 7.86328C117.932 7.86328 115.582 9.23803 114.896 11.6174L117.694 12.0933C118.117 11.2473 119.067 10.4542 120.44 10.4542C122.103 10.4542 123.079 11.6438 123.212 13.4416H120.255C116.216 13.4416 114.209 15.2129 114.209 17.7509C114.209 20.3153 116.19 22.1395 118.988 22.1395C121.232 22.1395 122.736 20.8441 123.397 19.7073L124.03 21.8752H126.274V14.1554C126.274 10.1633 124.109 7.86328 120.598 7.86328ZM119.701 19.5751C118.222 19.5751 117.272 18.8084 117.272 17.6187C117.272 16.5612 118.037 15.821 120.36 15.821H123.238C123.238 18.0682 121.813 19.5751 119.701 19.5751Z"
        fill="black"
      />
      <path
        d="M138.094 21.8754H142.08L135.771 14.7373L141.552 8.12793H137.936L133.342 13.4683H132.603V8.12793H129.567V21.8754H132.603V16.4029H133.342L138.094 21.8754Z"
        fill="black"
      />
      <path d="M147.231 21.8754H150.267V10.9039H155.019V8.12793H142.479V10.9039H147.231V21.8754Z" fill="black" />
      <path
        d="M170.075 14.8428C170.075 10.8772 167.118 7.86328 162.948 7.86328C158.776 7.86328 155.82 10.8507 155.82 15.0014C155.82 19.1521 158.776 22.1395 162.948 22.1395C165.798 22.1395 168.491 20.7912 169.52 18.1739L166.67 17.698C166.009 18.6762 164.742 19.3636 163.132 19.3636C160.809 19.3636 159.278 17.936 158.988 15.821H170.022C170.048 15.5302 170.075 15.2129 170.075 14.8428ZM162.948 10.507C164.848 10.507 166.273 11.7232 166.775 13.4416H159.172C159.674 11.6703 161.1 10.507 162.948 10.507Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1637_42852">
        <rect width="171" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
