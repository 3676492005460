import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const MenuIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99365 5.74863C3.99365 5.38965 4.28467 5.09863 4.64365 5.09863L19.3562 5.09863C19.7152 5.09863 20.0062 5.38965 20.0062 5.74863C20.0062 6.10762 19.7152 6.39863 19.3562 6.39863L4.64365 6.39863C4.28467 6.39863 3.99365 6.10762 3.99365 5.74863Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99365 18.3648C3.99365 18.0059 4.28467 17.7148 4.64365 17.7148L19.3562 17.7148C19.7152 17.7148 20.0062 18.0059 20.0062 18.3648C20.0062 18.7238 19.7152 19.0148 19.3562 19.0148L4.64365 19.0148C4.28467 19.0148 3.99365 18.7238 3.99365 18.3648Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99369 12C3.99369 11.641 4.28471 11.35 4.64369 11.35L19.3562 11.35C19.7152 11.35 20.0062 11.641 20.0062 12C20.0062 12.359 19.7152 12.65 19.3562 12.65L4.64369 12.65C4.28471 12.65 3.99369 12.359 3.99369 12Z"
        fill={color}
      />
    </svg>
  );
};

const MenuIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM8.60304 7.99999C8.24406 7.99999 7.95304 8.29101 7.95304 8.64999C7.95304 9.00898 8.24406 9.29999 8.60304 9.29999L15.3969 9.29999C15.7559 9.29999 16.0469 9.00898 16.0469 8.64999C16.0469 8.291 15.7559 7.99999 15.3969 7.99999H8.60304ZM8.60304 14.6959C8.24406 14.6959 7.95304 14.9869 7.95304 15.3459C7.95304 15.7049 8.24406 15.9959 8.60304 15.9959L15.3969 15.9959C15.7559 15.9959 16.0469 15.7049 16.0469 15.3459C16.0469 14.9869 15.7559 14.6959 15.3969 14.6959H8.60304ZM7.95304 12C7.95304 11.641 8.24406 11.35 8.60304 11.35L15.3969 11.35C15.7559 11.35 16.0469 11.641 16.0469 12C16.0469 12.359 15.7559 12.65 15.3969 12.65L8.60304 12.65C8.24406 12.65 7.95304 12.359 7.95304 12Z"
        fill={color}
      />
    </svg>
  );
};

export const MenuIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={MenuIconOutlined}
      solidSvg={MenuIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
