import React from 'react';

export type TypographyProps<TComponent extends React.ElementType | React.ComponentType> = React.PropsWithChildren<{
  component?: TComponent;
  componentProps?: React.ComponentProps<TComponent>;
  font?: string;
  size?: string;
  color?: string;
  weight?: number | boolean;
  align?: 'left' | 'right' | 'center';
  ellips?: boolean;
  underline?: boolean;
}>;
