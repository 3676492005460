import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const DeleteOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.4801 9.12009C14.4801 8.76111 14.1891 8.47009 13.8301 8.47009C13.4712 8.47009 13.1801 8.76111 13.1801 9.12009V15.4701C13.1801 15.8291 13.4712 16.1201 13.8301 16.1201C14.1891 16.1201 14.4801 15.8291 14.4801 15.4701V9.12009Z"
        fill={color}
      />
      <path
        d="M10.1802 8.47009C10.5391 8.47009 10.8302 8.76111 10.8302 9.12009V15.4701C10.8302 15.8291 10.5391 16.1201 10.1802 16.1201C9.82117 16.1201 9.53015 15.8291 9.53015 15.4701V9.12009C9.53015 8.76111 9.82117 8.47009 10.1802 8.47009Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 2.0199C12.3592 2.0199 12.6502 2.31091 12.6502 2.6699V4.65002H19.6802C20.0392 4.65002 20.3302 4.94104 20.3302 5.30002C20.3302 5.65901 20.0392 5.95002 19.6802 5.95002H18.896L18.1202 17.44C18.0302 19.02 16.7102 20.25 15.1302 20.25H8.77018C7.19018 20.25 5.88018 19.02 5.78018 17.44L5.0228 5.95002H4.33018C3.97119 5.95002 3.68018 5.65901 3.68018 5.30002C3.68018 4.94104 3.97119 4.65002 4.33018 4.65002H11.3502V2.6699C11.3502 2.31091 11.6412 2.0199 12.0002 2.0199ZM6.37791 5.95002L7.08018 17.35C7.14018 18.25 7.88018 18.95 8.78018 18.95H15.1402C16.0402 18.95 16.7802 18.25 16.8402 17.36L17.5425 5.95002H6.37791Z"
        fill={color}
      />
    </svg>
  );
};

const DeleteSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6502 2.6699C12.6502 2.31091 12.3592 2.0199 12.0002 2.0199C11.6412 2.0199 11.3502 2.31091 11.3502 2.6699V4.65002H4.33018C3.97119 4.65002 3.68018 4.94104 3.68018 5.30002C3.68018 5.65901 3.97119 5.95002 4.33018 5.95002H5.0228L5.78018 17.44C5.88018 19.02 7.19018 20.25 8.77018 20.25H15.1302C16.7102 20.25 18.0302 19.02 18.1202 17.44L18.896 5.95002H19.6802C20.0392 5.95002 20.3302 5.65901 20.3302 5.30002C20.3302 4.94104 20.0392 4.65002 19.6802 4.65002H12.6502V2.6699ZM10.8302 9.12009C10.8302 8.76111 10.5391 8.47009 10.1802 8.47009C9.82117 8.47009 9.53015 8.76111 9.53015 9.12009V15.4701C9.53015 15.8291 9.82117 16.1201 10.1802 16.1201C10.5391 16.1201 10.8302 15.8291 10.8302 15.4701V9.12009ZM13.8301 8.47009C14.1891 8.47009 14.4801 8.76111 14.4801 9.12009V15.4701C14.4801 15.8291 14.1891 16.1201 13.8301 16.1201C13.4712 16.1201 13.1801 15.8291 13.1801 15.4701V9.12009C13.1801 8.76111 13.4712 8.47009 13.8301 8.47009Z"
        fill={color}
      />
    </svg>
  );
};

export const DeleteIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={DeleteOutlined}
      solidSvg={DeleteSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
