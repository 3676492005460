import React from 'react';

export const LoginFormPlarinLogo: React.VFC = function LoginFormPlarinLogo() {
  return (
    <svg width="107" height="24" viewBox="0 0 107 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M33.7305 2.40234V21.6H37.8912V15.5467H41.6113C45.7996 15.5467 48.7065 12.9125 48.7065 8.97453C48.7065 5.02324 45.8961 2.40234 41.8179 2.40234H33.7305ZM37.8912 5.58201H40.7156C43.0993 5.58201 44.4769 6.80597 44.4769 8.98783C44.4769 11.183 43.0855 12.407 40.7018 12.407H37.8912V5.58201Z"
        fill="#214ecb"
      />
      <path d="M51.4883 21.6H55.5113V2.40234H51.4883V21.6Z" fill="#214ecb" />
      <path
        d="M64.4875 19.0191C63.1237 19.0191 62.2142 18.354 62.2142 17.2896C62.2142 16.2785 63.0685 15.6266 64.5703 15.5202L67.6841 15.3339V16.3717C67.6841 17.9149 66.2372 19.0191 64.4875 19.0191ZM63.1896 21.6001C65.0359 21.6001 66.8436 20.9349 67.6703 19.4183H67.7528V21.6001H71.6382V11.7951C71.6382 8.9214 69.172 7.03223 65.3832 7.03223C61.4702 7.03223 59.0317 8.93471 58.8802 11.702H62.5587C62.7516 10.6509 63.7024 9.93251 65.2042 9.93251C66.7197 9.93251 67.6841 10.7042 67.6841 12.0345V12.9791L63.9642 13.192C60.2854 13.4049 58.2188 14.9082 58.2188 17.5025C58.2188 20.0702 60.3792 21.6001 63.1896 21.6001Z"
        fill="#214ecb"
      />
      <path
        d="M74.3984 21.6082H78.4215V13.6391C78.4215 11.6302 79.5787 10.4594 81.5626 10.4594C82.1413 10.4594 82.8158 10.468 83.1465 10.601V7.28356C82.871 7.20372 82.348 7.12012 81.8796 7.12012C80.1436 7.12012 78.8899 8.10461 78.3663 9.91395H78.2835V7.34627H74.3984V21.6082Z"
        fill="#214ecb"
      />
      <path
        d="M85.5947 21.5714H89.6177V7.33812H85.5947V21.5714ZM87.6062 6.42016C88.8189 6.42016 89.7556 5.52879 89.7556 4.41125C89.7556 3.28041 88.8189 2.40234 87.6062 2.40234C86.3938 2.40234 85.457 3.28041 85.457 4.41125C85.457 5.52879 86.3938 6.42016 87.6062 6.42016Z"
        fill="#214ecb"
      />
      <path
        d="M92.293 21.6051H96.316V13.4232C96.316 11.5739 97.4594 10.2967 99.2643 10.2967C101.069 10.2967 101.978 11.361 101.978 13.2236V21.6051H106.001V12.4121C106.001 9.07275 104.155 7.07715 100.876 7.07715C98.6028 7.07715 97.0462 8.11487 96.2608 9.88429H96.1781V7.34322H92.293V21.6051Z"
        fill="#214ecb"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.2645 3.99996C7.70853 3.99996 4.01517 7.67934 4.01517 12.2181V19.9998H11.8265C16.3825 19.9998 20.0758 16.3204 20.0758 11.7817V3.99996H12.2645ZM0 12.2181C0 5.47022 5.49101 0 12.2645 0H24.091V11.7817C24.091 18.5296 18.6 23.9998 11.8265 23.9998H0V12.2181Z"
        fill="#214ecb"
      />
      <path
        d="M8.72656 12.284C8.72656 10.3204 10.3245 8.72852 12.2956 8.72852H15.8646V12.284C15.8646 14.2477 14.2667 15.8396 12.2956 15.8396C10.3245 15.8396 8.72656 14.2477 8.72656 12.284Z"
        fill="#7FC222"
      />
    </svg>
  );
};
