import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const PeopleOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.82765 3C7.16261 3 5.09129 5.32002 5.3922 7.96802L5.65424 10.274C5.89544 12.3966 7.69141 14 9.82765 14C11.9639 14 13.7599 12.3966 14.0011 10.274L14.2631 7.96803C14.564 5.32002 12.4927 3 9.82765 3ZM12.7094 10.1272L12.9714 7.82124C13.1847 5.94439 11.7166 4.3 9.82765 4.3C7.93872 4.3 6.47061 5.94439 6.68389 7.82124L6.94593 10.1272C7.11248 11.5928 8.35258 12.7 9.82765 12.7C11.3027 12.7 12.5428 11.5928 12.7094 10.1272Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.97402 15C5.16182 15 2.72694 16.9532 2.11689 19.6984L2.01695 20.1481C1.91981 20.5853 2.25246 21 2.70028 21H16.955C17.4028 21 17.7355 20.5853 17.6384 20.1481L17.5384 19.6984C16.9284 16.9532 14.4935 15 11.6813 15H7.97402ZM16.198 19.7C15.6229 17.6982 13.7872 16.3 11.6813 16.3H7.97402C5.86815 16.3 4.03238 17.6982 3.45729 19.7H16.198Z"
        fill={color}
      />
      <path
        d="M14.1723 14C13.7922 14 13.6567 13.5382 13.8947 13.2419C13.9416 13.1835 13.9873 13.1241 14.0319 13.0638C14.196 12.8415 14.4473 12.7003 14.7187 12.6484C15.9438 12.4144 16.9086 11.4076 17.0541 10.1272L17.3161 7.82124C17.497 6.22931 16.4683 4.80463 14.9991 4.40876C14.6467 4.31378 14.3183 4.12676 14.0889 3.84284C14.0392 3.78144 13.9884 3.72105 13.9363 3.66173C13.7152 3.40992 13.8373 3 14.1723 3C16.8374 3 18.9087 5.32002 18.6078 7.96803L18.3458 10.274C18.1046 12.3966 16.3086 14 14.1723 14Z"
        fill={color}
      />
      <path
        d="M19.3143 21C18.9716 21 18.7274 20.6484 18.7236 20.3058C18.7202 19.9919 18.9448 19.7 19.2587 19.7H20.5427C20.1057 18.1788 18.9407 17.0062 17.4824 16.5308C17.2738 16.4628 17.0977 16.3644 16.9626 16.1914C16.5991 15.726 16.9161 15.0365 17.4889 15.1804C19.65 15.7232 21.382 17.4437 21.8831 19.6984L21.983 20.1481C22.0802 20.5853 21.7475 21 21.2997 21H19.3143Z"
        fill={color}
      />
    </svg>
  );
};

const PeopleSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.3922 7.96802C5.09129 5.32002 7.16261 3 9.82765 3C12.4927 3 14.564 5.32002 14.2631 7.96803L14.0011 10.274C13.7599 12.3966 11.9639 14 9.82765 14C7.69141 14 5.89544 12.3966 5.65424 10.274L5.3922 7.96802Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.11689 19.6984C2.72694 16.9532 5.16182 15 7.97402 15H11.6813C14.4935 15 16.9284 16.9532 17.5384 19.6984L17.6384 20.1481C17.7355 20.5853 17.4028 21 16.955 21H2.70028C2.25246 21 1.91981 20.5853 2.01695 20.1481L2.11689 19.6984Z"
        fill={color}
      />
      <path
        d="M18.9351 20.5899C18.9168 20.7148 18.938 20.8525 19.0446 20.9202C19.1239 20.9705 19.215 21 19.3143 21H21.2997C21.7475 21 22.0802 20.5853 21.983 20.1481L21.8831 19.6984C21.382 17.4437 19.65 15.7232 17.4889 15.1804C17.1202 15.0878 16.8575 15.3404 16.814 15.6563C16.8009 15.7514 16.8478 15.8416 16.9148 15.9104C17.8346 16.8556 18.5052 18.0561 18.8075 19.4164L18.9074 19.8661C18.9622 20.1127 18.9691 20.3569 18.9351 20.5899Z"
        fill={color}
      />
      <path
        d="M14.1489 13.2032C13.9862 13.4095 13.8271 13.697 13.9857 13.9066C14.0288 13.9635 14.089 14 14.1723 14C16.3086 14 18.1046 12.3966 18.3458 10.274L18.6078 7.96803C18.9087 5.32002 16.8374 3 14.1723 3C14.0533 3 13.9612 3.05173 13.9017 3.12973C13.8252 3.23021 13.8823 3.36514 13.9701 3.45595C15.1199 4.64512 15.7585 6.32171 15.5548 8.11476L15.2928 10.4207C15.1734 11.4707 14.7636 12.4237 14.1489 13.2032Z"
        fill={color}
      />
    </svg>
  );
};

export const PeopleIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={PeopleOutlined}
      solidSvg={PeopleSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
