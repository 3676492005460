import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

export const IdeaOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0143 14.9752V17.9893C16.0143 19.6521 14.6664 21 13.0036 21H10.9965C9.33374 21 7.98581 19.6521 7.98581 17.9893V14.9752C6.37843 13.7593 5.33984 11.831 5.33984 9.6602C5.33984 5.98187 8.32172 3 12 3C15.6784 3 18.6602 5.98187 18.6602 9.6602C18.6602 11.831 17.6217 13.7593 16.0143 14.9752ZM9.29044 15.2647H14.7096V17.9893C14.7096 18.9316 13.9458 19.6954 13.0036 19.6954H10.9965C10.0543 19.6954 9.29044 18.9316 9.29044 17.9893V15.2647ZM8.77288 13.9347L8.80633 13.96H9.9816V10.2457H9.21335C8.85309 10.2457 8.56104 9.95364 8.56104 9.59338C8.56104 9.23311 8.85309 8.94106 9.21335 8.94106H14.8313C15.1916 8.94106 15.4836 9.23311 15.4836 9.59338C15.4836 9.95364 15.1916 10.2457 14.8313 10.2457H14.063V13.96H15.1938L15.2272 13.9347C16.5227 12.9548 17.3556 11.405 17.3556 9.6602C17.3556 6.7024 14.9579 4.30462 12 4.30462C9.04224 4.30462 6.64447 6.7024 6.64447 9.6602C6.64447 11.405 7.47743 12.9548 8.77288 13.9347ZM12.7584 13.96V10.2457H11.2862V13.96H12.7584Z"
        fill={color}
      />
    </svg>
  );
};

const IdeaSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0143 17.9893V14.9752C17.6217 13.7593 18.6602 11.831 18.6602 9.6602C18.6602 5.98187 15.6784 3 12 3C8.32172 3 5.33984 5.98187 5.33984 9.6602C5.33984 11.831 6.37843 13.7593 7.98581 14.9752V17.9893C7.98581 19.6521 9.33374 21 10.9965 21H13.0036C14.6664 21 16.0143 19.6521 16.0143 17.9893ZM8.80633 13.96L8.77288 13.9347C7.47743 12.9548 6.64447 11.405 6.64447 9.6602C6.64447 6.7024 9.04224 4.30463 12 4.30463C14.9579 4.30463 17.3556 6.7024 17.3556 9.6602C17.3556 11.405 16.5227 12.9548 15.2272 13.9347L15.1938 13.96H14.063V10.2457H14.8313C15.1916 10.2457 15.4836 9.95365 15.4836 9.59339C15.4836 9.23313 15.1916 8.94107 14.8313 8.94107H9.21335C8.85309 8.94107 8.56104 9.23313 8.56104 9.59339C8.56104 9.95365 8.85309 10.2457 9.21335 10.2457H9.9816V13.96H8.80633ZM11.2862 13.96H12.7584V10.2457H11.2862V13.96Z"
        fill={color}
      />
    </svg>
  );
};

export const IdeaIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={IdeaOutlined}
      solidSvg={IdeaSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
