import React from 'react';
import { TColor } from '../../../styles';

export type THeadlineLevel = 1 | 2 | 3 | 4 | 5 | 6;

export type THeadlineSize = 'xxl' | 'xl' | 'l' | 'm' | 's' | 'xs';

export type THeadlineAlign = 'left' | 'center' | 'right';

export interface HeadlineProps extends React.PropsWithChildren<{}> {
  level: THeadlineLevel;
  color: TColor;
  size: THeadlineSize;
  bold?: boolean;
  props?: React.ComponentProps<'h1'>;
  align?: THeadlineAlign;
}
