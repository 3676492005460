import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const AttachFileOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.856 5.21169C14.9383 4.29396 13.4503 4.29396 12.5326 5.21169L6.38078 11.3635C4.95537 12.7889 4.95537 15.1 6.38078 16.5254L7.72428 17.8689C9.1497 19.2943 11.4607 19.2943 12.8862 17.8689L18.6844 12.0706C18.9383 11.8168 19.3498 11.8168 19.6037 12.0706C19.8575 12.3245 19.8575 12.736 19.6037 12.9899L13.8054 18.7881C11.8723 20.7212 8.73814 20.7212 6.80504 18.7881L5.46154 17.4446C3.52845 15.5115 3.52844 12.3774 5.46154 10.4443L11.6134 4.29245C13.0388 2.86704 15.3498 2.86704 16.7752 4.29245L17.4824 4.99956C18.9078 6.42497 18.9078 8.73602 17.4824 10.1614L11.6841 15.9597C10.9226 16.7212 9.68789 16.7212 8.92636 15.9597L8.28997 15.3233C7.52845 14.5618 7.52844 13.3271 8.28997 12.5656L13.7347 7.12088C13.9885 6.86704 14.4001 6.86704 14.6539 7.12088C14.9078 7.37472 14.9078 7.78627 14.6539 8.04011L9.20921 13.4848C8.95536 13.7387 8.95537 14.1502 9.20921 14.4041L9.8456 15.0405C10.0994 15.2943 10.511 15.2943 10.7648 15.0405L16.5631 9.2422C17.4808 8.32446 17.4808 6.83653 16.5631 5.91879L15.856 5.21169Z"
        fill={color}
      />
    </svg>
  );
};

const AttachFileSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM8.06051 15.9797C9.4078 17.327 11.5922 17.327 12.9395 15.9797L17.4596 11.4596C17.7135 11.2058 17.7135 10.7942 17.4596 10.5404C17.2058 10.2865 16.7942 10.2865 16.5404 10.5404L12.0203 15.0605C11.1806 15.9001 9.81936 15.9001 8.97975 15.0605C8.14014 14.2209 8.14014 12.8596 8.97975 12.02L12.8794 8.1203C13.1333 7.86646 13.5448 7.86646 13.7987 8.1203L13.9401 8.2617C14.1939 8.51554 14.1939 8.92709 13.9401 9.18093L9.96418 13.1568C9.71033 13.4107 9.71034 13.8222 9.96418 14.0761C10.218 14.3299 10.6296 14.3299 10.8834 14.0761L14.8593 10.1002C15.6208 9.33865 15.6208 8.10398 14.8593 7.34246L14.7179 7.20106C13.9564 6.43954 12.7217 6.43954 11.9602 7.20107L8.06051 11.1008C6.71321 12.4481 6.71322 14.6325 8.06051 15.9797Z"
        fill={color}
      />
    </svg>
  );
};

export const AttachFileIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={AttachFileOutlined}
      solidSvg={AttachFileSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
