import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const NotificationsOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5998 2.58005C12.5998 2.22107 12.3088 1.93005 11.9498 1.93005C11.5908 1.93005 11.2998 2.22107 11.2998 2.58005V4.62376C9.37148 4.8849 7.76707 6.29299 7.2898 8.22005L5.52484 15.2799H4.7999C4.44092 15.2799 4.1499 15.5709 4.1499 15.9299C4.1499 16.2889 4.44092 16.5799 4.7999 16.5799H19.0999C19.4589 16.5799 19.7499 16.2889 19.7499 15.9299C19.7499 15.5709 19.4589 15.2799 19.0999 15.2799H18.3748L16.6098 8.22005C16.1325 6.29299 14.5281 4.8849 12.5998 4.62376V2.58005ZM17.0398 15.2799L15.3498 8.54005C14.9598 6.97005 13.5598 5.88005 11.9498 5.88005C10.3398 5.88005 8.93981 6.98005 8.54981 8.54005L6.85984 15.2799H17.0398Z"
        fill={color}
      />
      <path
        d="M10.6497 18.5846C10.6411 18.2257 10.3433 17.9417 9.98439 17.9503C9.6255 17.9588 9.34149 18.2567 9.35004 18.6155C9.38092 19.913 10.5234 20.8501 11.7999 20.8501C13.0763 20.8501 14.2188 19.913 14.2497 18.6155C14.2582 18.2567 13.9742 17.9588 13.6153 17.9503C13.2564 17.9417 12.9586 18.2257 12.95 18.5846C12.9386 19.0663 12.4858 19.5501 11.7999 19.5501C11.1139 19.5501 10.6611 19.0663 10.6497 18.5846Z"
        fill={color}
      />
    </svg>
  );
};

const NotificationsSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5998 2.58005C12.5998 2.22107 12.3088 1.93005 11.9498 1.93005C11.5908 1.93005 11.2998 2.22107 11.2998 2.58005V4.62376C9.37148 4.8849 7.76707 6.29299 7.2898 8.22005L5.52484 15.2799H4.7999C4.44092 15.2799 4.1499 15.5709 4.1499 15.9299C4.1499 16.2889 4.44092 16.5799 4.7999 16.5799H19.0999C19.4589 16.5799 19.7499 16.2889 19.7499 15.9299C19.7499 15.5709 19.4589 15.2799 19.0999 15.2799H18.3748L16.6098 8.22005C16.1325 6.29299 14.5281 4.8849 12.5998 4.62376V2.58005Z"
        fill={color}
      />
      <path
        d="M9.98439 17.9503C9.6255 17.9588 9.34149 18.2567 9.35004 18.6155C9.38092 19.913 10.5234 20.8501 11.7999 20.8501C13.0763 20.8501 14.2188 19.913 14.2497 18.6155C14.2582 18.2567 13.9742 17.9588 13.6153 17.9503C12.1112 17.9145 10.9355 17.9276 9.98439 17.9503Z"
        fill={color}
      />
    </svg>
  );
};

export const NotificationsIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={NotificationsOutlined}
      solidSvg={NotificationsSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
