import React from 'react';
import { BlackMenuLogo } from './black-menu-plarin-logo';
import { LoginFormPlarinLogo } from './login-form-plarin-logo';
import { MenuLogo } from './menu-plarin-logo';
import { PlarinLogoProps } from './plarin-logo-props';

export const PlarinLogo: React.VFC<PlarinLogoProps> = function PlarinLogo({ variant }) {
  switch (variant) {
    case 'auth-form':
      return <LoginFormPlarinLogo />;
    case 'menu':
      return <MenuLogo />;
    case 'blackMenu':
      return <BlackMenuLogo />;
  }
  return null;
};
