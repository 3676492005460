import React from 'react';

export const SpreadWorkFlow: React.VFC = function SpreadWorkFlow() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="#537AC6" />
      <path
        d="M12.8262 16.3652H15.2402V17.1738H12.8262V19.6699H12.0117V17.1738H9.60938V16.3652H12.0117V13.8574H12.8262V16.3652ZM22.084 21H16.4531V20.1621L18.709 17.8945C19.3965 17.1992 19.8496 16.7031 20.0684 16.4062C20.2871 16.1094 20.4512 15.8203 20.5605 15.5391C20.6699 15.2578 20.7246 14.9551 20.7246 14.6309C20.7246 14.1738 20.5859 13.8125 20.3086 13.5469C20.0312 13.2773 19.6465 13.1426 19.1543 13.1426C18.7988 13.1426 18.4609 13.2012 18.1406 13.3184C17.8242 13.4355 17.4707 13.6484 17.0801 13.957L16.5645 13.2949C17.3535 12.6387 18.2129 12.3105 19.1426 12.3105C19.9473 12.3105 20.5781 12.5176 21.0352 12.9316C21.4922 13.3418 21.7207 13.8945 21.7207 14.5898C21.7207 15.1328 21.5684 15.6699 21.2637 16.2012C20.959 16.7324 20.3887 17.4043 19.5527 18.2168L17.6777 20.0508V20.0977H22.084V21Z"
        fill="white"
      />
      <circle cx="16.0001" cy="15.9996" r="13.4" stroke="white" strokeWidth="2" />
    </svg>
  );
};
