import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const TreasuryUsdOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7092 7.70932C17.7092 10.8625 15.1531 13.4186 11.9999 13.4186C8.84674 13.4186 6.29059 10.8625 6.29059 7.70932C6.29059 4.55615 8.84674 2 11.9999 2C15.1531 2 17.7092 4.55615 17.7092 7.70932ZM16.4092 7.70932C16.4092 10.1445 14.4351 12.1186 11.9999 12.1186C9.56471 12.1186 7.59059 10.1445 7.59059 7.70932C7.59059 5.27412 9.56471 3.3 11.9999 3.3C14.4351 3.3 16.4092 5.27412 16.4092 7.70932Z"
        fill={color}
      />
      <path
        d="M6.24833 16.7094L7.77651 14.9461C8.84107 15.5644 10.0546 15.9544 11.3502 16.0491C11.35 16.0558 11.3499 16.0626 11.3499 16.0693V17.9457C11.3499 18.3046 11.6409 18.5957 11.9999 18.5957C12.3589 18.5957 12.6499 18.3046 12.6499 17.9457V16.0693C12.6499 16.0609 12.6498 16.0526 12.6494 16.0443C13.9775 15.9372 15.218 15.5196 16.2981 14.8642L17.7515 16.5413C17.9866 16.8126 18.3971 16.8419 18.6684 16.6068C18.9397 16.3717 18.969 15.9612 18.7339 15.6899L16.9815 13.6678C16.9663 13.645 16.9496 13.6229 16.9312 13.6017C16.6984 13.3322 16.2938 13.3027 16.0018 13.5065C14.8585 14.3039 13.468 14.7716 11.9684 14.7716C10.5314 14.7716 9.19472 14.3422 8.07964 13.6047C7.80533 13.4233 7.43992 13.4429 7.20746 13.664C7.1519 13.6981 7.10055 13.7411 7.05562 13.7929L5.26592 15.858C5.03082 16.1293 5.06015 16.5398 5.33143 16.7749C5.60272 17.01 6.01323 16.9807 6.24833 16.7094Z"
        fill={color}
      />
      <path
        d="M4.11226 19.9507C4.83023 19.9507 5.41226 19.3687 5.41226 18.6507C5.41226 17.9327 4.83023 17.3507 4.11226 17.3507C3.39429 17.3507 2.81226 17.9327 2.81226 18.6507C2.81226 19.3687 3.39429 19.9507 4.11226 19.9507Z"
        fill={color}
      />
      <path
        d="M13.3 20.7C13.3 21.418 12.7179 22 12 22C11.282 22 10.7 21.418 10.7 20.7C10.7 19.9821 11.282 19.4 12 19.4C12.7179 19.4 13.3 19.9821 13.3 20.7Z"
        fill={color}
      />
      <path
        d="M21.188 18.6507C21.188 19.3687 20.6059 19.9507 19.8879 19.9507C19.17 19.9507 18.588 19.3687 18.588 18.6507C18.588 17.9327 19.17 17.3507 19.8879 17.3507C20.6059 17.3507 21.188 17.9327 21.188 18.6507Z"
        fill={color}
      />
      <path
        d="M11.5023 5.04977C10.7844 5.22175 10.3485 5.80479 10.314 6.43788C10.2719 7.20824 10.8177 7.95642 11.8832 8.13017C12.3559 8.20726 12.6444 8.37306 12.8061 8.5415C12.9649 8.70706 13.0186 8.89127 13.0029 9.0603C12.9722 9.39128 12.6414 9.79387 11.9482 9.79387C11.5787 9.79387 11.3485 9.64444 11.2031 9.47466C11.042 9.28659 10.9943 9.08451 10.9943 9.0286C10.9943 8.81303 10.8165 8.63827 10.5971 8.63827C10.3778 8.63827 10.2 8.81303 10.2 9.0286C10.2 9.29159 10.3298 9.66732 10.5953 9.97732C10.8044 10.2216 11.1044 10.4329 11.5023 10.5252V11.0089C11.5023 11.2245 11.6801 11.3992 11.8995 11.3992C12.1188 11.3992 12.2967 11.2245 12.2967 11.0089V10.549C13.1425 10.4224 13.7283 9.83885 13.794 9.13122C13.8309 8.73418 13.6954 8.33046 13.384 8.00592C13.0754 7.68425 12.6125 7.45776 12.0132 7.36004C11.3078 7.24501 11.0889 6.81516 11.1072 6.4797C11.1263 6.12905 11.3995 5.77939 11.9482 5.77939C12.3064 5.77939 12.5068 5.92063 12.6293 6.08256C12.7653 6.26223 12.8174 6.48724 12.8174 6.62909C12.8174 6.84466 12.9953 7.01942 13.2146 7.01942C13.434 7.01942 13.6118 6.84466 13.6118 6.62909C13.6118 6.34573 13.5196 5.95072 13.2668 5.61668C13.0536 5.33504 12.7337 5.10827 12.2967 5.02904V4.58955C12.2967 4.37398 12.1188 4.19922 11.8995 4.19922C11.6801 4.19922 11.5023 4.37398 11.5023 4.58955V5.04977Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const TreasuryUsdIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={TreasuryUsdOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
