export type TColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'hover'
  | 'press'
  | 'disable'
  | 'primaryLight'
  | 'textPrimary'
  | 'textSecondary'
  | 'textTertiary';
