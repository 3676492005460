import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const VipIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6666 4.9987C11.6666 5.70336 11.2293 6.30589 10.6113 6.54968C10.6203 6.55935 10.6292 6.56928 10.6379 6.57948L12.2093 8.4223C12.5693 8.84457 13.1902 8.92647 13.6475 8.61202L15.2354 7.51995C15.0859 7.27006 15 6.97775 15 6.66536C15 5.74489 15.7461 4.9987 16.6666 4.9987C17.5871 4.9987 18.3333 5.74489 18.3333 6.66536C18.3333 7.55251 17.6402 8.27777 16.7659 8.32912L15.9745 13.5062C15.8936 14.0351 15.4387 14.4258 14.9036 14.4258H5.09756C4.56247 14.4258 4.10753 14.0351 4.02667 13.5062L3.23529 8.3292C2.36043 8.27847 1.66663 7.55294 1.66663 6.66536C1.66663 5.74489 2.41282 4.9987 3.33329 4.9987C4.25377 4.9987 4.99996 5.74489 4.99996 6.66536C4.99996 6.97497 4.91554 7.26486 4.76846 7.51327L6.37914 8.61352C6.838 8.92697 7.45962 8.84239 7.81806 8.41774L9.367 6.58267C9.3759 6.57213 9.38499 6.56187 9.39427 6.55189C8.77326 6.30954 8.33329 5.7055 8.33329 4.9987C8.33329 4.07822 9.07948 3.33203 9.99996 3.33203C10.9204 3.33203 11.6666 4.07822 11.6666 4.9987ZM9.99996 5.58203C10.3221 5.58203 10.5833 5.32086 10.5833 4.9987C10.5833 4.67653 10.3221 4.41536 9.99996 4.41536C9.67779 4.41536 9.41663 4.67653 9.41663 4.9987C9.41663 5.32086 9.67779 5.58203 9.99996 5.58203ZM15.6346 8.56019L14.2613 9.50464C13.3469 10.1335 12.105 9.96973 11.3849 9.12521L10.0048 7.50662L8.64591 9.11651C7.92904 9.96581 6.6858 10.135 5.76808 9.50807L4.36489 8.54955L5.09756 13.3425L14.9036 13.3425L15.6346 8.56019ZM3.91663 6.66536C3.91663 6.98753 3.65546 7.2487 3.33329 7.2487C3.01113 7.2487 2.74996 6.98753 2.74996 6.66536C2.74996 6.3432 3.01113 6.08203 3.33329 6.08203C3.65546 6.08203 3.91663 6.3432 3.91663 6.66536ZM16.6666 7.2487C16.9888 7.2487 17.25 6.98753 17.25 6.66536C17.25 6.3432 16.9888 6.08203 16.6666 6.08203C16.3445 6.08203 16.0833 6.3432 16.0833 6.66536C16.0833 6.98753 16.3445 7.2487 16.6666 7.2487Z"
        fill={color}
      />
      <path
        d="M15.2916 16.668C15.5908 16.668 15.8333 16.4254 15.8333 16.1263C15.8333 15.8271 15.5908 15.5846 15.2916 15.5846H4.70829C4.40914 15.5846 4.16663 15.8271 4.16663 16.1263C4.16663 16.4254 4.40914 16.668 4.70829 16.668H15.2916Z"
        fill={color}
      />
    </svg>
  );
};

const VipIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8333 16.1244C15.8333 16.4235 15.5908 16.666 15.2916 16.666H4.70829C4.40914 16.666 4.16663 16.4235 4.16663 16.1244C4.16663 15.8252 4.40914 15.5827 4.70829 15.5827H15.2916C15.5908 15.5827 15.8333 15.8252 15.8333 16.1244Z"
        fill={color}
      />
      <path
        d="M11.6666 5.00004C11.6666 5.68429 11.2543 6.27223 10.6645 6.52894L12.5214 8.4724C12.9 8.86864 13.5149 8.91975 13.9537 8.59145L15.2833 7.59667C15.1044 7.33104 15 7.01107 15 6.66671C15 5.74623 15.7461 5.00004 16.6666 5.00004C17.5871 5.00004 18.3333 5.74623 18.3333 6.66671C18.3333 7.5569 17.6354 8.2841 16.7569 8.33097L15.9769 13.3033C15.8942 13.8304 15.4401 14.2188 14.9066 14.2188H5.09327C4.55978 14.2188 4.1057 13.8304 4.02303 13.3033L3.24305 8.33097C2.36454 8.2841 1.66663 7.5569 1.66663 6.66671C1.66663 5.74623 2.41282 5.00004 3.33329 5.00004C4.25377 5.00004 4.99996 5.74623 4.99996 6.66671C4.99996 7.01473 4.89329 7.33783 4.71087 7.60511L6.01316 8.58866C6.45008 8.91865 7.06455 8.87074 7.44505 8.47702L9.33001 6.52659C8.74313 6.26865 8.33329 5.68221 8.33329 5.00004C8.33329 4.07957 9.07948 3.33337 9.99996 3.33337C10.9204 3.33337 11.6666 4.07957 11.6666 5.00004Z"
        fill={color}
      />
    </svg>
  );
};

export const VipIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={VipIconOutlined}
      solidSvg={VipIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
