import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const ArrowUpOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.0308 12.4403C5.8908 12.3103 5.8208 12.1403 5.8208 11.9603C5.8208 11.8003 5.8708 11.6503 5.9908 11.5203L11.4808 5.4803C11.7608 5.1703 12.2408 5.1703 12.5208 5.4803L18.0108 11.5203C18.2508 11.7903 18.2308 12.2003 17.9708 12.4403C17.7008 12.6803 17.2908 12.6703 17.0508 12.4003L12.6508 7.56466L12.6508 18.4603C12.6508 18.8193 12.3598 19.1103 12.0008 19.1103C11.6418 19.1103 11.3508 18.8193 11.3508 18.4603L11.3508 7.56466L6.9508 12.4003C6.7108 12.6603 6.3008 12.6803 6.0308 12.4403Z"
        fill={color}
      />
    </svg>
  );
};

const ArrowUpSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM7.44183 11.5C7.44183 11.68 7.51183 11.85 7.65183 11.98C7.92183 12.22 8.33183 12.2 8.57183 11.94L11.4055 9.05238V16C11.4055 16.359 11.6965 16.65 12.0555 16.65C12.4145 16.65 12.7055 16.359 12.7055 16V9.05944L15.5218 11.96C15.7618 12.23 16.1718 12.24 16.4418 12C16.7018 11.76 16.7218 11.35 16.4818 11.08L12.5755 7.02C12.2955 6.71 11.8155 6.71 11.5355 7.02L7.61182 11.06C7.49183 11.19 7.44183 11.34 7.44183 11.5Z"
        fill={color}
      />
    </svg>
  );
};

export const ArrowUpIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={ArrowUpOutlined}
      solidSvg={ArrowUpSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
