import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const SettingsIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9998 12C15.9998 14.2091 14.209 16 11.9998 16C9.79071 16 7.99985 14.2091 7.99985 12C7.99985 9.79081 9.79071 7.99995 11.9998 7.99995C14.209 7.99995 15.9998 9.79081 15.9998 12ZM14.6998 12C14.6998 13.4911 13.491 14.7 11.9998 14.7C10.5087 14.7 9.29985 13.4911 9.29985 12C9.29985 10.5088 10.5087 9.29995 11.9998 9.29995C13.491 9.29995 14.6998 10.5088 14.6998 12Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9829 3.00488C10.0972 3.00488 9.34712 3.65803 9.22535 4.53534C9.07434 5.62334 7.93962 6.27847 6.92187 5.86525C6.10122 5.53205 5.16054 5.85506 4.71768 6.62211L3.76095 8.27922C3.32346 9.03697 3.50364 10.0005 4.18538 10.549C5.06698 11.2582 5.05247 12.626 4.14737 13.3306C3.44849 13.8748 3.25788 14.8509 3.70074 15.618L4.65747 17.2751C5.09496 18.0328 6.0195 18.3585 6.83534 18.0424C7.89037 17.6335 9.06765 18.33 9.22535 19.4661C9.34712 20.3434 10.0972 20.9966 10.9829 20.9966H12.8964C13.7713 20.9966 14.5157 20.3588 14.6498 19.4942C14.8322 18.3184 16.0549 17.6124 17.1644 18.0424C17.9802 18.3586 18.9047 18.0328 19.3422 17.2751L20.299 15.618C20.7418 14.8509 20.5512 13.8748 19.8523 13.3306C18.9472 12.626 18.9327 11.2582 19.8143 10.549C20.4961 10.0005 20.6762 9.03697 20.2387 8.27922L19.282 6.62211C18.8392 5.85506 17.8985 5.53205 17.0778 5.86525C16.015 6.29676 14.8232 5.62543 14.6498 4.50732C14.5157 3.64269 13.7713 3.00488 12.8964 3.00488H10.9829ZM10.513 4.71406C10.5456 4.47951 10.7461 4.30488 10.9829 4.30488L12.8964 4.30488C13.1303 4.30488 13.3293 4.47541 13.3652 4.70657C13.6695 6.66882 15.7415 7.8109 17.5669 7.06975C17.7863 6.98067 18.0378 7.06703 18.1562 7.27211L19.1129 8.92922C19.2299 9.13181 19.1817 9.38942 18.9994 9.53606C17.4523 10.7808 17.4992 13.1461 19.0537 14.3564C19.2406 14.5019 19.2915 14.7629 19.1731 14.968L18.2164 16.6251C18.0994 16.8277 17.8522 16.9148 17.6341 16.8302C15.7506 16.1003 13.6748 17.2987 13.3652 19.2949C13.3293 19.5261 13.1303 19.6966 12.8964 19.6966H10.9829C10.7461 19.6966 10.5456 19.522 10.513 19.2874C10.2421 17.336 8.21711 16.1127 6.36558 16.8302C6.14745 16.9148 5.90027 16.8277 5.78331 16.6251L4.82657 14.968C4.70817 14.7629 4.75913 14.5019 4.94598 14.3564C6.50054 13.1461 6.54743 10.7808 5.00026 9.53606C4.81799 9.38942 4.76981 9.13181 4.88678 8.92922L5.84352 7.27211C5.96192 7.06703 6.21342 6.98067 6.43283 7.06975C8.23557 7.80169 10.2455 6.64126 10.513 4.71406Z"
        fill={color}
      />
    </svg>
  );
};

const SettingsIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.22535 4.53534C9.34712 3.65803 10.0972 3.00488 10.9829 3.00488H12.8964C13.7713 3.00488 14.5157 3.64269 14.6498 4.50732C14.8232 5.62543 16.015 6.29676 17.0778 5.86525C17.8985 5.53205 18.8392 5.85506 19.282 6.62211L20.2387 8.27922C20.6762 9.03697 20.4961 10.0005 19.8143 10.549C18.9327 11.2582 18.9472 12.626 19.8523 13.3306C20.5512 13.8748 20.7418 14.8509 20.299 15.618L19.3422 17.2751C18.9047 18.0328 17.9802 18.3586 17.1644 18.0424C16.0549 17.6124 14.8322 18.3184 14.6498 19.4942C14.5157 20.3588 13.7713 20.9966 12.8964 20.9966H10.9829C10.0972 20.9966 9.34712 20.3434 9.22535 19.4661C9.06765 18.33 7.89037 17.6335 6.83534 18.0424C6.0195 18.3585 5.09496 18.0328 4.65747 17.2751L3.70074 15.618C3.25788 14.8509 3.44849 13.8748 4.14737 13.3306C5.05247 12.626 5.06698 11.2582 4.18538 10.549C3.50364 10.0005 3.32346 9.03697 3.76095 8.27922L4.71768 6.62211C5.16054 5.85506 6.10122 5.53205 6.92187 5.86525C7.93962 6.27847 9.07434 5.62334 9.22535 4.53534ZM11.9998 16C14.209 16 15.9998 14.2091 15.9998 12C15.9998 9.79082 14.209 7.99995 11.9998 7.99995C9.79071 7.99995 7.99985 9.79082 7.99985 12C7.99985 14.2091 9.79071 16 11.9998 16Z"
        fill={color}
      />
    </svg>
  );
};

export const SettingsIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={SettingsIconOutlined}
      solidSvg={SettingsIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
