import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const SearchIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 11C4 7.13 7.13 4 11 4C14.87 4 18 7.13 18 11C18 12.7621 17.3511 14.3708 16.2789 15.6005L19.7802 19.111C20.0337 19.3652 20.0332 19.7767 19.779 20.0302C19.5248 20.2837 19.1133 20.2832 18.8598 20.029L15.3373 16.4973C14.1453 17.4386 12.639 18 11 18C7.13 18 4 14.87 4 11ZM16.7 11C16.7 7.86 14.14 5.3 11 5.3C7.86 5.3 5.3 7.86 5.3 11C5.3 14.14 7.86 16.7 11 16.7C14.14 16.7 16.7 14.14 16.7 11Z"
        fill={color}
      />
    </svg>
  );
};

const SearchIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5 8.3C9.73011 8.3 8.3 9.73011 8.3 11.5C8.3 13.2699 9.73011 14.7 11.5 14.7C13.2699 14.7 14.7 13.2699 14.7 11.5C14.7 9.73011 13.2699 8.3 11.5 8.3Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM7 11.5C7 9.01214 9.01214 7 11.5 7C13.9879 7 16 9.01214 16 11.5C16 12.5092 15.6689 13.44 15.1093 14.1901L16.9596 16.0404C17.2135 16.2942 17.2135 16.7058 16.9596 16.9596C16.7058 17.2135 16.2942 17.2135 16.0404 16.9596L14.1901 15.1093C13.44 15.6689 12.5092 16 11.5 16C9.01214 16 7 13.9879 7 11.5Z"
        fill={color}
      />
    </svg>
  );
};

export const SearchIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={SearchIconOutlined}
      solidSvg={SearchIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
