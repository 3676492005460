import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

const GoogleAnalyticsIconCommon = ({ color, size }: TSvgProps) => {
  return (
    <div
      style={{
        width: `${size}`,
        height: `${size}`,
        borderRadius: '4px',
        background: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.6009 16.7475C17.6072 17.9833 16.651 18.9896 15.466 18.9963C15.3779 18.997 15.2891 18.9918 15.2018 18.9807C14.102 18.8112 13.2964 17.8138 13.3234 16.6557V3.34047C13.2971 2.18089 14.1049 1.18348 15.2053 1.01539C16.3825 0.871736 17.4488 1.74994 17.5866 2.9769C17.5966 3.06724 17.6016 3.15758 17.6009 3.24865V16.7475Z"
          fill="#F9AB00"
        />
        <path
          d="M4.13908 14.5409C5.32051 14.5409 6.27815 15.539 6.27815 16.7704C6.27815 18.0018 5.32051 19 4.13908 19C2.95765 19 2 18.0018 2 16.7704C2 15.539 2.95765 14.5409 4.13908 14.5409ZM9.76703 7.78406C8.57992 7.85218 7.65921 8.88958 7.68692 10.1284V16.1166C7.68692 17.7419 8.37319 18.7282 9.37843 18.9385C10.5385 19.1836 11.6688 18.4024 11.904 17.1932C11.9331 17.0451 11.9473 16.8948 11.9466 16.7438V10.0247C11.9487 8.78888 10.9897 7.78554 9.80397 7.78332C9.7919 7.78332 9.77911 7.78332 9.76703 7.78406Z"
          fill="#E37400"
        />
      </svg>
    </div>
  );
};

export const GoogleAnalyticsIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={GoogleAnalyticsIconCommon}
      solidSvg={GoogleAnalyticsIconCommon}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
