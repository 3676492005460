import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

export const HeadsetOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.64998 9.65C7.64998 7.24756 9.59754 5.3 12 5.3C14.4024 5.3 16.35 7.24756 16.35 9.65V14.1527C16.35 15.6632 15.2859 16.9252 13.8664 17.2299C13.5773 16.4811 12.8507 15.95 12 15.95C10.8954 15.95 9.99997 16.8454 9.99997 17.95C9.99997 19.0546 10.8954 19.95 12 19.95C12.8977 19.95 13.6573 19.3585 13.9103 18.544C15.7865 18.2441 17.271 16.7706 17.5875 14.9H18.2999C19.3769 14.9 20.2499 14.027 20.2499 12.95V11.05C20.2499 9.97307 19.3769 9.10002 18.2999 9.10002L17.6235 9.10002C17.347 6.2377 14.9348 4 12 4C9.06513 4 6.65293 6.23767 6.37641 9.09998H5.7C4.62305 9.09998 3.75 9.97302 3.75 11.05V12.95C3.75 14.0269 4.62304 14.9 5.7 14.9H6.9725C6.98161 14.9004 6.99077 14.9005 6.99998 14.9005C7.35896 14.9005 7.64998 14.6095 7.64998 14.2505V9.65ZM17.65 10.4V13.6H18.2999C18.6589 13.6 18.9499 13.309 18.9499 12.95V11.05C18.9499 10.691 18.6589 10.4 18.2999 10.4H17.65ZM5.7 10.4H6.34998V13.6H5.7C5.34101 13.6 5.05 13.309 5.05 12.95V11.05C5.05 10.691 5.34102 10.4 5.7 10.4ZM12.7 17.95C12.7 18.3366 12.3866 18.65 12 18.65C11.6134 18.65 11.3 18.3366 11.3 17.95C11.3 17.5634 11.6134 17.25 12 17.25C12.3866 17.25 12.7 17.5634 12.7 17.95Z"
        fill={color}
      />
    </svg>
  );
};

const HeadsetSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.64998 9.65C7.64998 7.24756 9.59754 5.3 12 5.3C14.3976 5.3 16.3421 7.2397 16.35 9.63544V14.1651C16.3435 15.8481 15.016 17.2196 13.3505 17.2966C13.1077 16.7955 12.5942 16.45 12 16.45C11.1715 16.45 10.5 17.1216 10.5 17.95C10.5 18.7784 11.1715 19.45 12 19.45C12.5965 19.45 13.1117 19.1018 13.3534 18.5975C15.4872 18.5264 17.2402 16.9522 17.5875 14.9H18.2999C19.3769 14.9 20.2499 14.027 20.2499 12.95V11.05C20.2499 9.97307 19.3769 9.10002 18.2999 9.10002H17.6235C17.347 6.2377 14.9348 4 12 4C9.06513 4 6.65293 6.23767 6.37641 9.09998H5.7C4.62305 9.09998 3.75 9.97302 3.75 11.05V12.95C3.75 14.0269 4.62304 14.9 5.7 14.9H6.9725C6.98161 14.9004 6.99077 14.9005 6.99998 14.9005C7.34774 14.9005 7.63172 14.6274 7.64913 14.284C7.64936 14.2796 7.64954 14.2751 7.64967 14.2706C7.64989 14.2638 7.65 14.2569 7.65 14.25V9.74998L7.64998 9.74427V9.65Z"
        fill={color}
      />
    </svg>
  );
};

export const HeadsetIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={HeadsetOutlined}
      solidSvg={HeadsetSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
