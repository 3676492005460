import { CssBaseline, ThemeProvider as MUIhemeProvider } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import React from 'react';
import { themeMui } from './theme-mui';

export const ThemeProvider: React.FC<{}> = function ThemeProvider({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <MUIhemeProvider theme={themeMui}>
        <CssBaseline />
        {children}
      </MUIhemeProvider>
    </StyledEngineProvider>
  );
};
