import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const SignInIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2814 16.4167H7.50016C6.71776 16.4167 6.0835 15.7824 6.0835 15V14.7604C6.0835 14.4613 5.84098 14.2188 5.54183 14.2188C5.24268 14.2188 5.00016 14.4613 5.00016 14.7604V15C5.00016 16.3807 6.11945 17.5 7.50016 17.5H13.2814C14.6621 17.5 15.7814 16.3807 15.7814 15V5C15.7814 3.61929 14.6621 2.5 13.2814 2.5H7.50016C6.11945 2.5 5.00016 3.61929 5.00016 5V5.29167C5.00016 5.59082 5.24268 5.83333 5.54183 5.83333C5.84098 5.83333 6.0835 5.59082 6.0835 5.29167V5C6.0835 4.2176 6.71776 3.58333 7.50016 3.58333L13.2814 3.58333C13.4678 3.58333 13.6459 3.61934 13.8089 3.68478L12.5201 4.44419C11.7574 4.8936 11.2892 5.71284 11.2892 6.59809V13.3675C11.2892 14.2434 11.7477 15.0554 12.4976 15.508L13.8247 16.3087C13.6574 16.3783 13.4739 16.4167 13.2814 16.4167ZM14.5984 4.47698C14.6627 4.6388 14.6981 4.81527 14.6981 5L14.6981 15C14.6981 15.1808 14.6642 15.3537 14.6024 15.5127L13.0573 14.5804C12.6323 14.324 12.3726 13.8638 12.3726 13.3675V6.59809C12.3726 6.09645 12.6378 5.63221 13.07 5.37755L14.5984 4.47698Z"
        fill={color}
      />
      <path
        d="M5.8281 7.42541C6.02952 7.20423 6.37211 7.18821 6.5933 7.38963L8.90655 9.49625C9.08299 9.65693 9.12887 9.90744 9.03797 10.1145C9.01295 10.2054 8.96388 10.2909 8.89097 10.3609L6.63431 12.528C6.41854 12.7352 6.07564 12.7283 5.86843 12.5125C5.66122 12.2967 5.66816 11.9539 5.88394 11.7466L7.17165 10.51L3.0861 10.51C2.78695 10.51 2.54443 10.2675 2.54443 9.96835C2.54443 9.6692 2.78695 9.42668 3.0861 9.42668H7.2212L5.86387 8.19061C5.64269 7.98918 5.62668 7.64659 5.8281 7.42541Z"
        fill={color}
      />
    </svg>
  );
};

const SignInIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="path-1-inside-1_3_372" fill={color}>
        <path d="M13.2814 16.4167H7.50016C6.71776 16.4167 6.0835 15.7824 6.0835 15L6.0835 14.7604C6.0835 14.4613 5.84098 14.2188 5.54183 14.2188C5.24268 14.2188 5.00016 14.4613 5.00016 14.7604V15C5.00016 16.3807 6.11945 17.5 7.50016 17.5H13.2814C14.6621 17.5 15.7814 16.3807 15.7814 15L15.7814 5C15.7814 3.61929 14.6621 2.5 13.2814 2.5H7.50016C6.11945 2.5 5.00016 3.61929 5.00016 5V5.29167C5.00016 5.59082 5.24268 5.83333 5.54183 5.83333C5.84098 5.83333 6.0835 5.59082 6.0835 5.29167V5C6.0835 4.2176 6.71776 3.58333 7.50016 3.58333L13.2814 3.58333C13.4678 3.58333 13.6459 3.61934 13.8089 3.68478L12.5201 4.44419C11.7574 4.8936 11.2892 5.71284 11.2892 6.59809V13.3675C11.2892 14.2434 11.7477 15.0554 12.4976 15.508L13.8247 16.3087C13.6574 16.3783 13.4739 16.4167 13.2814 16.4167Z" />
        <path d="M5.82615 7.42544C6.02757 7.20426 6.37016 7.18824 6.59134 7.38967L8.9046 9.49628C9.08104 9.65696 9.12692 9.90748 9.03601 10.1145C9.011 10.2054 8.96193 10.2909 8.88902 10.3609L6.63236 12.5281C6.41659 12.7353 6.07369 12.7283 5.86648 12.5126C5.65927 12.2968 5.66621 11.9539 5.88198 11.7467L7.1697 10.51H3.08415C2.78499 10.51 2.54248 10.2675 2.54248 9.96838C2.54248 9.66923 2.78499 9.42672 3.08415 9.42672H7.21925L5.86192 8.19064C5.64074 7.98921 5.62472 7.64662 5.82615 7.42544Z" />
      </mask>
      <path
        d="M13.2814 16.4167H7.50016C6.71776 16.4167 6.0835 15.7824 6.0835 15L6.0835 14.7604C6.0835 14.4613 5.84098 14.2188 5.54183 14.2188C5.24268 14.2188 5.00016 14.4613 5.00016 14.7604V15C5.00016 16.3807 6.11945 17.5 7.50016 17.5H13.2814C14.6621 17.5 15.7814 16.3807 15.7814 15L15.7814 5C15.7814 3.61929 14.6621 2.5 13.2814 2.5H7.50016C6.11945 2.5 5.00016 3.61929 5.00016 5V5.29167C5.00016 5.59082 5.24268 5.83333 5.54183 5.83333C5.84098 5.83333 6.0835 5.59082 6.0835 5.29167V5C6.0835 4.2176 6.71776 3.58333 7.50016 3.58333L13.2814 3.58333C13.4678 3.58333 13.6459 3.61934 13.8089 3.68478L12.5201 4.44419C11.7574 4.8936 11.2892 5.71284 11.2892 6.59809V13.3675C11.2892 14.2434 11.7477 15.0554 12.4976 15.508L13.8247 16.3087C13.6574 16.3783 13.4739 16.4167 13.2814 16.4167Z"
        fill={color}
      />
      <path
        d="M5.82615 7.42544C6.02757 7.20426 6.37016 7.18824 6.59134 7.38967L8.9046 9.49628C9.08104 9.65696 9.12692 9.90748 9.03601 10.1145C9.011 10.2054 8.96193 10.2909 8.88902 10.3609L6.63236 12.5281C6.41659 12.7353 6.07369 12.7283 5.86648 12.5126C5.65927 12.2968 5.66621 11.9539 5.88198 11.7467L7.1697 10.51H3.08415C2.78499 10.51 2.54248 10.2675 2.54248 9.96838C2.54248 9.66923 2.78499 9.42672 3.08415 9.42672H7.21925L5.86192 8.19064C5.64074 7.98921 5.62472 7.64662 5.82615 7.42544Z"
        fill={color}
      />
      <path
        d="M13.2814 16.4167H7.50016C6.71776 16.4167 6.0835 15.7824 6.0835 15L6.0835 14.7604C6.0835 14.4613 5.84098 14.2188 5.54183 14.2188C5.24268 14.2188 5.00016 14.4613 5.00016 14.7604V15C5.00016 16.3807 6.11945 17.5 7.50016 17.5H13.2814C14.6621 17.5 15.7814 16.3807 15.7814 15L15.7814 5C15.7814 3.61929 14.6621 2.5 13.2814 2.5H7.50016C6.11945 2.5 5.00016 3.61929 5.00016 5V5.29167C5.00016 5.59082 5.24268 5.83333 5.54183 5.83333C5.84098 5.83333 6.0835 5.59082 6.0835 5.29167V5C6.0835 4.2176 6.71776 3.58333 7.50016 3.58333L13.2814 3.58333C13.4678 3.58333 13.6459 3.61934 13.8089 3.68478L12.5201 4.44419C11.7574 4.8936 11.2892 5.71284 11.2892 6.59809V13.3675C11.2892 14.2434 11.7477 15.0554 12.4976 15.508L13.8247 16.3087C13.6574 16.3783 13.4739 16.4167 13.2814 16.4167Z"
        stroke={color}
        strokeWidth="2.6"
        mask="url(#path-1-inside-1_3_372)"
      />
      <path
        d="M5.82615 7.42544C6.02757 7.20426 6.37016 7.18824 6.59134 7.38967L8.9046 9.49628C9.08104 9.65696 9.12692 9.90748 9.03601 10.1145C9.011 10.2054 8.96193 10.2909 8.88902 10.3609L6.63236 12.5281C6.41659 12.7353 6.07369 12.7283 5.86648 12.5126C5.65927 12.2968 5.66621 11.9539 5.88198 11.7467L7.1697 10.51H3.08415C2.78499 10.51 2.54248 10.2675 2.54248 9.96838C2.54248 9.66923 2.78499 9.42672 3.08415 9.42672H7.21925L5.86192 8.19064C5.64074 7.98921 5.62472 7.64662 5.82615 7.42544Z"
        stroke={color}
        strokeWidth="2.6"
        mask="url(#path-1-inside-1_3_372)"
      />
    </svg>
  );
};

export const SignInIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={SignInIconOutlined}
      solidSvg={SignInIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
