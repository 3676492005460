import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const ErrorOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 16.65C12.4142 16.65 12.75 16.3142 12.75 15.9C12.75 15.4858 12.4142 15.15 12 15.15C11.5858 15.15 11.25 15.4858 11.25 15.9C11.25 16.3142 11.5858 16.65 12 16.65Z"
        fill={color}
      />
      <path
        d="M12 13.7962C12.359 13.7962 12.65 13.5052 12.65 13.1462V7.99997C12.65 7.64099 12.359 7.34997 12 7.34997C11.641 7.34997 11.35 7.64099 11.35 7.99997V13.1462C11.35 13.5052 11.641 13.7962 12 13.7962Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM4.3 12C4.3 7.74741 7.74741 4.3 12 4.3C16.2526 4.3 19.7 7.74741 19.7 12C19.7 16.2526 16.2526 19.7 12 19.7C7.74741 19.7 4.3 16.2526 4.3 12Z"
        fill={color}
      />
    </svg>
  );
};

const ErrorSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V13C12.75 13.4142 12.4142 13.75 12 13.75C11.5858 13.75 11.25 13.4142 11.25 13V8C11.25 7.58579 11.5858 7.25 12 7.25ZM12 16.7C12.4694 16.7 12.85 16.3195 12.85 15.85C12.85 15.3806 12.4694 15 12 15C11.5306 15 11.15 15.3806 11.15 15.85C11.15 16.3195 11.5306 16.7 12 16.7Z"
        fill={color}
      />
    </svg>
  );
};

export const ErrorIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={ErrorOutlined}
      solidSvg={ErrorSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
