import React from 'react';
import { BasicIcon } from '../_utils/type';
import avatarIcon from '../assets/icons/avatar-icon.png';

export const AvatarIcon = ({ className }: BasicIcon) => (
  <img
    className={className}
    src={avatarIcon}
    alt=""
    style={{
      borderRadius: '50%',
      width: '32px',
      height: '32px',
    }}
  />
);
