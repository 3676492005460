export function useTypographyFontWeightClass(weight?: number | boolean) {
  if (typeof weight === 'boolean') {
    return weight ? 'typographyFontWeightBold' : 'typographyFontWeightNormal';
  }
  if (typeof weight === 'number') {
    return `typographyFontWeight${weight}`;
  }
  if (typeof weight === 'undefined') {
    return null;
  }
  return null;
}
