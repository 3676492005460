import clsx from 'clsx';
import React from 'react';
import {
  useTypographyAlignClass,
  useTypographyColorClass,
  useTypographyFontSizeClass,
  useTypographyFontWeightClass,
} from '../../_utils';
import { TypographyProps } from './typography-props';
import './typography.scss';

export function Typography<TComponent extends React.ElementType = 'span'>({
  children,
  component,
  componentProps,
  size,
  color,
  weight,
  align,
  ellips,
  underline,
}: TypographyProps<TComponent>) {
  const sizeClass = useTypographyFontSizeClass(size);
  const colorClass = useTypographyColorClass(color);
  const weightClass = useTypographyFontWeightClass(weight);
  const alignClass = useTypographyAlignClass(align);

  const Component = component ?? 'span';

  return (
    <Component
      {...componentProps}
      className={clsx(
        'typography',
        sizeClass,
        colorClass,
        weightClass,
        alignClass,
        componentProps?.className,
        ellips && 'ellips',
        underline && 'underline',
      )}
    >
      {children}
    </Component>
  );
}
