import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const ChevronRightOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.47396 18.7543C9.29396 18.7543 9.12396 18.6843 8.99396 18.5443C8.75396 18.2743 8.77396 17.8643 9.03396 17.6243L15.4031 11.884L8.99394 6.33399C8.72394 6.09399 8.71394 5.68398 8.95394 5.41398C9.19394 5.15398 9.60394 5.13399 9.87394 5.37399L16.7731 11.364C17.0831 11.644 17.0831 12.124 16.7731 12.404L9.91396 18.5843C9.78396 18.7043 9.63396 18.7543 9.47396 18.7543Z"
        fill={color}
      />
    </svg>
  );
};

const ChevronRightSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12ZM9.81369 16.3941C9.94369 16.5341 10.1137 16.6041 10.2937 16.6041C10.4537 16.6041 10.6037 16.5541 10.7337 16.4341L14.7737 12.5104C15.0837 12.2304 15.0837 11.7504 14.7737 11.4704L10.7137 7.56408C10.4437 7.32408 10.0337 7.34408 9.79369 7.60408C9.55369 7.87408 9.56369 8.28408 9.83369 8.52408L13.4073 11.9976L9.85369 15.4741C9.59369 15.7141 9.57369 16.1241 9.81369 16.3941Z"
        fill={color}
      />
    </svg>
  );
};

export const ChevronRightIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={ChevronRightOutlined}
      solidSvg={ChevronRightSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
