import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const CheckOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.79 15.29C11.07 15.57 11.53 15.56 11.8 15.27L16.22 10.47C16.46 10.2 16.44 9.79001 16.18 9.55001C15.92 9.31001 15.5 9.32001 15.26 9.59001L11.27 13.93L8.87001 11.53C8.62001 11.28 8.20001 11.28 7.95001 11.53C7.70001 11.78 7.70001 12.2 7.95001 12.45L10.79 15.29Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM4.3 12C4.3 7.74741 7.74741 4.3 12 4.3C16.2526 4.3 19.7 7.74741 19.7 12C19.7 16.2526 16.2526 19.7 12 19.7C7.74741 19.7 4.3 16.2526 4.3 12Z"
        fill={color}
      />
    </svg>
  );
};

const CheckSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM10.7899 15.2899C11.07 15.5699 11.5299 15.5599 11.7999 15.2699L16.2199 10.4699C16.4599 10.1999 16.4399 9.78988 16.1799 9.54988C15.9199 9.30988 15.4999 9.31988 15.2599 9.58988L11.2699 13.9299L8.86995 11.5299C8.61995 11.2799 8.19995 11.2799 7.94995 11.5299C7.69995 11.7799 7.69995 12.1999 7.94995 12.4499L10.7899 15.2899Z"
        fill={color}
      />
    </svg>
  );
};

export const CheckIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={CheckOutlined}
      solidSvg={CheckSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
