import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

const CopyTextOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99998 2C11.1046 1.99999 12 2.89542 12 4C13.1046 4 14 4.89543 14 6V12.5001C14 13.6046 13.1046 14.5001 12 14.5001H7C5.89543 14.5001 5 13.6046 5 12.5001C3.89543 12.5001 3 11.6046 3 10.5001V4.00005C3 2.89549 3.89542 2.00007 4.99998 2.00006L9.99998 2ZM9.99999 3L4.99999 3.00006C4.44771 3.00006 4 3.44778 4 4.00005V10.5001C4 11.0524 4.44772 11.5001 5 11.5001L5 6.00005C5 4.89549 5.89542 4.00007 6.99998 4.00006L11 4C11 3.44771 10.5523 2.99999 9.99999 3ZM12 13.5001H7C6.44772 13.5001 6 13.0524 6 12.5001V11.5001V6.00005C6 5.44778 6.44771 5.00006 6.99999 5.00006L11 5.00001L12 5C12.5523 5 13 5.44771 13 6V12.5001C13 13.0524 12.5523 13.5001 12 13.5001Z"
        fill={color}
      />
    </svg>
  );
};

export const CopyTextIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={CopyTextOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
