import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const RubOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.02564 7.65671C9.02564 7.29773 9.31666 7.00671 9.67564 7.00671H13.0035C14.8383 7.00671 16.3256 8.4941 16.3256 10.3289C16.3256 12.1637 14.8383 13.651 13.0035 13.651H10.3256V14.6994H13.3455C13.7044 14.6994 13.9955 14.9904 13.9955 15.3494C13.9955 15.7084 13.7044 15.9994 13.3455 15.9994H10.3256V16.8472C10.3256 17.2062 10.0346 17.4972 9.67564 17.4972C9.31666 17.4972 9.02564 17.2062 9.02564 16.8472V15.9994H8.32422C7.96523 15.9994 7.67422 15.7084 7.67422 15.3494C7.67422 14.9904 7.96523 14.6994 8.32422 14.6994H9.02564V13.651H8.33008C7.97109 13.651 7.68008 13.36 7.68008 13.001C7.68008 12.6421 7.97109 12.351 8.33008 12.351H9.02564V7.65671ZM10.3256 12.351H13.0035C14.1203 12.351 15.0256 11.4457 15.0256 10.3289C15.0256 9.21207 14.1203 8.30671 13.0035 8.30671H10.3256V12.351Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM19.7 12C19.7 16.2526 16.2526 19.7 12 19.7C7.74741 19.7 4.3 16.2526 4.3 12C4.3 7.74741 7.74741 4.3 12 4.3C16.2526 4.3 19.7 7.74741 19.7 12Z"
        fill={color}
      />
    </svg>
  );
};

const RubSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3257 12.3512H13.0036C14.1204 12.3512 15.0257 11.4458 15.0257 10.329C15.0257 9.21219 14.1204 8.30684 13.0036 8.30684H10.3257V12.3512Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM9.02574 7.65684C9.02574 7.29785 9.31675 7.00684 9.67574 7.00684H13.0036C14.8384 7.00684 16.3257 8.49422 16.3257 10.329C16.3257 12.1638 14.8384 13.6512 13.0036 13.6512H10.3257V14.6995H13.3456C13.7045 14.6995 13.9956 14.9905 13.9956 15.3495C13.9956 15.7085 13.7045 15.9995 13.3456 15.9995H10.3257V16.8473C10.3257 17.2063 10.0347 17.4973 9.67574 17.4973C9.31675 17.4973 9.02574 17.2063 9.02574 16.8473V15.9995H8.32432C7.96533 15.9995 7.67432 15.7085 7.67432 15.3495C7.67432 14.9905 7.96533 14.6995 8.32432 14.6995H9.02574V13.6512H8.33018C7.97119 13.6512 7.68018 13.3601 7.68018 13.0012C7.68018 12.6422 7.97119 12.3512 8.33018 12.3512H9.02574V7.65684Z"
        fill={color}
      />
    </svg>
  );
};

export const RubIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={RubOutlined}
      solidSvg={RubSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
