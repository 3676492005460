import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const RedoOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2215 6.38058C11.0103 6.01792 11.888 5.81001 12.82 5.81001C16.2316 5.81001 19.01 8.57961 19.01 12C19.01 15.421 16.241 18.19 12.82 18.19C9.40082 18.19 6.63998 15.4129 6.63998 12C6.63998 11.8907 6.64279 11.782 6.6484 11.674L7.73997 12.84C7.86997 12.98 8.04997 13.05 8.21997 13.05C8.37997 13.05 8.52997 13 8.63997 12.87C8.89997 12.62 8.91997 12.21 8.66997 11.95L6.47997 9.60001C6.20997 9.30001 5.73997 9.30001 5.45997 9.60001L3.26997 11.95C3.01997 12.21 3.03997 12.62 3.29997 12.87C3.55997 13.12 3.96997 13.1 4.21997 12.84L5.34876 11.6343C5.3429 11.7557 5.33997 11.8777 5.33997 12C5.33997 16.1271 8.67913 19.49 12.82 19.49C16.959 19.49 20.31 16.139 20.31 12C20.31 7.86041 16.9483 4.51001 12.82 4.51001C11.692 4.51001 10.6297 4.7621 9.67845 5.19944C9.35229 5.3494 9.20944 5.73537 9.3594 6.06154C9.50936 6.3877 9.89534 6.53054 10.2215 6.38058Z"
        fill={color}
      />
    </svg>
  );
};

const RedoSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM11.9965 7.9C11.3786 7.9 10.7968 8.03766 10.2734 8.27814C9.94717 8.42801 9.56124 8.28507 9.41137 7.95886C9.2615 7.63266 9.40445 7.24672 9.73065 7.09685C10.4164 6.78178 11.1828 6.6 11.9965 6.6C14.9744 6.6 17.4 9.01495 17.4 12C17.4 14.9844 14.982 17.4 11.9965 17.4C9.12588 17.4 6.78907 15.1613 6.61092 12.346L5.97 13C5.72 13.26 5.31 13.28 5.05 13.03C4.79 12.78 4.77 12.37 5.02 12.11L6.83671 10.225C7.11671 9.925 7.58671 9.925 7.85671 10.225L9.5 12.08C9.75 12.34 9.73 12.75 9.47 13C9.36 13.13 9.21 13.18 9.05 13.18C8.88 13.18 8.7 13.11 8.57 12.97L7.90535 12.2113C8.01516 14.374 9.80105 16.1 11.9965 16.1C14.2649 16.1 16.1 14.2656 16.1 12C16.1 9.73505 14.2586 7.9 11.9965 7.9Z"
        fill={color}
      />
    </svg>
  );
};

export const RedoIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={RedoOutlined}
      solidSvg={RedoSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
