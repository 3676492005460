import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const TreasuryOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7092 7.70932C17.7092 10.8625 15.1531 13.4186 11.9999 13.4186C8.84674 13.4186 6.29059 10.8625 6.29059 7.70932C6.29059 4.55615 8.84674 2 11.9999 2C15.1531 2 17.7092 4.55615 17.7092 7.70932ZM16.4092 7.70932C16.4092 10.1445 14.4351 12.1186 11.9999 12.1186C9.56471 12.1186 7.59059 10.1445 7.59059 7.70932C7.59059 5.27412 9.56471 3.3 11.9999 3.3C14.4351 3.3 16.4092 5.27412 16.4092 7.70932Z"
        fill={color}
      />
      <path
        d="M6.24833 16.7094L7.77651 14.9461C8.84107 15.5644 10.0546 15.9544 11.3502 16.0491C11.35 16.0558 11.3499 16.0626 11.3499 16.0693V17.9457C11.3499 18.3046 11.6409 18.5957 11.9999 18.5957C12.3589 18.5957 12.6499 18.3046 12.6499 17.9457V16.0693C12.6499 16.0609 12.6498 16.0526 12.6494 16.0443C13.9775 15.9372 15.218 15.5196 16.2981 14.8642L17.7515 16.5413C17.9866 16.8126 18.3971 16.8419 18.6684 16.6068C18.9397 16.3717 18.969 15.9612 18.7339 15.6899L16.9815 13.6678C16.9663 13.645 16.9496 13.6229 16.9312 13.6017C16.6984 13.3322 16.2938 13.3027 16.0018 13.5065C14.8585 14.3039 13.468 14.7716 11.9684 14.7716C10.5314 14.7716 9.19472 14.3422 8.07964 13.6047C7.80533 13.4233 7.43992 13.4429 7.20746 13.664C7.1519 13.6981 7.10055 13.7411 7.05562 13.7929L5.26592 15.858C5.03082 16.1293 5.06015 16.5398 5.33143 16.7749C5.60272 17.01 6.01323 16.9807 6.24833 16.7094Z"
        fill={color}
      />
      <path
        d="M4.11226 19.9507C4.83023 19.9507 5.41226 19.3687 5.41226 18.6507C5.41226 17.9327 4.83023 17.3507 4.11226 17.3507C3.39429 17.3507 2.81226 17.9327 2.81226 18.6507C2.81226 19.3687 3.39429 19.9507 4.11226 19.9507Z"
        fill={color}
      />
      <path
        d="M13.3 20.7C13.3 21.418 12.7179 22 12 22C11.282 22 10.7 21.418 10.7 20.7C10.7 19.9821 11.282 19.4 12 19.4C12.7179 19.4 13.3 19.9821 13.3 20.7Z"
        fill={color}
      />
      <path
        d="M21.188 18.6507C21.188 19.3687 20.6059 19.9507 19.8879 19.9507C19.17 19.9507 18.588 19.3687 18.588 18.6507C18.588 17.9327 19.17 17.3507 19.8879 17.3507C20.6059 17.3507 21.188 17.9327 21.188 18.6507Z"
        fill={color}
      />
      <path
        d="M12.7724 5.67296C12.7724 6.15465 11.9821 6.54513 11.0072 6.54513C10.0323 6.54513 9.24203 6.15465 9.24203 5.67296C9.24203 5.19127 10.0323 4.80078 11.0072 4.80078C11.9821 4.80078 12.7724 5.19127 12.7724 5.67296Z"
        fill={color}
      />
      <path
        d="M15 7.87502C15 8.35671 14.2097 8.74719 13.2348 8.74719C12.2599 8.74719 11.4696 8.35671 11.4696 7.87502C11.4696 7.39333 12.2599 7.00284 13.2348 7.00284C14.2097 7.00284 15 7.39333 15 7.87502Z"
        fill={color}
      />
      <path
        d="M11.5168 8.8449C11.5893 8.75296 11.7206 8.73877 11.8102 8.81319C11.9013 8.88896 12.3774 9.23927 13.2333 9.23927C14.0947 9.23927 14.5503 8.91405 14.6556 8.81887C14.7421 8.74073 14.8739 8.74937 14.95 8.83817C15.0261 8.92696 15.0177 9.06229 14.9312 9.14043C14.7534 9.30105 14.201 9.66761 13.2333 9.66761C12.2602 9.66761 11.6943 9.26801 11.5477 9.14611C11.4581 9.07169 11.4443 8.93683 11.5168 8.8449Z"
        fill={color}
      />
      <path
        d="M11.8102 9.68616C11.7206 9.61174 11.5893 9.62594 11.5168 9.71787C11.4443 9.8098 11.4581 9.94466 11.5477 10.0191C11.6916 10.1387 12.2746 10.5608 13.2505 10.5608C14.2245 10.5608 14.7587 10.1693 14.9312 10.0134C15.0177 9.93526 15.0261 9.79993 14.95 9.71114C14.8739 9.62234 14.7421 9.61371 14.6556 9.69185C14.545 9.79176 14.1056 10.1324 13.2505 10.1324C12.3973 10.1324 11.904 9.76417 11.8102 9.68616Z"
        fill={color}
      />
      <path
        d="M9.5826 6.61114C9.49305 6.53672 9.36169 6.55092 9.2892 6.64285C9.2167 6.73478 9.23053 6.86964 9.32008 6.94406C9.46675 7.06596 10.0326 7.46556 11.0058 7.46556C11.121 7.46556 11.2144 7.36967 11.2144 7.25139C11.2144 7.13311 11.121 7.03723 11.0058 7.03723C10.1498 7.03723 9.67376 6.68691 9.5826 6.61114Z"
        fill={color}
      />
      <path
        d="M9.2892 7.5158C9.36169 7.42387 9.49305 7.40967 9.5826 7.4841C9.67646 7.5621 10.1697 7.93038 11.0229 7.93038C11.1381 7.93038 11.2315 8.02626 11.2315 8.14455C11.2315 8.26283 11.1381 8.35871 11.0229 8.35871C10.0471 8.35871 9.46405 7.93667 9.32008 7.81702C9.23053 7.74259 9.2167 7.60773 9.2892 7.5158Z"
        fill={color}
      />
      <path
        d="M9.5826 8.38711C9.49305 8.31269 9.36169 8.32688 9.2892 8.41882C9.2167 8.51075 9.23053 8.64561 9.32008 8.72003C9.46405 8.83969 10.0471 9.26173 11.0229 9.26173C11.1381 9.26173 11.2315 9.16584 11.2315 9.04756C11.2315 8.92928 11.1381 8.8334 11.0229 8.8334C10.1697 8.8334 9.67646 8.46512 9.5826 8.38711Z"
        fill={color}
      />
      <path
        d="M12.7724 5.67296C12.7724 6.15465 11.9821 6.54513 11.0072 6.54513C10.0323 6.54513 9.24203 6.15465 9.24203 5.67296C9.24203 5.19127 10.0323 4.80078 11.0072 4.80078C11.9821 4.80078 12.7724 5.19127 12.7724 5.67296Z"
        stroke={color}
        strokeWidth="0.2"
        strokeLinecap="round"
      />
      <path
        d="M15 7.87502C15 8.35671 14.2097 8.74719 13.2348 8.74719C12.2599 8.74719 11.4696 8.35671 11.4696 7.87502C11.4696 7.39333 12.2599 7.00284 13.2348 7.00284C14.2097 7.00284 15 7.39333 15 7.87502Z"
        stroke={color}
        strokeWidth="0.2"
        strokeLinecap="round"
      />
      <path
        d="M11.5168 8.8449C11.5893 8.75296 11.7206 8.73877 11.8102 8.81319C11.9013 8.88896 12.3774 9.23927 13.2333 9.23927C14.0947 9.23927 14.5503 8.91405 14.6556 8.81887C14.7421 8.74073 14.8739 8.74937 14.95 8.83817C15.0261 8.92696 15.0177 9.06229 14.9312 9.14043C14.7534 9.30105 14.201 9.66761 13.2333 9.66761C12.2602 9.66761 11.6943 9.26801 11.5477 9.14611C11.4581 9.07169 11.4443 8.93683 11.5168 8.8449Z"
        stroke={color}
        strokeWidth="0.2"
        strokeLinecap="round"
      />
      <path
        d="M11.8102 9.68616C11.7206 9.61174 11.5893 9.62594 11.5168 9.71787C11.4443 9.8098 11.4581 9.94466 11.5477 10.0191C11.6916 10.1387 12.2746 10.5608 13.2505 10.5608C14.2245 10.5608 14.7587 10.1693 14.9312 10.0134C15.0177 9.93526 15.0261 9.79993 14.95 9.71114C14.8739 9.62234 14.7421 9.61371 14.6556 9.69185C14.545 9.79176 14.1056 10.1324 13.2505 10.1324C12.3973 10.1324 11.904 9.76417 11.8102 9.68616Z"
        stroke={color}
        strokeWidth="0.2"
        strokeLinecap="round"
      />
      <path
        d="M9.5826 6.61114C9.49305 6.53672 9.36169 6.55092 9.2892 6.64285C9.2167 6.73478 9.23053 6.86964 9.32008 6.94406C9.46675 7.06596 10.0326 7.46556 11.0058 7.46556C11.121 7.46556 11.2144 7.36967 11.2144 7.25139C11.2144 7.13311 11.121 7.03723 11.0058 7.03723C10.1498 7.03723 9.67376 6.68691 9.5826 6.61114Z"
        stroke={color}
        strokeWidth="0.2"
        strokeLinecap="round"
      />
      <path
        d="M9.2892 7.5158C9.36169 7.42387 9.49305 7.40967 9.5826 7.4841C9.67646 7.5621 10.1697 7.93038 11.0229 7.93038C11.1381 7.93038 11.2315 8.02626 11.2315 8.14455C11.2315 8.26283 11.1381 8.35871 11.0229 8.35871C10.0471 8.35871 9.46405 7.93667 9.32008 7.81702C9.23053 7.74259 9.2167 7.60773 9.2892 7.5158Z"
        stroke={color}
        strokeWidth="0.2"
        strokeLinecap="round"
      />
      <path
        d="M9.5826 8.38711C9.49305 8.31269 9.36169 8.32688 9.2892 8.41882C9.2167 8.51075 9.23053 8.64561 9.32008 8.72003C9.46405 8.83969 10.0471 9.26173 11.0229 9.26173C11.1381 9.26173 11.2315 9.16584 11.2315 9.04756C11.2315 8.92928 11.1381 8.8334 11.0229 8.8334C10.1697 8.8334 9.67646 8.46512 9.5826 8.38711Z"
        stroke={color}
        strokeWidth="0.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const TreasuryIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={TreasuryOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
