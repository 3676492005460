import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

export const GroupingOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.6323 3.65145C12.6047 3.13767 11.3952 3.13767 10.3677 3.65145L2.88432 7.39312C2.33153 7.66952 2.33153 8.45837 2.88432 8.73476L5.51122 10.0482L2.88432 11.3617C2.33153 11.6381 2.33153 12.4269 2.88432 12.7033L5.51153 14.0169L2.95408 15.2956C2.40129 15.572 2.4013 16.3609 2.95408 16.6373L10.3676 20.3441C11.3952 20.8578 12.6047 20.8578 13.6323 20.3441L21.0459 16.6373C21.5986 16.3609 21.5986 15.572 21.0459 15.2956L18.4884 14.0169L21.1157 12.7033C21.6684 12.4269 21.6684 11.6381 21.1157 11.3617L18.4887 10.0482L21.1157 8.73476C21.6684 8.45837 21.6684 7.66952 21.1157 7.39312L13.6323 3.65145ZM10.949 4.81421C11.6106 4.48341 12.3893 4.48341 13.0509 4.81421L19.5504 8.06394L13.0509 11.3137C12.3893 11.6445 11.6106 11.6445 10.949 11.3137L4.44956 8.06394L10.949 4.81421ZM17.0353 10.7749L13.6323 12.4764C12.6047 12.9902 11.3952 12.9902 10.3677 12.4764L6.96467 10.7749L4.44956 12.0325L10.949 15.2822C11.6106 15.613 12.3893 15.613 13.0509 15.2822L19.5504 12.0325L17.0353 10.7749ZM4.51933 15.9665L6.96497 14.7436L10.3677 16.445C11.3952 16.9588 12.6047 16.9588 13.6323 16.445L17.035 14.7437L19.4806 15.9665L13.0509 19.1813C12.3893 19.5121 11.6106 19.5121 10.949 19.1813L4.51933 15.9665Z"
        fill={color}
      />
    </svg>
  );
};

const GroupingSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.52704 15.0092L10.3677 18.4295C11.3952 18.9433 12.6047 18.9433 13.6323 18.4295L20.4729 15.0092L21.0459 15.2956C21.5986 15.572 21.5986 16.3609 21.0459 16.6373L13.6323 20.3441C12.6047 20.8578 11.3952 20.8578 10.3676 20.3441L2.95408 16.6373C2.4013 16.3609 2.40129 15.572 2.95408 15.2956L3.52704 15.0092Z"
        fill={color}
      />
      <path
        d="M3.52693 11.0403L10.3677 14.4606C11.3952 14.9744 12.6047 14.9744 13.6323 14.4606L20.473 11.0403L21.1157 11.3616C21.6684 11.638 21.6684 12.4268 21.1157 12.7032L13.6323 16.4449C12.6047 16.9587 11.3952 16.9587 10.3677 16.4449L2.88432 12.7032C2.33153 12.4268 2.33153 11.638 2.88432 11.3616L3.52693 11.0403Z"
        fill={color}
      />
      <path
        d="M13.6323 3.65145C12.6047 3.13767 11.3952 3.13767 10.3677 3.65145L2.88432 7.39312C2.33153 7.66952 2.33153 8.45837 2.88432 8.73476L10.3677 12.4764C11.3952 12.9902 12.6047 12.9902 13.6323 12.4764L21.1157 8.73476C21.6684 8.45837 21.6684 7.66952 21.1157 7.39312L13.6323 3.65145Z"
        fill={color}
      />
    </svg>
  );
};

export const GroupingIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={GroupingOutlined}
      solidSvg={GroupingSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
