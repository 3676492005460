import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const LoaderOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6571 3.65C12.6571 3.29101 12.3661 3 12.0071 3C11.6481 3 11.3571 3.29101 11.3571 3.65V7.35C11.3571 7.70899 11.6481 8 12.0071 8C12.3661 8 12.6571 7.70899 12.6571 7.35L12.6571 3.65Z"
        fill={color}
      />
      <path
        d="M8.2523 9.17161C8.50614 9.42545 8.91769 9.42545 9.17153 9.17161C9.42537 8.91777 9.42537 8.50621 9.17153 8.25237L6.55524 5.63607C6.3014 5.38223 5.88984 5.38223 5.636 5.63607C5.38216 5.88991 5.38216 6.30147 5.636 6.55531L8.2523 9.17161Z"
        fill={color}
      />
      <path
        d="M8 12C8 12.359 7.70899 12.65 7.35 12.65L3.65 12.65C3.29101 12.65 3 12.359 3 12C3 11.641 3.29101 11.35 3.65 11.35H7.35C7.70899 11.35 8 11.641 8 12Z"
        fill={color}
      />
      <path
        d="M12.0071 16C12.3661 16 12.6571 16.291 12.6571 16.65L12.6571 20.35C12.6571 20.709 12.3661 21 12.0071 21C11.6481 21 11.3571 20.709 11.3571 20.35V16.65C11.3571 16.291 11.6481 16 12.0071 16Z"
        fill={color}
      />
      <path
        d="M9.17869 14.8285C9.43253 15.0823 9.43253 15.4939 9.17869 15.7477L6.56239 18.364C6.30855 18.6178 5.89699 18.6178 5.64315 18.364C5.38931 18.1102 5.38931 17.6986 5.64315 17.4448L8.25945 14.8285C8.51329 14.5746 8.92485 14.5746 9.17869 14.8285Z"
        fill={color}
      />
      <path
        d="M21 12C21 12.359 20.709 12.65 20.35 12.65L16.65 12.65C16.291 12.65 16 12.359 16 12C16 11.641 16.291 11.35 16.65 11.35H20.35C20.709 11.35 21 11.641 21 12Z"
        fill={color}
      />
      <path
        d="M17.4447 18.364C17.6985 18.6178 18.1101 18.6178 18.3639 18.364C18.6178 18.1101 18.6178 17.6986 18.3639 17.4447L15.7476 14.8285C15.4938 14.5746 15.0822 14.5746 14.8284 14.8285C14.5745 15.0823 14.5745 15.4939 14.8284 15.7477L17.4447 18.364Z"
        fill={color}
      />
    </svg>
  );
};

const LoaderSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0073 21C16.9779 21 21.0073 16.9706 21.0073 12C21.0073 7.02944 16.9779 3 12.0073 3C7.03676 3 3.00732 7.02944 3.00732 12C3.00732 16.9706 7.03676 21 12.0073 21ZM12.6573 15.6541C12.6573 15.2928 12.3663 15 12.0073 15C11.6483 15 11.3573 15.2928 11.3573 15.6541V17.8459C11.3573 18.2072 11.6483 18.5 12.0073 18.5C12.3663 18.5 12.6573 18.2072 12.6573 17.8459V15.6541ZM9.00018 12C9.00018 12.359 8.70734 12.65 8.3461 12.65H6.15427C5.79303 12.65 5.50018 12.359 5.50018 12C5.50018 11.641 5.79303 11.35 6.15427 11.35H8.3461C8.70734 11.35 9.00018 11.641 9.00018 12ZM8.96391 9.87579C9.21935 10.1312 9.6322 10.1325 9.88604 9.87868C10.1399 9.62484 10.1386 9.21199 9.88315 8.95655L8.3333 7.4067C8.07786 7.15126 7.66501 7.14996 7.41117 7.40381C7.15733 7.65765 7.15862 8.0705 7.41406 8.32594L8.96391 9.87579ZM9.87885 14.1213C10.1327 14.3751 10.1314 14.788 9.87596 15.0434L8.32611 16.5933C8.07067 16.8487 7.65782 16.85 7.40398 16.5962C7.15014 16.3423 7.15143 15.9295 7.40687 15.674L8.95672 14.1242C9.21216 13.8688 9.62501 13.8675 9.87885 14.1213ZM12.6573 6.15409C12.6573 5.79285 12.3663 5.5 12.0073 5.5C11.6483 5.5 11.3573 5.79285 11.3573 6.15409V8.34591C11.3573 8.70716 11.6483 9 12.0073 9C12.3663 9 12.6573 8.70716 12.6573 8.34591V6.15409ZM18.5002 12C18.5002 12.359 18.2073 12.65 17.8461 12.65H15.6543C15.293 12.65 15.0002 12.359 15.0002 12C15.0002 11.641 15.293 11.35 15.6543 11.35H17.8461C18.2073 11.35 18.5002 11.641 18.5002 12ZM15.6814 16.5933C15.9368 16.8487 16.3497 16.85 16.6035 16.5962C16.8574 16.3424 16.8561 15.9295 16.6006 15.6741L15.0508 14.1242C14.7954 13.8688 14.3825 13.8675 14.1287 14.1213C13.8748 14.3752 13.8761 14.788 14.1316 15.0434L15.6814 16.5933Z"
        fill={color}
      />
    </svg>
  );
};

export const LoaderIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={LoaderOutlined}
      solidSvg={LoaderSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
