import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const ChevronDownOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.22607 8.56014C5.22607 8.38014 5.29607 8.21014 5.43607 8.08014C5.70607 7.84014 6.11607 7.86014 6.35607 8.12014L12.0964 14.4893L17.6464 8.08012C17.8864 7.81012 18.2964 7.80012 18.5664 8.04012C18.8264 8.28012 18.8464 8.69012 18.6064 8.96012L12.6164 15.8593C12.3364 16.1693 11.8564 16.1693 11.5764 15.8593L5.39607 9.00014C5.27607 8.87014 5.22607 8.72014 5.22607 8.56014Z"
        fill={color}
      />
    </svg>
  );
};

const ChevronDownSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02943 21 12 21ZM7.60592 9.81369C7.46592 9.94369 7.39592 10.1137 7.39592 10.2937C7.39592 10.4537 7.44592 10.6037 7.56592 10.7337L11.4896 14.7737C11.7696 15.0837 12.2496 15.0837 12.5296 14.7737L16.4359 10.7137C16.6759 10.4437 16.6559 10.0337 16.3959 9.79369C16.1259 9.55369 15.7159 9.56369 15.4759 9.83369L12.0024 13.4074L8.52592 9.85369C8.28592 9.59369 7.87592 9.57369 7.60592 9.81369Z"
        fill={color}
      />
    </svg>
  );
};

export const ChevronDownIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={ChevronDownOutlined}
      solidSvg={ChevronDownSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
