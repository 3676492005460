import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

export const MegaphoneOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.02014 12.0001C7.02014 11.4502 7.46786 11.0001 8.02014 11.0001C8.57243 11.0001 9.02014 11.4502 9.02014 12.0001C9.02014 12.5499 8.57243 13.0001 8.02014 13.0001C7.46786 13.0001 7.02014 12.5499 7.02014 12.0001Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.763 8.0059H12.8806C14.5795 8.0059 16.0246 6.76683 16.2838 5.08778C16.3801 4.46413 16.9168 4.00391 17.5479 4.00391H18.7C19.418 4.00391 20 4.58594 20 5.30391V18.6962C20 19.4142 19.418 19.9962 18.7 19.9962H17.5424C16.9144 19.9962 16.3801 19.5385 16.2838 18.9179C16.0245 17.2471 14.5861 16.0147 12.8953 16.0147H11.7695V18.7074C11.7695 19.9736 10.7431 21 9.47699 21C8.37484 21 7.42867 20.2157 7.22423 19.1327L6.62927 15.981C5.14734 15.7983 4 14.535 4 13.0037V11.0037C4 9.34681 5.34315 8.00366 7 8.00366H11.763V8.0059ZM18.7 18.6962H17.5649C17.198 16.4034 15.2196 14.7147 12.8953 14.7147H11.774V9.3059H12.8806C15.2149 9.3059 17.2013 7.60779 17.5658 5.30391H18.7V18.6962ZM10.463 14.7032H6.95925C6.03919 14.6815 5.3 13.9289 5.3 13.0037V11.0037C5.3 10.0648 6.06112 9.30367 7 9.30367H10.463V14.7032ZM7.95651 16.0037L8.50167 18.8916C8.59018 19.3605 8.99982 19.7 9.47699 19.7C10.0252 19.7 10.4695 19.2556 10.4695 18.7074V16.0037H7.95651Z"
        fill={color}
      />
    </svg>
  );
};

const MegaphoneSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.712 16.1129C15.031 16.4358 16.0675 17.5244 16.2838 18.9179C16.3801 19.5385 16.9144 19.9962 17.5424 19.9962H18.7C19.418 19.9962 20 19.4142 20 18.6962V5.30391C20 4.58594 19.418 4.00391 18.7 4.00391H17.5479C16.9168 4.00391 16.3801 4.46413 16.2838 5.08778C16.068 6.48543 15.0306 7.57821 13.709 7.90525C13.3466 7.99493 13.0503 8.2951 13.0503 8.66843V15.3488C13.0503 15.7232 13.3483 16.0239 13.712 16.1129Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 13.0037C4 14.535 5.14732 15.7983 6.62925 15.981L6.62985 15.9842H11.0599C11.4465 15.9842 11.7599 15.6708 11.7599 15.2842V8.70366C11.7599 8.31706 11.4465 8.00366 11.0599 8.00366H7C5.34315 8.00366 4 9.34681 4 11.0037V13.0037ZM7.47503 12.0001C7.47503 11.4503 7.92275 11.0001 8.47503 11.0001C9.02731 11.0001 9.47503 11.4503 9.47503 12.0001C9.47503 12.5499 9.02731 13.0001 8.47503 13.0001C7.92275 13.0001 7.47503 12.5499 7.47503 12.0001Z"
        fill={color}
      />
      <path
        d="M11.7599 18.7075V17.9842C11.7599 17.5976 11.4465 17.2842 11.0599 17.2842H8.66189C8.22324 17.2842 7.89267 17.683 7.97404 18.114L8.16635 19.1328C8.37079 20.2158 9.08738 21.0001 10.1895 21.0001C11.4557 21.0001 11.7599 19.9737 11.7599 18.7075Z"
        fill={color}
      />
    </svg>
  );
};

export const MegaphoneIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={MegaphoneOutlined}
      solidSvg={MegaphoneSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
