import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const MetricOutline = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8567 15.5809V9.15904C15.8567 8.26157 15.1291 7.53404 14.2317 7.53404C13.3342 7.53404 12.6067 8.26157 12.6067 9.15904L12.6067 15.5809H11.625V12.9611C11.625 12.0637 10.8975 11.3361 10 11.3361C9.10253 11.3361 8.375 12.0637 8.375 12.9611V15.5809H7.39323L7.39323 10.8257C7.39323 9.92824 6.66569 9.2007 5.76823 9.2007C4.87076 9.2007 4.14323 9.92824 4.14323 10.8257L4.14323 15.5809H3.27799C2.97884 15.5809 2.73633 15.8234 2.73633 16.1226C2.73633 16.4217 2.97884 16.6642 3.27799 16.6642H4.9345C4.93436 16.6642 4.93463 16.6642 4.9345 16.6642H6.60156C6.60137 16.6642 6.60175 16.6642 6.60156 16.6642H9.16591C9.16566 16.6642 9.16616 16.6642 9.16591 16.6642H10.8333C10.8336 16.6642 10.8339 16.6642 10.8342 16.6642H13.3973C13.3977 16.6642 13.398 16.6642 13.3983 16.6642H15.065C15.0654 16.6642 15.0657 16.6642 15.0661 16.6642H16.722C17.0212 16.6642 17.2637 16.4217 17.2637 16.1226C17.2637 15.8234 17.0212 15.5809 16.722 15.5809H15.8567ZM14.7733 15.5809V9.15904C14.7733 8.85988 14.5308 8.61737 14.2317 8.61737C13.9325 8.61737 13.69 8.85988 13.69 9.15904V15.5809H14.7733ZM5.22656 15.5809L5.22656 10.8257C5.22656 10.5265 5.46907 10.284 5.76823 10.284C6.06738 10.284 6.30989 10.5265 6.30989 10.8257V15.5809H5.22656ZM9.45833 15.5809H10.5417V12.9611C10.5417 12.662 10.2992 12.4195 10 12.4195C9.70084 12.4195 9.45833 12.662 9.45833 12.9611V15.5809Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8983 4.74235C15.8983 5.66282 15.1521 6.40902 14.2317 6.40902C13.9515 6.40902 13.6875 6.3399 13.4558 6.2178L11.5504 7.98377C11.5456 7.9717 11.5407 7.9597 11.5357 7.94777C11.62 8.14717 11.6667 8.3664 11.6667 8.59652C11.6667 9.51699 10.9205 10.2632 9.99999 10.2632C9.07952 10.2632 8.33333 9.51699 8.33333 8.59652C8.33333 8.54233 8.33591 8.48875 8.34097 8.43589L6.87912 7.65149C6.58428 7.91529 6.19498 8.07568 5.76822 8.07568C4.84775 8.07568 4.10155 7.32949 4.10155 6.40902C4.10155 5.48854 4.84775 4.74235 5.76822 4.74235C6.6887 4.74235 7.43489 5.48854 7.43489 6.40902C7.43489 6.51041 7.42583 6.60969 7.40849 6.7061L8.79185 7.44839C8.76486 7.47678 8.73887 7.50612 8.71391 7.53636C9.0196 7.16595 9.48223 6.92985 9.99999 6.92985C10.3086 6.92985 10.5977 7.01374 10.8456 7.15997C10.8456 7.15997 10.8456 7.15997 10.8456 7.15997L12.7127 5.4294C12.6178 5.21991 12.565 4.9873 12.565 4.74235C12.565 3.82188 13.3112 3.07568 14.2317 3.07568C15.1521 3.07568 15.8983 3.82188 15.8983 4.74235ZM14.815 4.74235C14.815 5.06452 14.5538 5.32568 14.2317 5.32568C13.9095 5.32568 13.6483 5.06452 13.6483 4.74235C13.6483 4.42018 13.9095 4.15902 14.2317 4.15902C14.5538 4.15902 14.815 4.42018 14.815 4.74235ZM6.35155 6.40902C6.35155 6.73118 6.09039 6.99235 5.76822 6.99235C5.44606 6.99235 5.18489 6.73118 5.18489 6.40902C5.18489 6.08685 5.44606 5.82568 5.76822 5.82568C6.09039 5.82568 6.35155 6.08685 6.35155 6.40902ZM9.99999 9.17985C10.3222 9.17985 10.5833 8.91868 10.5833 8.59652C10.5833 8.27435 10.3222 8.01318 9.99999 8.01318C9.67783 8.01318 9.41666 8.27435 9.41666 8.59652C9.41666 8.91868 9.67783 9.17985 9.99999 9.17985Z"
        fill={color}
      />
    </svg>
  );
};

export const MetricIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={MetricOutline}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
