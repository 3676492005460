import clsx from 'clsx';
import React from 'react';
import classes from './style.module.scss';

type IconStatusProps = {
  status: string;
  className?: string;
};

// TODO сделать список возможных статусов и типизировать
export const IconStatus = ({ status, className }: IconStatusProps) => {
  return (
    <span
      className={clsx(className, classes.statusIcon, [
        (status.includes('Активн') || status.includes('active')) && classes.activeStatus,
        (status.includes('Остан') || status.includes('Ожидает подтверждения') || status.includes('completed')) &&
          classes.stopStatus,
        (status.includes('Архивн') || status.includes('blocked') || status.includes('archived')) &&
          classes.archiveStatus,
        (status.includes('Ошиб') || status.includes('error') || status.includes('banned')) && classes.errorStatus,
      ])}
    ></span>
  );
};
