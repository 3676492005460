import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const TreasuryRubOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8645 8.24582H11.5841V8.84949H12.8366C13.1956 8.84949 13.4866 9.1405 13.4866 9.49949C13.4866 9.85847 13.1956 10.1495 12.8366 10.1495H11.5841V10.466C11.5841 10.825 11.2931 11.116 10.9341 11.116C10.5751 11.116 10.2841 10.825 10.2841 10.466V10.1495H9.85081C9.49182 10.1495 9.20081 9.85847 9.20081 9.49949C9.20081 9.1405 9.49182 8.84949 9.85081 8.84949H10.2841V8.24582H9.86444C9.50545 8.24582 9.21444 7.9548 9.21444 7.59582C9.21444 7.23683 9.50545 6.94582 9.86444 6.94582H10.2841V5.62465C10.2841 5.09954 10.7099 4.6748 11.2341 4.6748H12.8645C13.8506 4.6748 14.65 5.4742 14.65 6.46031C14.65 7.44642 13.8506 8.24582 12.8645 8.24582ZM11.5841 6.94582V5.9748H12.8645C13.1326 5.9748 13.35 6.19217 13.35 6.46031C13.35 6.72845 13.1326 6.94582 12.8645 6.94582H11.5841Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7092 7.70932C17.7092 10.8625 15.1531 13.4186 11.9999 13.4186C8.84674 13.4186 6.29059 10.8625 6.29059 7.70932C6.29059 4.55615 8.84674 2 11.9999 2C15.1531 2 17.7092 4.55615 17.7092 7.70932ZM16.4092 7.70932C16.4092 10.1445 14.4351 12.1186 11.9999 12.1186C9.56471 12.1186 7.59059 10.1445 7.59059 7.70932C7.59059 5.27412 9.56471 3.3 11.9999 3.3C14.4351 3.3 16.4092 5.27412 16.4092 7.70932Z"
        fill={color}
      />
      <path
        d="M6.24833 16.7094L7.77652 14.9461C8.84107 15.5644 10.0546 15.9544 11.3502 16.0491C11.35 16.0558 11.3499 16.0626 11.3499 16.0693V17.9457C11.3499 18.3046 11.6409 18.5957 11.9999 18.5957C12.3589 18.5957 12.6499 18.3046 12.6499 17.9457V16.0693C12.6499 16.0609 12.6498 16.0526 12.6494 16.0443C13.9775 15.9372 15.218 15.5196 16.2981 14.8642L17.7515 16.5413C17.9866 16.8126 18.3971 16.8419 18.6684 16.6068C18.9397 16.3717 18.969 15.9612 18.7339 15.6899L16.9815 13.6678C16.9663 13.645 16.9496 13.6229 16.9312 13.6017C16.6984 13.3322 16.2938 13.3027 16.0018 13.5065C14.8585 14.3039 13.468 14.7716 11.9684 14.7716C10.5314 14.7716 9.19472 14.3422 8.07965 13.6047C7.80533 13.4233 7.43992 13.4429 7.20746 13.664C7.1519 13.6981 7.10055 13.7411 7.05562 13.7929L5.26592 15.858C5.03082 16.1293 5.06015 16.5398 5.33143 16.7749C5.60272 17.01 6.01323 16.9807 6.24833 16.7094Z"
        fill={color}
      />
      <path
        d="M4.11226 19.9507C4.83023 19.9507 5.41226 19.3687 5.41226 18.6507C5.41226 17.9327 4.83023 17.3507 4.11226 17.3507C3.39429 17.3507 2.81226 17.9327 2.81226 18.6507C2.81226 19.3687 3.39429 19.9507 4.11226 19.9507Z"
        fill={color}
      />
      <path
        d="M13.3 20.7C13.3 21.418 12.7179 22 12 22C11.282 22 10.7 21.418 10.7 20.7C10.7 19.9821 11.282 19.4 12 19.4C12.7179 19.4 13.3 19.9821 13.3 20.7Z"
        fill={color}
      />
      <path
        d="M21.188 18.6507C21.188 19.3687 20.6059 19.9507 19.888 19.9507C19.17 19.9507 18.588 19.3687 18.588 18.6507C18.588 17.9327 19.17 17.3507 19.888 17.3507C20.6059 17.3507 21.188 17.9327 21.188 18.6507Z"
        fill={color}
      />
    </svg>
  );
};

export const TreasuryRubIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={TreasuryRubOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
