import { SvgIcon } from '@mui/material';
import React from 'react';
import { BasicIcon } from '../../_utils/type';
import '../icon.scss';

export const MailRuTextIcon: React.VFC = ({ className }: BasicIcon) => (
  <SvgIcon viewBox="0 0 39 16" width={39} height={16} className={`no-size ${className}`}>
    <path
      d="M2.69899 9.38545V15.6196H0.558594V4.84886H2.69899V5.84399C3.40307 5.05374 4.47326 4.58545 5.62795 4.58545C6.95162 4.58545 8.07814 5.17081 8.83855 6.25374C9.68344 5.20008 11.0071 4.58545 12.4434 4.58545C15.0626 4.58545 16.8087 6.60496 16.8087 9.47325V15.6489H14.6683V9.50252C14.6683 7.8635 13.7389 6.7513 12.3026 6.7513C10.8663 6.7513 9.73977 7.89277 9.73977 9.41472V15.6489H7.59937V9.50252C7.59937 7.8635 6.66999 6.7513 5.23367 6.7513C3.82552 6.72203 2.69899 7.8635 2.69899 9.38545Z"
      fill="#005EF9"
    />
    <path
      d="M27.3968 6.01944V4.84871H29.5372V15.6194H27.3968V14.4487C26.5237 15.356 25.3127 15.9121 23.9045 15.9121C20.8629 15.9121 18.5254 13.4536 18.5254 10.2633C18.5254 7.04383 20.8629 4.61456 23.9045 4.61456C25.3409 4.55603 26.5237 5.11212 27.3968 6.01944ZM20.7784 10.2048C20.7784 12.2536 22.1866 13.717 24.1017 13.717C26.0449 13.717 27.4249 12.2243 27.4249 10.2048C27.4249 8.18529 26.0449 6.69261 24.1017 6.69261C22.1866 6.72188 20.7784 8.15602 20.7784 10.2048Z"
      fill="#005EF9"
    />
    <path
      d="M34.5228 1.57089C34.5228 2.36114 33.9032 3.00504 33.1428 3.00504C32.3824 3.00504 31.791 2.36114 31.791 1.57089C31.791 0.780648 32.3824 0.166016 33.1428 0.166016C33.9032 0.166016 34.5228 0.780648 34.5228 1.57089ZM34.213 15.6197H32.0726V4.84894H34.213V15.6197Z"
      fill="#005EF9"
    />
    <path d="M38.9998 15.6194H36.8594V0.399902H38.9998V15.6194Z" fill="#005EF9" />
  </SvgIcon>
);
