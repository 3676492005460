import React from 'react';
import { TColor } from '../../../styles';

export type TTextSize = 'm' | 's' | 'xs' | 'xss';

export type TTextWeight = 'regular' | 'semi-bold' | 'bold';

export type TTextAlign = 'left' | 'center' | 'right';

export interface TextProps extends React.PropsWithChildren<{}> {
  color?: TColor;
  size?: TTextSize;
  weight?: TTextWeight;
  increaseLineHeight?: boolean;
  align?: TTextAlign;
  props?: React.ComponentProps<'span'>;
}
