import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const PauseOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2133 8.43136C10.5723 8.43136 10.8633 8.72238 10.8633 9.08136V14.9442C10.8633 15.3032 10.5723 15.5942 10.2133 15.5942C9.85431 15.5942 9.5633 15.3032 9.5633 14.9442L9.5633 9.08136C9.5633 8.72238 9.85431 8.43136 10.2133 8.43136Z"
        fill={color}
      />
      <path
        d="M13.7867 8.43136C14.1457 8.43136 14.4367 8.72238 14.4367 9.08136L14.4367 14.9442C14.4367 15.3032 14.1457 15.5942 13.7867 15.5942C13.4277 15.5942 13.1367 15.3032 13.1367 14.9442V9.08136C13.1367 8.72238 13.4277 8.43136 13.7867 8.43136Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 19.7C16.2526 19.7 19.7 16.2526 19.7 12C19.7 7.74741 16.2526 4.3 12 4.3C7.74741 4.3 4.3 7.74741 4.3 12C4.3 16.2526 7.74741 19.7 12 19.7Z"
        fill={color}
      />
    </svg>
  );
};

const PauseSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM10.8633 9.08137C10.8633 8.72238 10.5723 8.43137 10.2133 8.43137C9.85432 8.43137 9.5633 8.72238 9.5633 9.08137L9.5633 14.9443C9.5633 15.3032 9.85432 15.5943 10.2133 15.5943C10.5723 15.5943 10.8633 15.3032 10.8633 14.9443V9.08137ZM14.4367 9.08137C14.4367 8.72238 14.1457 8.43137 13.7867 8.43137C13.4277 8.43137 13.1367 8.72238 13.1367 9.08137V14.9443C13.1367 15.3032 13.4277 15.5943 13.7867 15.5943C14.1457 15.5943 14.4367 15.3032 14.4367 14.9443L14.4367 9.08137Z"
        fill={color}
      />
    </svg>
  );
};

export const PauseIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={PauseOutlined}
      solidSvg={PauseSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
