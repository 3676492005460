import React from 'react';
import { BasicIcon } from '../_utils/type';

export const EmptyTableNoCompany = ({ className }: BasicIcon) => {
  return (
    <svg
      width="242"
      height="182"
      viewBox="0 0 242 182"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="242" height="181.5" fill="white" />
      <path
        d="M183.118 154H190.882C192.096 154 193.188 154.971 193.188 156.305V164.07C193.188 165.283 192.217 166.375 190.882 166.375H183.118C181.904 166.375 180.812 165.404 180.812 164.07V156.184C180.812 154.971 181.904 154 183.118 154Z"
        fill="#7FC222"
      />
      <path
        d="M183.118 21.3125H190.882C192.096 21.3125 193.188 22.2831 193.188 23.6176V31.3824C193.188 32.5956 192.217 33.6875 190.882 33.6875H183.118C181.904 33.6875 180.812 32.7169 180.812 31.3824V23.4963C180.812 22.2831 181.904 21.3125 183.118 21.3125Z"
        fill="#F5AB3D"
      />
      <path
        d="M82.8581 149.467C79.0106 149.19 75.513 147.234 73.2621 144.102C71.6652 141.88 70.8221 139.246 70.8241 136.485C70.8248 135.558 70.0993 134.798 69.1835 134.754C69.103 134.749 69.0104 134.748 68.9076 134.754C67.9898 134.795 67.2612 135.554 67.2602 136.482C67.2555 140.663 65.2504 144.61 61.896 147.041C59.9505 148.45 57.645 149.286 55.2289 149.456C54.3259 149.52 53.6257 150.273 53.625 151.182C53.6243 152.091 54.3236 152.847 55.2263 152.912C59.0738 153.189 62.5717 155.144 64.8223 158.277C66.1868 160.176 67.013 162.426 67.2117 164.783C67.2858 165.661 68.0019 166.339 68.8716 166.368C68.9208 166.372 69.0029 166.375 69.042 166.375C69.0989 166.375 69.1549 166.372 69.2145 166.368C70.0808 166.336 70.7938 165.66 70.8689 164.786C71.1946 160.996 73.1334 157.552 76.188 155.338C78.1339 153.927 80.4391 153.092 82.8551 152.923C83.7581 152.859 84.4587 152.105 84.4594 151.196C84.46 150.287 83.7608 149.532 82.8581 149.467Z"
        fill="url(#paint0_linear_816_494880)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.283 66.0318C147.271 67.3207 146.217 68.3558 144.928 68.3455L91.9554 67.9193C90.6632 67.9089 89.6247 66.8516 89.6375 65.5594L89.6403 65.282C89.653 63.9932 90.7069 62.958 91.9958 62.9684L144.968 63.3945C146.26 63.4049 147.299 64.4622 147.286 65.7545L147.283 66.0318Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.481 81.1447C147.471 82.4379 146.413 83.4772 145.12 83.4637L92.1454 82.9112C90.8574 82.8978 89.8232 81.8448 89.833 80.5568L89.8392 79.7296C89.849 78.4364 90.9072 77.3971 92.2004 77.4106L145.175 77.9631C146.463 77.9765 147.497 79.0296 147.487 80.3175L147.481 81.1447Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.84 95.2576C120.822 96.5491 119.759 97.5807 118.468 97.561L92.3408 97.1617C91.0513 97.142 90.0216 96.0814 90.0399 94.792L90.0489 94.1604C90.0672 92.869 91.1297 91.8373 92.4212 91.857L118.548 92.2563C119.838 92.276 120.867 93.3366 120.849 94.626L120.84 95.2576Z"
        fill="white"
      />
      <path
        d="M79.0625 49.25V104.804C79.0625 108.557 81.023 112.038 84.2326 113.982L149.916 153.784C152.153 155.139 154.717 155.856 157.332 155.856H161.812C166.231 155.856 169.812 152.274 169.812 147.856V49.25C169.812 44.8317 166.231 41.25 161.812 41.25H87.0625C82.6442 41.25 79.0625 44.8317 79.0625 49.25Z"
        fill="url(#paint1_linear_816_494880)"
      />
      <path
        d="M91.3593 113.309C86.9325 115.031 84.5551 113.883 82.9976 113.309L151.859 154.872L123.335 112.997C119.703 107.664 112.953 105.428 106.882 107.614C100.201 110.02 93.2864 112.56 91.3593 113.309Z"
        fill="#EAF1FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.985 61.7115C152.981 62.6349 152.229 63.3802 151.306 63.3762L97.5505 63.1455C96.6271 63.1415 95.8818 62.3898 95.8858 61.4664L95.8934 59.6981C95.8973 58.7748 96.6491 58.0295 97.5724 58.0334L151.328 58.2641C152.251 58.2681 152.996 59.0198 152.992 59.9432L152.985 61.7115Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.985 76.1939C152.981 77.1173 152.229 77.8626 151.306 77.8586L97.5505 77.6279C96.6271 77.6239 95.8818 76.8722 95.8858 75.9489L95.8934 74.1805C95.8973 73.2572 96.6491 72.5119 97.5724 72.5158L151.328 72.7466C152.251 72.7505 152.996 73.5023 152.992 74.4256L152.985 76.1939Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M126.153 90.7353C126.143 91.6586 125.387 92.3996 124.464 92.3903L97.5504 92.1192C96.6271 92.1099 95.8861 91.3539 95.8954 90.4306L95.9132 88.6624C95.9225 87.7391 96.6785 86.9981 97.6019 87.0074L124.515 87.2784C125.439 87.2877 126.18 88.0437 126.17 88.967L126.153 90.7353Z"
        fill="white"
      />
      <path
        d="M176.69 60.2969C177.05 59.8508 177.703 59.7811 178.149 60.141L189.261 69.108C189.707 69.468 189.776 70.1214 189.416 70.5674L151.558 117.476C151.386 117.689 151.136 117.826 150.863 117.856L140.801 118.956C140.23 119.019 139.716 118.604 139.656 118.032L138.607 107.965C138.579 107.692 138.659 107.419 138.832 107.205L176.69 60.2969Z"
        fill="url(#paint2_linear_816_494880)"
      />
      <path
        d="M139.22 113.961L144.877 118.526L140.979 121.852C139.977 122.707 138.508 122.727 137.483 121.9C136.458 121.073 136.168 119.633 136.792 118.473L139.22 113.961Z"
        fill="#1654B6"
      />
      <path
        d="M175.792 61.4096C178.224 58.3966 182.638 57.9253 185.651 60.3569L187.467 61.8229C190.48 64.2544 190.951 68.6682 188.52 71.6812L175.18 88.2109L162.452 77.9393L175.792 61.4096Z"
        fill="#57C3FF"
      />
      <path
        d="M180.664 65.0216C181.073 64.534 181.797 64.4631 182.292 64.8621L184.089 66.3078C184.598 66.7181 184.672 67.4671 184.251 67.9686L166.476 89.171C166.067 89.6585 165.344 89.7294 164.848 89.3305L163.052 87.8848C162.542 87.4745 162.469 86.7255 162.889 86.224L180.664 65.0216Z"
        fill="#1654B6"
      />
      <path
        d="M97.3858 33.5445C95.6738 33.5445 94.2455 32.1606 94.2455 30.3953C94.2455 28.6833 95.6295 27.2461 97.3858 27.2461C99.0979 27.2461 100.526 28.63 100.526 30.3953C100.437 32.1162 99.0979 33.5445 97.3858 33.5445Z"
        fill="white"
      />
      <path
        d="M84.6291 33.5445C82.917 33.5445 81.4888 32.1606 81.4888 30.3953C81.4888 28.6833 82.8726 27.2461 84.6291 27.2461C86.3412 27.2461 87.7694 28.63 87.7694 30.3953C87.725 32.1162 86.3856 33.5445 84.6291 33.5445Z"
        fill="white"
      />
      <path
        d="M71.5988 33.5444C69.8867 33.5444 68.4585 32.1605 68.4585 30.3953C68.4585 28.6832 69.8425 27.2461 71.5988 27.2461C73.311 27.2461 74.7392 28.63 74.7392 30.3953C74.6416 32.1163 73.3021 33.5444 71.5988 33.5444Z"
        fill="white"
      />
      <path
        d="M64.0294 21H80.9706C83.6177 21 86 23.1176 86 26.0294V42.9706C86 45.6177 83.8824 48 80.9706 48H64.0294C61.3824 48 59 45.8824 59 42.9706V25.7647C59 23.1176 61.3824 21 64.0294 21Z"
        fill="#57C3FF"
      />
      <defs>
        <linearGradient
          id="paint0_linear_816_494880"
          x1="53.625"
          y1="137.762"
          x2="81.9648"
          y2="165.57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#638AD2" />
          <stop offset="0.828087" stopColor="#214ecb" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_816_494880"
          x1="124.438"
          y1="53.2812"
          x2="124.438"
          y2="127.187"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EAF1FA" />
          <stop offset="1" stopColor="#CFDEEF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_816_494880"
          x1="173.156"
          y1="64.673"
          x2="190.783"
          y2="87.3505"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#638AD2" />
          <stop offset="0.828087" stopColor="#214ecb" />
        </linearGradient>
      </defs>
    </svg>
  );
};
