import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const SmallArrowLeftOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0038 7.78882C14.1838 7.78882 14.3538 7.85882 14.4838 7.99882C14.7238 8.26882 14.7038 8.67882 14.4438 8.91882L10.9506 12.0651L14.4399 15.0829C14.7099 15.3229 14.7199 15.7329 14.4799 16.0029C14.2399 16.2629 13.8299 16.2829 13.5599 16.0429L9.58064 12.5851C9.27064 12.3051 9.27064 11.8251 9.58064 11.5451L13.5638 7.95882C13.6938 7.83882 13.8438 7.78882 14.0038 7.78882Z"
        fill={color}
      />
    </svg>
  );
};

const SmallArrowLeftSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM13.6433 15.1778C13.5133 15.3178 13.3433 15.3878 13.1633 15.3878C13.0033 15.3878 12.8533 15.3378 12.7233 15.2178L9.73354 12.53C9.42354 12.25 9.42354 11.77 9.73354 11.49L12.7191 8.89368C12.9891 8.65368 13.3991 8.67368 13.6391 8.93368C13.8791 9.20368 13.8691 9.61368 13.5991 9.85368L11.1035 12.01L13.6033 14.2578C13.8633 14.4978 13.8833 14.9078 13.6433 15.1778Z"
        fill={color}
      />
    </svg>
  );
};

export const SmallArrowLeftIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={SmallArrowLeftOutlined}
      solidSvg={SmallArrowLeftSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
