import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const PerfomaceIconOutlined = ({ color = '#626F84', size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.40163 4.07405C6.30357 4.63362 4.65131 6.26817 4.08567 8.34374C3.42162 7.78799 3 6.95755 3 6.02969C3 4.35644 4.37113 3 6.0625 3C7.00042 3 7.83986 3.41711 8.40163 4.07405ZM14.375 9.92498C14.375 12.3153 12.4162 14.2531 10 14.2531C7.58375 14.2531 5.625 12.3153 5.625 9.92498C5.625 7.53463 7.58375 5.59686 10 5.59686C12.4162 5.59686 14.375 7.53463 14.375 9.92498ZM15.9143 11.6563C15.3487 13.7318 13.6964 15.3664 11.5984 15.9259C12.1601 16.5829 12.9996 17 13.9375 17C15.6289 17 17 15.6436 17 13.9703C17 13.0425 16.5784 12.212 15.9143 11.6563ZM15.9143 8.34374C15.3487 6.26817 13.6964 4.63362 11.5984 4.07405C12.1601 3.41711 12.9996 3 13.9375 3C15.6289 3 17 4.35644 17 6.02969C17 6.95755 16.5784 7.78799 15.9143 8.34374ZM8.40155 15.926C6.3035 15.3664 4.65125 13.7319 4.08564 11.6563C3.42161 12.212 3 13.0425 3 13.9703C3 15.6436 4.37113 17 6.0625 17C7.00037 17 7.83978 16.5829 8.40155 15.926Z"
        fill={color}
      />
    </svg>
  );
};
export const PerfomaceIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={PerfomaceIconOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
