import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const SmallArrowDownOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.78858 9.99632C7.78858 9.81632 7.85858 9.64632 7.99858 9.51632C8.26858 9.27632 8.67858 9.29632 8.91858 9.55632L12.0648 13.0495L15.0826 9.56022C15.3226 9.29022 15.7326 9.28022 16.0026 9.52022C16.2626 9.76022 16.2826 10.1702 16.0426 10.4402L12.5848 14.4195C12.3048 14.7295 11.8248 14.7295 11.5448 14.4195L7.95858 10.4363C7.83858 10.3063 7.78858 10.1563 7.78858 9.99632Z"
        fill={color}
      />
    </svg>
  );
};

const SmallArrowDownSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3ZM8.82219 13.6433C8.68219 13.5133 8.61219 13.3433 8.61219 13.1633C8.61219 13.0033 8.66219 12.8533 8.78219 12.7233L11.47 9.73354C11.75 9.42354 12.23 9.42354 12.51 9.73354L15.1063 12.7191C15.3463 12.9891 15.3263 13.3991 15.0663 13.6391C14.7963 13.8791 14.3863 13.8691 14.1463 13.5991L11.99 11.1035L9.74219 13.6033C9.50219 13.8633 9.09219 13.8833 8.82219 13.6433Z"
        fill={color}
      />
    </svg>
  );
};

export const SmallArrowDownIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={SmallArrowDownOutlined}
      solidSvg={SmallArrowDownSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
