import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const CloseCircleOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 9.00003C16.2538 8.74619 16.2539 8.33463 16 8.08079C15.7462 7.82695 15.3346 7.82695 15.0808 8.08079L12 11.0808L9 8.08079C8.74616 7.82695 8.3346 7.82695 8.08076 8.08079C7.82692 8.33463 7.82692 8.74619 8.08076 9.00003L11.0808 12L8.08079 15.0808C7.82695 15.3346 7.82695 15.7462 8.08079 16C8.33463 16.2539 8.74619 16.2539 9.00003 16L12 12.9193L15 16C15.2539 16.2539 15.6654 16.2539 15.9193 16C16.1731 15.7462 16.1731 15.3346 15.9193 15.0808L12.9193 12L16 9.00003Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 4.3C7.74741 4.3 4.3 7.74741 4.3 12C4.3 16.2526 7.74741 19.7 12 19.7C16.2526 19.7 19.7 16.2526 19.7 12C19.7 7.74741 16.2526 4.3 12 4.3Z"
        fill={color}
      />
    </svg>
  );
};

const CloseCircleSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM16 8.08078C16.2538 8.33462 16.2538 8.74618 16 9.00002L12.9193 12L15.9192 15.0808C16.1731 15.3346 16.1731 15.7462 15.9192 16C15.6654 16.2538 15.2538 16.2538 15 16L12 12.9193L9.00002 16C8.74618 16.2539 8.33462 16.2539 8.08078 16C7.82694 15.7462 7.82694 15.3346 8.08078 15.0808L11.0808 12L8.08075 9.00002C7.82691 8.74618 7.82691 8.33462 8.08075 8.08078C8.33459 7.82694 8.74615 7.82694 8.99999 8.08078L12 11.0808L15.0808 8.08078C15.3346 7.82694 15.7462 7.82694 16 8.08078Z"
        fill={color}
      />
    </svg>
  );
};

export const CloseCircleIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={CloseCircleOutlined}
      solidSvg={CloseCircleSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
