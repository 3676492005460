import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const PlayOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5846 8.50771C10.7253 7.88279 9.51996 8.49659 9.51996 9.55907V14.441C9.51996 15.5034 10.7253 16.1172 11.5846 15.4923L14.9409 13.0514C15.6547 12.5322 15.6547 11.4678 14.9409 10.9487L11.5846 8.50771ZM14.1763 12L10.82 14.441V9.55907L14.1763 12Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM19.7 12C19.7 16.2526 16.2526 19.7 12 19.7C7.74741 19.7 4.3 16.2526 4.3 12C4.3 7.74741 7.74741 4.3 12 4.3C16.2526 4.3 19.7 7.74741 19.7 12Z"
        fill={color}
      />
    </svg>
  );
};

const PlaySolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM9.51996 9.55907C9.51996 8.49659 10.7253 7.88279 11.5846 8.50771L14.9409 10.9487C15.6547 11.4678 15.6547 12.5322 14.9409 13.0514L11.5846 15.4923C10.7253 16.1172 9.51996 15.5034 9.51996 14.441V9.55907Z"
        fill={color}
      />
    </svg>
  );
};

export const PlayIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={PlayOutlined}
      solidSvg={PlaySolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
