import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const BranchesOutline = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.64639 8.93019C7.99256 8.63458 9 7.43494 9 6C9 4.34315 7.65685 3 6 3C4.34315 3 3 4.34315 3 6C3 7.43501 4.00754 8.63469 5.3538 8.93023C5.35135 8.95315 5.3501 8.97643 5.3501 9.00001L5.3501 15.0706C4.00569 15.3675 3 16.5663 3 18C3 19.6569 4.34315 21 6 21C7.42611 21 8.61981 20.0049 8.92463 18.6712H14.9999C17.0157 18.6712 18.6499 17.037 18.6499 15.0212V14.9294C19.9943 14.6325 21 13.4337 21 12C21 10.3431 19.6569 9 18 9C16.3431 9 15 10.3431 15 12C15 13.4336 16.0056 14.6324 17.3499 14.9294V15.0212C17.3499 16.3191 16.2978 17.3712 14.9999 17.3712H8.93399C8.68977 16.2261 7.79216 15.3229 6.6501 15.0706L6.6501 9.00001C6.6501 8.97642 6.64884 8.95313 6.64639 8.93019ZM7.7 6C7.7 6.93888 6.93888 7.7 6 7.7C5.06112 7.7 4.3 6.93888 4.3 6C4.3 5.06112 5.06112 4.3 6 4.3C6.93888 4.3 7.7 5.06112 7.7 6ZM18 13.7C18.9389 13.7 19.7 12.9389 19.7 12C19.7 11.0611 18.9389 10.3 18 10.3C17.0611 10.3 16.3 11.0611 16.3 12C16.3 12.9389 17.0611 13.7 18 13.7ZM7.7 18C7.7 18.9389 6.93888 19.7 6 19.7C5.06112 19.7 4.3 18.9389 4.3 18C4.3 17.0611 5.06112 16.3 6 16.3C6.93888 16.3 7.7 17.0611 7.7 18Z"
        fill={color}
      />
    </svg>
  );
};

const BranchesSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 6C9 7.43494 7.99256 8.63458 6.64639 8.93019C6.64884 8.95313 6.6501 8.97642 6.6501 9.00001L6.6501 15.0706C7.79216 15.3229 8.68977 16.2261 8.93399 17.3712H14.9999C16.2978 17.3712 17.3499 16.3191 17.3499 15.0212V14.9294C16.0056 14.6324 15 13.4336 15 12C15 10.3431 16.3431 9 18 9C19.6569 9 21 10.3431 21 12C21 13.4337 19.9943 14.6325 18.6499 14.9294V15.0212C18.6499 17.037 17.0157 18.6712 14.9999 18.6712H8.92463C8.61981 20.0049 7.42611 21 6 21C4.34315 21 3 19.6569 3 18C3 16.5663 4.00569 15.3675 5.3501 15.0706L5.3501 9.00001C5.3501 8.97643 5.35135 8.95315 5.3538 8.93023C4.00754 8.63469 3 7.43501 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
        fill={color}
      />
    </svg>
  );
};

export const BranchesIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={BranchesOutline}
      solidSvg={BranchesSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
