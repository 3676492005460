import clsx from 'clsx';
import React from 'react';
import './text.scss';
import { getColorClass } from '../../../styles';
import { TextProps } from './types';

export const Text = function Text({ color, size, weight, increaseLineHeight, children, props }: TextProps) {
  const Element: React.ElementType = 'span';

  return (
    <Element
      {...props}
      className={clsx('text', color && getColorClass(color), props?.className, {
        [`text_weight_${weight}`]: typeof weight === 'string',
        [`text_size_${size}`]: typeof size === 'string',
        text_height_increased: increaseLineHeight,
      })}
    >
      {children}
    </Element>
  );
};
