import React from 'react';
import { BasicIcon } from '../_utils/type';
import avatarWorkspaceSelect from '../assets/icons/avatar-workspace-icons-select.svg';

export const AvatarWorkspaceIconSelect = ({ className, size = '20px', onClick }: BasicIcon) => (
  <img
    onClick={onClick}
    className={className}
    style={{
      width: size,
      height: size,
    }}
    src={avatarWorkspaceSelect}
    alt=""
  />
);
