import { SvgIcon } from '@mui/material';
import React from 'react';
import { BasicIcon } from '../_utils/type';

export const ArrowRight = ({ className }: BasicIcon) => (
  <SvgIcon className={className} viewBox="0 0 24 24">
    <path
      d="M4 12.9231C3.44771 12.9231 3 12.4754 3 11.9231C3 11.3708 3.44771 10.9231 4 10.9231V12.9231ZM20 11.9231L20.687 11.1964C20.8868 11.3853 21 11.6481 21 11.9231C21 12.198 20.8868 12.4608 20.687 12.6497L20 11.9231ZM14.1059 7.72665C13.7046 7.34722 13.6868 6.7143 14.0663 6.31299C14.4457 5.91167 15.0786 5.89393 15.4799 6.27335L14.1059 7.72665ZM15.4799 17.5728C15.0786 17.9522 14.4457 17.9345 14.0663 17.5332C13.6868 17.1318 13.7046 16.4989 14.1059 16.1195L15.4799 17.5728ZM4 10.9231H20V12.9231H4V10.9231ZM19.313 12.6497L14.1059 7.72665L15.4799 6.27335L20.687 11.1964L19.313 12.6497ZM20.687 12.6497L15.4799 17.5728L14.1059 16.1195L19.313 11.1964L20.687 12.6497Z"
      fill="#214ecb"
    />
  </SvgIcon>
);
