import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const PersonIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.56456 7.96802C7.26365 5.32002 9.33497 3 12 3C14.6651 3 16.7364 5.32002 16.4355 7.96803L16.1734 10.274C15.9322 12.3966 14.1363 14 12 14C9.86377 14 8.06781 12.3966 7.8266 10.274L7.56456 7.96802ZM15.1438 7.82124L14.8817 10.1272C14.7152 11.5928 13.4751 12.7 12 12.7C10.5249 12.7 9.28484 11.5928 9.11829 10.1272L8.85625 7.82124C8.64297 5.94439 10.1111 4.3 12 4.3C13.889 4.3 15.3571 5.94439 15.1438 7.82124Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.28926 19.6984C4.89931 16.9532 7.33419 15 10.1464 15H13.8537C16.6658 15 19.1007 16.9532 19.7108 19.6984L19.8107 20.1481C19.9079 20.5853 19.5752 21 19.1274 21H4.87265C4.42482 21 4.09217 20.5853 4.18932 20.1481L4.28926 19.6984ZM13.8537 16.3C15.9595 16.3 17.7953 17.6982 18.3704 19.7H5.62966C6.20474 17.6982 8.04051 16.3 10.1464 16.3H13.8537Z"
        fill={color}
      />
    </svg>
  );
};

const PersonIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.56456 7.96802C7.26365 5.32002 9.33497 3 12 3C14.6651 3 16.7364 5.32002 16.4355 7.96803L16.1734 10.274C15.9322 12.3966 14.1363 14 12 14C9.86377 14 8.06781 12.3966 7.8266 10.274L7.56456 7.96802Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.28926 19.6984C4.89931 16.9532 7.33419 15 10.1464 15H13.8537C16.6658 15 19.1007 16.9532 19.7108 19.6984L19.8107 20.1481C19.9079 20.5853 19.5752 21 19.1274 21H4.87265C4.42482 21 4.09217 20.5853 4.18932 20.1481L4.28926 19.6984Z"
        fill={color}
      />
    </svg>
  );
};

export const PersonIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={PersonIconOutlined}
      solidSvg={PersonIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
