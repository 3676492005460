import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const LightningOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7702 3.3088C13.5743 3.27541 13.3743 3.33836 13.2329 3.47793L4.76098 11.838C4.38981 12.2043 4.57938 12.8366 5.09084 12.9382L8.93232 13.7017C9.9792 13.9097 10.6643 14.9208 10.4695 15.9702L9.72691 19.97C9.66404 20.3087 9.89052 20.6333 10.2301 20.6912C10.426 20.7246 10.626 20.6617 10.7674 20.5221L19.2393 12.162C19.6105 11.7957 19.4209 11.1634 18.9095 11.0618L15.068 10.2983C14.0211 10.0903 13.336 9.07925 13.5308 8.02982L14.2734 4.02997C14.3363 3.6913 14.1098 3.36669 13.7702 3.3088ZM12.3198 2.55261C12.7591 2.11909 13.3803 1.92357 13.9887 2.02729C15.0434 2.20709 15.7468 3.21532 15.5515 4.26726L14.809 8.26711C14.7441 8.61691 14.9724 8.95393 15.3214 9.02328L19.1629 9.78671C20.6973 10.0916 21.266 11.9885 20.1524 13.0873L11.6805 21.4474C11.2412 21.8809 10.62 22.0764 10.0116 21.9727C8.95692 21.7929 8.25346 20.7847 8.44875 19.7328L9.1913 15.7329C9.25624 15.3831 9.02788 15.0461 8.67892 14.9767L4.83744 14.2133C3.30304 13.9084 2.73435 12.0116 3.84787 10.9127L12.3198 2.55261Z"
        fill={color}
      />
    </svg>
  );
};

const LightningSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9887 2.02729C13.3803 1.92357 12.7591 2.11909 12.3198 2.55261L3.84787 10.9127C2.73435 12.0116 3.30304 13.9084 4.83744 14.2133L8.67892 14.9767C9.02788 15.0461 9.25624 15.3831 9.1913 15.7329L8.44875 19.7328C8.25346 20.7847 8.95692 21.7929 10.0116 21.9727C10.62 22.0764 11.2412 21.8809 11.6805 21.4474L20.1524 13.0873C21.266 11.9885 20.6973 10.0916 19.1629 9.78671L15.3214 9.02328C14.9724 8.95393 14.7441 8.61692 14.809 8.26711L15.5515 4.26726C15.7468 3.21532 15.0434 2.20709 13.9887 2.02729Z"
        fill={color}
      />
    </svg>
  );
};

export const LightningIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={LightningOutlined}
      solidSvg={LightningSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
