import { SvgIcon } from '@mui/material';
import React from 'react';
import { BasicIcon } from '../_utils/type';

export const RefreshIcon = ({ className }: BasicIcon) => (
  <SvgIcon className={className} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.05141 6.96933C10.5684 5.51617 13.7005 6.09387 15.5504 8.19399L13.6859 8.05356C13.1352 8.01208 12.6551 8.4249 12.6136 8.97563C12.5722 9.52635 12.985 10.0064 13.5357 10.0479L17.7664 10.3666C18.3171 10.408 18.7972 9.99522 18.8387 9.4445L19.1573 5.21384C19.1988 4.66311 18.786 4.18304 18.2352 4.14156C17.6845 4.10008 17.2044 4.5129 17.163 5.06362L17.0287 6.84596C14.5595 4.06557 10.3976 3.30533 7.05141 5.23728C4.44288 6.74331 3.00337 9.49655 3.05123 12.3089L5.05104 12.2581C5.02048 10.1541 6.09963 8.09619 8.05141 6.96933ZM14.6183 17.0039C12.1013 18.4571 8.96914 17.8794 7.11922 15.7793L8.98374 15.9197C9.53446 15.9612 10.0145 15.5484 10.056 14.9976C10.0975 14.4469 9.68468 13.9668 9.13395 13.9254L4.9033 13.6067C4.35257 13.5652 3.87249 13.978 3.83101 14.5288L3.51236 18.7594C3.47088 19.3101 3.8837 19.7902 4.43443 19.8317C4.98515 19.8732 5.46523 19.4604 5.50671 18.9096L5.64096 17.1273C8.11021 19.9077 12.272 20.6679 15.6183 18.736C18.2268 17.2299 19.6663 14.4767 19.6184 11.6644L17.6186 11.7152C17.6492 13.8192 16.57 15.8771 14.6183 17.0039Z"
      fill="#3961AC"
    />
  </SvgIcon>
);
