import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const CalendarOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.88002 12.65C8.13104 12.65 7.53002 13.251 7.53002 14V15.32C7.53002 16.0689 8.13104 16.67 8.88002 16.67H11.2C11.949 16.67 12.55 16.0689 12.55 15.32V14C12.55 13.251 11.949 12.65 11.2 12.65H8.88002ZM8.83002 14C8.83002 13.9812 8.83633 13.9704 8.84339 13.9633C8.85046 13.9563 8.86122 13.95 8.88002 13.95H11.2C11.2188 13.95 11.2296 13.9563 11.2367 13.9633C11.2437 13.9704 11.25 13.9812 11.25 14V15.32C11.25 15.3388 11.2437 15.3495 11.2367 15.3566C11.2296 15.3637 11.2188 15.37 11.2 15.37H8.88002C8.86122 15.37 8.85046 15.3637 8.84339 15.3566C8.83633 15.3495 8.83002 15.3388 8.83002 15.32V14Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.78998 2.59995C8.78998 2.24097 8.49896 1.94995 8.13998 1.94995C7.78099 1.94995 7.48998 2.24097 7.48998 2.59995V3.98996H7C5.34 3.98996 4 5.32996 4 6.98996V17.12C4 18.78 5.34 20.12 7 20.12H17C18.66 20.12 20 18.78 20 17.12V6.98996C20 5.32996 18.65 3.98996 17 3.98996H16.5V2.59995C16.5 2.24097 16.209 1.94995 15.85 1.94995C15.491 1.94995 15.2 2.24097 15.2 2.59995V3.98996H12.65V2.59995C12.65 2.24097 12.359 1.94995 12 1.94995C11.641 1.94995 11.35 2.24097 11.35 2.59995V3.98996H8.78998V2.59995ZM7.48998 5.28996V6.85995C7.48998 7.21894 7.78099 7.50995 8.13998 7.50995C8.49896 7.50995 8.78998 7.21894 8.78998 6.85995V5.28996H11.35V6.85995C11.35 7.21894 11.641 7.50995 12 7.50995C12.359 7.50995 12.65 7.21894 12.65 6.85995V5.28996H15.2V6.85995C15.2 7.21894 15.491 7.50995 15.85 7.50995C16.209 7.50995 16.5 7.21894 16.5 6.85995V5.28996H17C17.94 5.28996 18.7 6.04996 18.7 6.98996V9.34997H5.3V6.98996C5.3 6.04996 6.06 5.28996 7 5.28996H7.48998ZM5.3 10.65H18.7V17.12C18.7 18.06 17.94 18.82 17 18.82H7C6.06 18.82 5.3 18.06 5.3 17.12V10.65Z"
        fill={color}
      />
    </svg>
  );
};

const CalendarSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.78998 2.59995C8.78998 2.24097 8.49897 1.94995 8.13998 1.94995C7.781 1.94995 7.48998 2.24097 7.48998 2.59995V3.98996H7C5.34 3.98996 4 5.32996 4 6.98996V8.64996C4 9.03996 4.31 9.34996 4.7 9.34996H19.3C19.69 9.34996 20 9.03996 20 8.64996V6.98996C20 5.32996 18.65 3.98996 17 3.98996H16.5V2.59995C16.5 2.24097 16.209 1.94995 15.85 1.94995C15.491 1.94995 15.2 2.24097 15.2 2.59995V3.98996H12.65V2.59995C12.65 2.24097 12.359 1.94995 12 1.94995C11.641 1.94995 11.35 2.24097 11.35 2.59995V3.98996H8.78998V2.59995Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.7 10.65H19.3C19.68 10.65 20 10.96 20 11.35V17.12C20 18.78 18.66 20.12 17 20.12H7C5.34 20.12 4 18.78 4 17.12V11.35C4 10.96 4.31 10.65 4.7 10.65ZM10.42 16.52C10.81 16.52 11.12 16.21 11.12 15.82V14.5C11.12 14.11 10.81 13.8 10.42 13.8H8.09999C7.70999 13.8 7.39999 14.11 7.39999 14.5V15.82C7.39999 16.21 7.70999 16.52 8.09999 16.52H10.42Z"
        fill={color}
      />
    </svg>
  );
};

export const CalendarIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={CalendarOutlined}
      solidSvg={CalendarSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
