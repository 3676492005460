import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const PlusCircleOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.65 7.65C12.65 7.29102 12.359 7 12 7C11.641 7 11.35 7.29102 11.35 7.65L11.35 11.35H7.65C7.29101 11.35 7 11.641 7 12C7 12.359 7.29101 12.65 7.65 12.65H11.35L11.35 16.35C11.35 16.709 11.641 17 12 17C12.359 17 12.65 16.709 12.65 16.35L12.65 12.65H16.35C16.709 12.65 17 12.359 17 12C17 11.641 16.709 11.35 16.35 11.35H12.65L12.65 7.65Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM4.3 12C4.3 7.74741 7.74741 4.3 12 4.3C16.2526 4.3 19.7 7.74741 19.7 12C19.7 16.2526 16.2526 19.7 12 19.7C7.74741 19.7 4.3 16.2526 4.3 12Z"
        fill={color}
      />
    </svg>
  );
};

const PlusCircleSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 7C12.359 7 12.65 7.29102 12.65 7.65L12.65 11.35H16.35C16.709 11.35 17 11.641 17 12C17 12.359 16.709 12.65 16.35 12.65H12.65L12.65 16.35C12.65 16.709 12.359 17 12 17C11.641 17 11.35 16.709 11.35 16.35L11.35 12.65H7.65C7.29101 12.65 7 12.359 7 12C7 11.641 7.29101 11.35 7.65 11.35H11.35L11.35 7.65C11.35 7.29102 11.641 7 12 7Z"
        fill={color}
      />
    </svg>
  );
};

export const PlusCircleIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={PlusCircleOutlined}
      solidSvg={PlusCircleSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
