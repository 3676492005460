import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const AlertOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5995 1.01629C10.5995 0.685173 10.3311 0.416748 10 0.416748C9.66888 0.416748 9.40046 0.685173 9.40046 1.01629V2.58433C9.40046 2.91545 9.66888 3.18387 10 3.18387C10.3311 3.18387 10.5995 2.91545 10.5995 2.58433V1.01629Z"
        fill={color}
      />
      <path
        d="M10.5417 10.0828C10.5417 10.382 10.2992 10.6245 10 10.6245C9.70087 10.6245 9.45835 10.382 9.45835 10.0828V7.53914C9.45835 7.23999 9.70087 6.99748 10 6.99748C10.2992 6.99748 10.5417 7.23999 10.5417 7.53914V10.0828Z"
        fill={color}
      />
      <path
        d="M10.625 12.3777C10.625 12.7228 10.3452 13.0027 10 13.0027C9.65484 13.0027 9.37502 12.7228 9.37502 12.3777C9.37502 12.0325 9.65484 11.7527 10 11.7527C10.3452 11.7527 10.625 12.0325 10.625 12.3777Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.16669 10.0001C4.16669 6.77842 6.77836 4.16675 10 4.16675C13.2217 4.16675 15.8334 6.77842 15.8334 10.0001V14.7501C15.8334 15.0722 15.5722 15.3334 15.25 15.3334H4.75002C4.42785 15.3334 4.16669 15.0722 4.16669 14.7501V10.0001ZM14.75 10.0001V14.2501H5.25002V10.0001C5.25002 7.37673 7.37667 5.25008 10 5.25008C12.6234 5.25008 14.75 7.37673 14.75 10.0001Z"
        fill={color}
      />
      <path
        d="M3.16269 16.4167C2.86353 16.4167 2.62102 16.6592 2.62102 16.9584C2.62102 17.2575 2.86353 17.5 3.16269 17.5H16.8374C17.1365 17.5 17.379 17.2575 17.379 16.9584C17.379 16.6592 17.1365 16.4167 16.8374 16.4167H3.16269Z"
        fill={color}
      />
      <path
        d="M0.415161 10.0015C0.415161 9.67036 0.683586 9.40194 1.0147 9.40194H2.58274C2.91386 9.40194 3.18229 9.67036 3.18229 10.0015C3.18229 10.3326 2.91386 10.601 2.58274 10.601H1.0147C0.683586 10.601 0.415161 10.3326 0.415161 10.0015Z"
        fill={color}
      />
      <path
        d="M17.4173 9.40194C17.0861 9.40194 16.8177 9.67036 16.8177 10.0015C16.8177 10.3326 17.0861 10.601 17.4173 10.601H18.9853C19.3164 10.601 19.5848 10.3326 19.5848 10.0015C19.5848 9.67036 19.3164 9.40194 18.9853 9.40194H17.4173Z"
        fill={color}
      />
      <path
        d="M3.2225 3.22401C3.45663 2.98987 3.83624 2.98987 4.07038 3.22401L5.17915 4.33278C5.41329 4.56692 5.41329 4.94653 5.17915 5.18066C4.94501 5.4148 4.5654 5.4148 4.33127 5.18066L3.2225 4.07189C2.98836 3.83776 2.98836 3.45815 3.2225 3.22401Z"
        fill={color}
      />
      <path
        d="M14.8209 4.33281C14.5867 4.56695 14.5867 4.94656 14.8209 5.1807C15.055 5.41483 15.4346 5.41483 15.6687 5.1807L16.7775 4.07193C17.0116 3.83779 17.0116 3.45818 16.7775 3.22404C16.5434 2.98991 16.1638 2.98991 15.9296 3.22404L14.8209 4.33281Z"
        fill={color}
      />
    </svg>
  );
};

const AlertSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0.416748C10.3311 0.416748 10.5995 0.685173 10.5995 1.01629V2.58433C10.5995 2.91545 10.3311 3.18387 10 3.18387C9.66888 3.18387 9.40046 2.91545 9.40046 2.58433V1.01629C9.40046 0.685173 9.66888 0.416748 10 0.416748Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 4.16675C6.77836 4.16675 4.16669 6.77842 4.16669 10.0001V14.7501C4.16669 15.0722 4.42785 15.3334 4.75002 15.3334H15.25C15.5722 15.3334 15.8334 15.0722 15.8334 14.7501V10.0001C15.8334 6.77842 13.2217 4.16675 10 4.16675ZM10 10.6245C10.2992 10.6245 10.5417 10.382 10.5417 10.0828V7.53914C10.5417 7.23999 10.2992 6.99748 10 6.99748C9.70087 6.99748 9.45835 7.23999 9.45835 7.53914V10.0828C9.45835 10.382 9.70087 10.6245 10 10.6245ZM10 13.0027C10.3452 13.0027 10.625 12.7228 10.625 12.3777C10.625 12.0325 10.3452 11.7527 10 11.7527C9.65484 11.7527 9.37502 12.0325 9.37502 12.3777C9.37502 12.7228 9.65484 13.0027 10 13.0027Z"
        fill={color}
      />
      <path
        d="M0.415161 10.0015C0.415161 9.67036 0.683586 9.40194 1.0147 9.40194H2.58274C2.91386 9.40194 3.18229 9.67036 3.18229 10.0015C3.18229 10.3326 2.91386 10.601 2.58274 10.601H1.0147C0.683586 10.601 0.415161 10.3326 0.415161 10.0015Z"
        fill={color}
      />
      <path
        d="M16.8177 10.0015C16.8177 9.67036 17.0861 9.40194 17.4173 9.40194H18.9853C19.3164 9.40194 19.5848 9.67036 19.5848 10.0015C19.5848 10.3326 19.3164 10.601 18.9853 10.601H17.4173C17.0861 10.601 16.8177 10.3326 16.8177 10.0015Z"
        fill={color}
      />
      <path
        d="M3.2225 3.22401C3.45663 2.98987 3.83624 2.98987 4.07038 3.22401L5.17915 4.33278C5.41329 4.56692 5.41329 4.94653 5.17915 5.18066C4.94501 5.4148 4.5654 5.4148 4.33127 5.18066L3.2225 4.07189C2.98836 3.83776 2.98836 3.45815 3.2225 3.22401Z"
        fill={color}
      />
      <path
        d="M14.8209 5.1807C14.5867 4.94656 14.5867 4.56695 14.8209 4.33281L15.9296 3.22404C16.1638 2.98991 16.5434 2.98991 16.7775 3.22404C17.0116 3.45818 17.0116 3.83779 16.7775 4.07193L15.6687 5.1807C15.4346 5.41483 15.055 5.41483 14.8209 5.1807Z"
        fill={color}
      />
      <path
        d="M3.16269 16.4167C2.86353 16.4167 2.62102 16.6592 2.62102 16.9584C2.62102 17.2575 2.86353 17.5 3.16269 17.5H16.8374C17.1365 17.5 17.379 17.2575 17.379 16.9584C17.379 16.6592 17.1365 16.4167 16.8374 16.4167H3.16269Z"
        fill={color}
      />
    </svg>
  );
};

export const AlertIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={AlertOutlined}
      solidSvg={AlertSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
