import { createTheme } from '@mui/material/styles';

export const themeMui = createTheme({
  palette: {
    primary: {
      main: '#214ecb',
    },
    secondary: {
      main: '#214ecb',
    },
    action: {
      disabled: '#C4CAD4',
    },
  },
  typography: {
    fontFamily: [
      'Noto Sans',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '-apple-system',
      'BlinkMacSystemFont',
    ].join(','),
  },
  components: {
    MuiIconButton: {
      defaultProps: {
        disableFocusRipple: true,
      },
      styleOverrides: {
        root: {
          '&:hover': {
            background: 'transparent',
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          '&': {
            textTransform: 'none',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#F4F6FB;',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: '#214ECB',
          '&:checked': {
            color: '#214ECB',
          },
        },
        root: {
          '&.Mui-disabled': {
            color: '#E2E5E9',
            '&:checked': {
              color: '#C4CAD4',
            },
          },
        },
      },
    },
  },
});
