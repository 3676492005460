import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const PersonPropertiesOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.56456 7.96802C7.26365 5.32002 9.33497 3 12 3C14.6651 3 16.7364 5.32002 16.4355 7.96803L16.1734 10.274C15.9322 12.3966 14.1363 14 12 14C9.86377 14 8.06781 12.3966 7.8266 10.274L7.56456 7.96802ZM15.1438 7.82124L14.8817 10.1272C14.7152 11.5928 13.4751 12.7 12 12.7C10.5249 12.7 9.28484 11.5928 9.11829 10.1272L8.85625 7.82124C8.64297 5.94439 10.1111 4.3 12 4.3C13.889 4.3 15.3571 5.94439 15.1438 7.82124Z"
        fill={color}
      />
      <path
        d="M4.28926 19.6984C4.89931 16.9532 7.33419 15 10.1464 15H11.6571C12.0161 15 12.3071 15.291 12.3071 15.65C12.3071 16.009 12.0161 16.3 11.6571 16.3H10.1464C8.04051 16.3 6.20474 17.6982 5.62966 19.7H11.6571C12.0161 19.7 12.3071 19.991 12.3071 20.35C12.3071 20.709 12.0161 21 11.6571 21H4.87265C4.42482 21 4.09217 20.5853 4.18932 20.1481L4.28926 19.6984Z"
        fill={color}
      />
      <path
        d="M17.5 19C18.3284 19 19 18.3284 19 17.5C19 16.6716 18.3284 16 17.5 16C16.6716 16 16 16.6716 16 17.5C16 18.3284 16.6716 19 17.5 19Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.964 13C16.4971 13 16.1017 13.3267 16.0375 13.7656C15.9579 14.3098 15.3598 14.6375 14.8233 14.4308C14.3907 14.2642 13.8949 14.4257 13.6614 14.8094L13.1571 15.6384C12.9265 16.0174 13.0215 16.4994 13.3809 16.7738C13.8456 17.1286 13.8379 17.8128 13.3608 18.1653C12.9924 18.4374 12.8919 18.9257 13.1254 19.3094L13.6297 20.1384C13.8603 20.5174 14.3477 20.6804 14.7777 20.5222C15.3338 20.3177 15.9544 20.6661 16.0375 21.2344C16.1017 21.6733 16.4971 22 16.964 22H17.9726C18.4338 22 18.8262 21.681 18.8969 21.2484C18.993 20.6603 19.6375 20.3071 20.2223 20.5222C20.6524 20.6804 21.1397 20.5174 21.3703 20.1384L21.8746 19.3094C22.1081 18.9257 22.0076 18.4374 21.6392 18.1653C21.1621 17.8128 21.1545 17.1286 21.6192 16.7738C21.9785 16.4994 22.0735 16.0174 21.8429 15.6384L21.3386 14.8094C21.1052 14.4257 20.6093 14.2642 20.1767 14.4308C19.6165 14.6467 18.9883 14.3109 18.8969 13.7516C18.8262 13.3191 18.4338 13 17.9726 13H16.964ZM17.4719 14.3C17.3366 14.3 17.219 14.3888 17.1646 14.5127C16.7575 15.4384 15.7412 15.9219 14.7793 15.7598C14.6864 15.7441 14.5911 15.7826 14.5421 15.863C14.492 15.9454 14.5035 16.0505 14.5634 16.1262C15.1921 16.9204 15.1717 18.0472 14.5368 18.8268C14.4757 18.9019 14.4634 19.0075 14.5137 19.0903C14.5619 19.1694 14.6553 19.2077 14.7468 19.1931C15.7331 19.0359 16.7621 19.5483 17.1685 20.4935C17.2203 20.6139 17.3342 20.7 17.4653 20.7H17.4779C17.6079 20.7 17.7211 20.6154 17.7733 20.4964C18.1959 19.5331 19.2504 19.0264 20.2536 19.192C20.3449 19.2071 20.4384 19.1689 20.4866 19.0898C20.5368 19.0073 20.5245 18.902 20.4635 18.8272C19.8282 18.0475 19.8078 16.9203 20.4369 16.1259C20.4966 16.0504 20.5081 15.9456 20.4581 15.8634C20.4091 15.7829 20.3138 15.7445 20.221 15.7606C19.245 15.9291 18.2013 15.4499 17.7777 14.5093C17.7227 14.3871 17.6059 14.3 17.4719 14.3Z"
        fill={color}
      />
    </svg>
  );
};

const PersonPropertiesSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.56456 7.96802C7.26365 5.32002 9.33497 3 12 3C14.6651 3 16.7364 5.32002 16.4355 7.96803L16.1734 10.274C15.9322 12.3966 14.1363 14 12 14C9.86377 14 8.06781 12.3966 7.8266 10.274L7.56456 7.96802Z"
        fill={color}
      />
      <path
        d="M11.1541 15C11.6692 15 12.0139 15.5434 11.8848 16.042C11.7642 16.5078 11.7 16.9964 11.7 17.5C11.7 18.3115 11.8667 19.0841 12.1677 19.7854C12.3958 20.317 12.0443 21 11.4658 21H4.87265C4.42482 21 4.09217 20.5853 4.18932 20.1481L4.28926 19.6984C4.89931 16.9532 7.33419 15 10.1464 15H11.1541Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0375 13.7656C16.1017 13.3267 16.4971 13 16.964 13H17.9726C18.4338 13 18.8262 13.3191 18.8969 13.7516C18.9883 14.3109 19.6165 14.6467 20.1767 14.4308C20.6093 14.2642 21.1052 14.4257 21.3386 14.8094L21.8429 15.6384C22.0735 16.0174 21.9785 16.4994 21.6192 16.7738C21.1545 17.1286 21.1621 17.8128 21.6392 18.1653C22.0076 18.4374 22.1081 18.9257 21.8746 19.3094L21.3703 20.1384C21.1397 20.5174 20.6524 20.6804 20.2223 20.5222C19.6375 20.3071 18.993 20.6603 18.8969 21.2484C18.8262 21.681 18.4338 22 17.9726 22H16.964C16.4971 22 16.1017 21.6733 16.0375 21.2344C15.9544 20.6661 15.3338 20.3177 14.7777 20.5222C14.3477 20.6804 13.8603 20.5174 13.6297 20.1384L13.1254 19.3094C12.8919 18.9257 12.9924 18.4374 13.3608 18.1653C13.8379 17.8128 13.8456 17.1286 13.3809 16.7738C13.0215 16.4994 12.9265 16.0174 13.1571 15.6384L13.6614 14.8094C13.8949 14.4257 14.3907 14.2642 14.8233 14.4308C15.3598 14.6375 15.9579 14.3098 16.0375 13.7656ZM17.5 19C18.3284 19 19 18.3284 19 17.5C19 16.6716 18.3284 16 17.5 16C16.6716 16 16 16.6716 16 17.5C16 18.3284 16.6716 19 17.5 19Z"
        fill={color}
      />
    </svg>
  );
};

export const PersonPropertiesnIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={PersonPropertiesOutlined}
      solidSvg={PersonPropertiesSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
