import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const EyeClosedOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.7002 10.0454C21.9992 10.244 22.0807 10.6474 21.8822 10.9465C21.3822 11.6996 20.7405 12.519 19.9686 13.3044L21.8043 15.1401C22.0581 15.3939 22.0581 15.8055 21.8043 16.0593C21.5505 16.3131 21.1389 16.3131 20.8851 16.0593L19.0181 14.1923C18.0828 14.992 17.0026 15.7077 15.7916 16.2153L16.826 18.7041C16.9637 19.0356 16.8067 19.416 16.4752 19.5538C16.1437 19.6916 15.7633 19.5345 15.6255 19.203L14.5613 16.6424C13.7552 16.8653 12.9002 16.9917 11.9999 16.9917C11.0836 16.9917 10.2286 16.8687 9.43355 16.6543L8.3743 19.203C8.23653 19.5345 7.85611 19.6916 7.52461 19.5538C7.19312 19.416 7.03607 19.0356 7.17385 18.7041L8.20071 16.2333C6.95666 15.7166 5.88486 14.9847 4.97946 14.1946L3.11475 16.0593C2.86091 16.3131 2.44935 16.3131 2.19551 16.0593C1.94167 15.8055 1.94167 15.3939 2.19551 15.1401L4.03833 13.2972C3.23183 12.4606 2.59978 11.6239 2.13578 10.9589C1.93036 10.6645 2.00248 10.2593 2.29688 10.0539C2.59128 9.84848 2.99647 9.92061 3.20189 10.215C4.66506 12.3119 7.54129 15.6917 11.9999 15.6917C16.0334 15.6917 19.149 12.7131 20.7991 10.2275C20.9977 9.92838 21.4011 9.84688 21.7002 10.0454Z"
        fill={color}
      />
    </svg>
  );
};

export const EyeClosedIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={EyeClosedOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
