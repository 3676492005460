import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const CnyJpyOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.08663 7.59968C8.36368 7.37139 8.77333 7.41091 9.00163 7.68795L12 11.3266L14.9984 7.68795C15.2267 7.41091 15.6363 7.37139 15.9134 7.59968C16.1904 7.82797 16.2299 8.23763 16.0016 8.51467L12.8412 12.35H14.3489C14.7079 12.35 14.9989 12.641 14.9989 13C14.9989 13.359 14.7079 13.65 14.3489 13.65H12.65V14.6771H14.3489C14.7079 14.6771 14.9989 14.9682 14.9989 15.3271C14.9989 15.6861 14.7079 15.9771 14.3489 15.9771H12.65V16.8813C12.65 17.2403 12.359 17.5313 12 17.5313C11.641 17.5313 11.35 17.2403 11.35 16.8813V15.9771H9.65111C9.29212 15.9771 9.00111 15.6861 9.00111 15.3271C9.00111 14.9682 9.29212 14.6771 9.65111 14.6771H11.35V13.65H9.65111C9.29212 13.65 9.00111 13.359 9.00111 13C9.00111 12.641 9.29212 12.35 9.65111 12.35H11.1588L7.99836 8.51467C7.77007 8.23763 7.80959 7.82797 8.08663 7.59968Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM4.3 12C4.3 7.74741 7.74741 4.3 12 4.3C16.2526 4.3 19.7 7.74741 19.7 12C19.7 16.2526 16.2526 19.7 12 19.7C7.74741 19.7 4.3 16.2526 4.3 12Z"
        fill={color}
      />
    </svg>
  );
};

const CnyJpySolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM8.08664 7.59968C8.36368 7.37139 8.77334 7.41091 9.00163 7.68795L12 11.3266L14.9984 7.68795C15.2267 7.41091 15.6363 7.37139 15.9134 7.59968C16.1904 7.82797 16.2299 8.23763 16.0016 8.51467L12.8412 12.35H14.3489C14.7079 12.35 14.9989 12.641 14.9989 13C14.9989 13.359 14.7079 13.65 14.3489 13.65H12.65V14.6771H14.3489C14.7079 14.6771 14.9989 14.9682 14.9989 15.3271C14.9989 15.6861 14.7079 15.9771 14.3489 15.9771H12.65V16.8813C12.65 17.2403 12.359 17.5313 12 17.5313C11.641 17.5313 11.35 17.2403 11.35 16.8813V15.9771H9.65112C9.29213 15.9771 9.00112 15.6861 9.00112 15.3271C9.00112 14.9682 9.29213 14.6771 9.65112 14.6771H11.35V13.65H9.65112C9.29213 13.65 9.00112 13.359 9.00112 13C9.00112 12.641 9.29213 12.35 9.65112 12.35H11.1588L7.99837 8.51467C7.77008 8.23763 7.8096 7.82797 8.08664 7.59968Z"
        fill={color}
      />
    </svg>
  );
};

// китайский юань и японская йена обозначаются одним символом
export const CnyJpyIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={CnyJpyOutlined}
      solidSvg={CnyJpySolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
