import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const CopyLinkOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.0105 3.75264C11.6865 2.07664 14.4039 2.07663 16.0799 3.75264C17.7559 5.42863 17.7559 8.14597 16.0799 9.82197L14.1294 11.7724C13.9179 11.9839 13.5749 11.9839 13.3634 11.7724C13.1519 11.5609 13.1519 11.2179 13.3634 11.0064L15.3138 9.05594C16.5668 7.803 16.5668 5.7716 15.3138 4.51867C14.0609 3.26574 12.0295 3.26574 10.7766 4.51867L8.82613 6.4691C8.6146 6.68063 8.27163 6.68063 8.0601 6.4691C7.84857 6.25756 7.84857 5.9146 8.0601 5.70306L10.0105 3.75264Z"
        fill={color}
      />
      <path
        d="M9.88551 16.0163C8.20951 17.6923 5.49217 17.6923 3.81617 16.0163C2.14017 14.3403 2.14017 11.623 3.81617 9.94699L5.70307 8.06009C5.9146 7.84856 6.25757 7.84856 6.4691 8.06009C6.68064 8.27162 6.68064 8.61459 6.4691 8.82612L4.58221 10.713C3.32927 11.966 3.32927 13.9974 4.58221 15.2503C5.83514 16.5032 7.86654 16.5032 9.11948 15.2503L11.0064 13.3634C11.2179 13.1519 11.5609 13.1519 11.7724 13.3634C11.9839 13.5749 11.9839 13.9179 11.7724 14.1294L9.88551 16.0163Z"
        fill={color}
      />
      <path
        d="M12.067 8.53149C12.2786 8.31996 12.2786 7.97699 12.067 7.76546C11.8555 7.55393 11.5125 7.55393 11.301 7.76546L7.76546 11.301C7.55393 11.5125 7.55393 11.8555 7.76546 12.067C7.97699 12.2786 8.31996 12.2786 8.53149 12.067L12.067 8.53149Z"
        fill={color}
      />
    </svg>
  );
};

export const CopyLinkIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={CopyLinkOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
