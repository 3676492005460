import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const GridOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5.3C4 4.58203 4.58203 4 5.3 4H9.7C10.418 4 11 4.58203 11 5.3V9.7C11 10.418 10.418 11 9.7 11H5.3C4.58203 11 4 10.418 4 9.7V5.3ZM5.3 5.3H9.7V9.7L5.3 9.7V5.3Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 14.3C4 13.582 4.58203 13 5.3 13H9.7C10.418 13 11 13.582 11 14.3V18.7C11 19.418 10.418 20 9.7 20H5.3C4.58203 20 4 19.418 4 18.7V14.3ZM5.3 14.3H9.7V18.7L5.3 18.7V14.3Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3 4C13.582 4 13 4.58203 13 5.3V9.7C13 10.418 13.582 11 14.3 11H18.7C19.418 11 20 10.418 20 9.7V5.3C20 4.58203 19.418 4 18.7 4H14.3ZM18.7 5.3H14.3V9.7L18.7 9.7V5.3Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 14.3C13 13.582 13.582 13 14.3 13H18.7C19.418 13 20 13.582 20 14.3V18.7C20 19.418 19.418 20 18.7 20H14.3C13.582 20 13 19.418 13 18.7V14.3ZM14.3 14.3H18.7V18.7L14.3 18.7V14.3Z"
        fill={color}
      />
    </svg>
  );
};

const GridSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.3 4C4.58203 4 4 4.58203 4 5.3V9.7C4 10.418 4.58203 11 5.3 11H9.7C10.418 11 11 10.418 11 9.7V5.3C11 4.58203 10.418 4 9.7 4H5.3Z"
        fill={color}
      />
      <path
        d="M5.3 13C4.58203 13 4 13.582 4 14.3V18.7C4 19.418 4.58203 20 5.3 20H9.7C10.418 20 11 19.418 11 18.7V14.3C11 13.582 10.418 13 9.7 13H5.3Z"
        fill={color}
      />
      <path
        d="M13 5.3C13 4.58203 13.582 4 14.3 4H18.7C19.418 4 20 4.58203 20 5.3V9.7C20 10.418 19.418 11 18.7 11H14.3C13.582 11 13 10.418 13 9.7V5.3Z"
        fill={color}
      />
      <path
        d="M14.3 13C13.582 13 13 13.582 13 14.3V18.7C13 19.418 13.582 20 14.3 20H18.7C19.418 20 20 19.418 20 18.7V14.3C20 13.582 19.418 13 18.7 13H14.3Z"
        fill={color}
      />
    </svg>
  );
};

export const GridIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={GridOutlined}
      solidSvg={GridSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
