import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const HomeOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9998 9.00008L20.9998 12.0001C21.2713 12.2349 21.3011 12.6454 21.0663 12.9169C20.8316 13.1885 20.4211 13.2183 20.1495 12.9835L18.9998 11.8337V19.7001C18.9998 20.4181 18.4178 21.0001 17.6998 21.0001H14.4992C13.7811 21.0001 13.1991 20.418 13.1992 19.6999L13.1998 14.3001H10.7998V19.7001C10.7998 20.4181 10.2178 21.0001 9.49979 21.0001L6.29979 21.0001C5.58182 21.0001 4.99979 20.4181 4.99979 19.7001V11.8337L3.85004 12.9835C3.57849 13.2183 3.16801 13.1885 2.93322 12.9169C2.69843 12.6454 2.72823 12.2349 2.99978 12.0001L5.99979 9.00008L11.0805 3.91933C11.5882 3.41165 12.4113 3.41165 12.919 3.91934L17.9998 9.00008ZM17.6998 19.7001H14.4998V14.3001C14.4998 13.5821 13.9178 13.0001 13.1998 13.0001H10.7998C10.0818 13.0001 9.49979 13.5821 9.49979 14.3001V19.7001H6.29979V10.5386L11.9998 4.83857L17.6998 10.5386V19.7001Z"
        fill={color}
      />
    </svg>
  );
};

const HomeSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.0805 3.91933C11.5882 3.41165 12.4113 3.41165 12.919 3.91934L20.9998 12.0001C21.2713 12.2349 21.3011 12.6454 21.0663 12.9169C20.8316 13.1885 20.4211 13.2183 20.1495 12.9835L18.9998 11.8337V19.7001C18.9998 20.4181 18.4178 21.0001 17.6998 21.0001H14.6998C14.3132 21.0001 13.9998 20.6867 13.9998 20.3001V15.3001C13.9998 14.5821 13.4178 14.0001 12.6998 14.0001H11.2998C10.5818 14.0001 9.99979 14.5821 9.99979 15.3001V20.3001C9.99979 20.6867 9.68639 21.0001 9.29979 21.0001H6.29979C5.58182 21.0001 4.99979 20.4181 4.99979 19.7001V11.8337L3.85004 12.9835C3.57849 13.2183 3.16801 13.1885 2.93322 12.9169C2.69843 12.6454 2.72823 12.2349 2.99978 12.0001L5.99979 9.00009L11.0805 3.91933Z"
        fill={color}
      />
    </svg>
  );
};

export const HomeIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={HomeOutlined}
      solidSvg={HomeSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
