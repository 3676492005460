import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const MoreOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5 10C6.10457 10 7 10.8954 7 12C7 13.1046 6.10457 14 5 14C3.89543 14 3 13.1046 3 12C3 10.8954 3.89543 10 5 10Z"
        fill={color}
      />
      <path
        d="M19 10C20.1046 10 21 10.8954 21 12C21 13.1046 20.1046 14 19 14C17.8954 14 17 13.1046 17 12C17 10.8954 17.8954 10 19 10Z"
        fill={color}
      />
      <path
        d="M14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14C13.1046 14 14 13.1046 14 12Z"
        fill={color}
      />
    </svg>
  );
};

const MoreSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM6.3 10.7C7.01797 10.7 7.6 11.282 7.6 12C7.6 12.7179 7.01797 13.3 6.3 13.3C5.58203 13.3 5 12.7179 5 12C5 11.282 5.58203 10.7 6.3 10.7ZM17.3 10.7C18.018 10.7 18.6 11.282 18.6 12C18.6 12.7179 18.018 13.3 17.3 13.3C16.582 13.3 16 12.7179 16 12C16 11.282 16.582 10.7 17.3 10.7ZM12 10.7C12.718 10.7 13.3 11.282 13.3 12C13.3 12.718 12.718 13.3 12 13.3C11.282 13.3 10.7 12.718 10.7 12C10.7 11.282 11.282 10.7 12 10.7Z"
        fill={color}
      />
    </svg>
  );
};

export const MoreIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={MoreOutlined}
      solidSvg={MoreSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
