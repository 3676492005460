import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const CloseIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.40942 5.49019C6.15558 5.23634 5.74403 5.23634 5.49019 5.49019C5.23634 5.74403 5.23634 6.15558 5.49019 6.40942L11.0808 12L5.59065 17.4902C5.33681 17.744 5.33681 18.1556 5.59065 18.4094C5.84449 18.6633 6.25605 18.6633 6.50989 18.4094L12 12.9193L17.4902 18.4094C17.744 18.6633 18.1556 18.6633 18.4094 18.4094C18.6633 18.1556 18.6633 17.744 18.4094 17.4902L12.9193 12L18.5099 6.40942C18.7637 6.15558 18.7637 5.74403 18.5099 5.49019C18.256 5.23634 17.8445 5.23634 17.5906 5.49019L12 11.0808L6.40942 5.49019Z"
        fill={color}
      />
    </svg>
  );
};

const CloseIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM16 8.08078C16.2538 8.33462 16.2538 8.74618 16 9.00002L12.9193 12L15.9192 15.0808C16.1731 15.3346 16.1731 15.7462 15.9192 16C15.6654 16.2538 15.2538 16.2538 15 16L12 12.9193L9.00002 16C8.74618 16.2539 8.33462 16.2539 8.08078 16C7.82694 15.7462 7.82694 15.3346 8.08078 15.0808L11.0808 12L8.08075 9.00002C7.82691 8.74618 7.82691 8.33462 8.08075 8.08078C8.33459 7.82694 8.74615 7.82694 8.99999 8.08078L12 11.0808L15.0808 8.08078C15.3346 7.82694 15.7462 7.82694 16 8.08078Z"
        fill={color}
      />
    </svg>
  );
};

export const CloseIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={CloseIconOutlined}
      solidSvg={CloseIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
