import React from 'react';
import { BasicIcon } from '../../_utils/type';

export const MailRuIcon = ({ className }: BasicIcon) => (
  <svg className={className} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.1219 12.0293C23.1219 16.1561 21.2631 18.0878 18.9256 18.0878C17.5738 18.0878 16.3909 17.3561 15.715 16.2439C14.6448 17.3561 13.2085 18.0585 11.575 18.0585C8.36444 18.0585 5.77344 15.3659 5.77344 12.0293C5.77344 8.69268 8.36444 6 11.575 6C14.7856 6 17.3766 8.69268 17.3766 12.0293V13.9902C17.3766 14.9854 18.0525 15.6878 18.9256 15.6878C19.9676 15.6878 20.8125 14.7805 20.8125 12.0293C20.8125 6.73171 16.6726 2.42927 11.575 2.42927C6.47751 2.42927 2.30938 6.73171 2.30938 12.0293C2.30938 17.3268 6.44935 21.6293 11.5469 21.6293C14.504 21.6293 16.5317 20.1073 16.5317 20.1073L18.0525 21.9219C18.0525 21.9219 15.4615 24 11.5469 24C5.18201 24 0 18.6146 0 12C0 5.38537 5.18201 0 11.5469 0C17.9399 0.0292683 23.1219 5.41463 23.1219 12.0293ZM11.575 15.6878C13.5183 15.6878 15.0954 14.0488 15.0954 12.0293C15.0954 10.0098 13.5183 8.37073 11.575 8.37073C9.63178 8.37073 8.05465 10.0098 8.05465 12.0293C8.05465 14.0488 9.63178 15.6878 11.575 15.6878Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="4.36528"
        y1="1.46341"
        x2="18.0459"
        y2="22.3541"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFBC02" />
        <stop offset="1" stopColor="#FF8A40" />
      </linearGradient>
    </defs>
  </svg>
);
