import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const GalleryIconOutlined = ({ color = '#626F84', size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.99567 3V7.24819L3 10.0344L4.99567 12.3562V16.9999H8.90201C9.24011 16.9999 9.51419 16.7258 9.51419 16.3876C9.51419 16.0493 9.24011 15.7752 8.90201 15.7752H6.22002V11.9021L4.55551 9.96554L6.22002 7.64168V4.22477H8.90201C9.24011 4.22477 9.51419 3.95059 9.51419 3.61238C9.51419 3.27417 9.24011 3 8.90201 3H4.99567ZM15.0043 17L15.0043 12.7518L17 9.9656L15.0043 7.64376L15.0043 3.00006L11.098 3.00006C10.7599 3.00006 10.4858 3.27423 10.4858 3.61244C10.4858 3.95065 10.7599 4.22483 11.098 4.22483L13.78 4.22483V8.09791L15.4445 10.0345L13.78 12.3583V15.7752H11.098C10.7599 15.7752 10.4858 16.0494 10.4858 16.3876C10.4858 16.7258 10.7599 17 11.098 17H15.0043Z"
        fill={color}
      />
    </svg>
  );
};

export const GalleryIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={GalleryIconOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
