import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

const NoImageOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19525 5.43331C6.19525 6.35378 6.94144 7.09998 7.86191 7.09998C8.78239 7.09998 9.52858 6.35378 9.52858 5.43331C9.52858 4.51283 8.78239 3.76664 7.86191 3.76664C6.94144 3.76664 6.19525 4.51283 6.19525 5.43331ZM7.06191 5.43331C7.06191 5.87514 7.42009 6.23331 7.86191 6.23331C8.30374 6.23331 8.66191 5.87514 8.66191 5.43331C8.66191 4.99148 8.30374 4.63331 7.86191 4.63331C7.42009 4.63331 7.06191 4.99148 7.06191 5.43331Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7667 4C13.7667 2.89543 12.8713 2 11.7667 2H6.43341C5.32884 2 4.43341 2.89543 4.43341 4V10.6667C4.43341 11.7712 5.32884 12.6667 6.43341 12.6667H6.43423L6.43341 12.668H11.7667C12.8713 12.668 13.7667 11.7726 13.7667 10.668V4ZM12.4302 7.52648L12.9 8.2061V4C12.9 3.37408 12.3926 2.86667 11.7667 2.86667H6.43338C5.80746 2.86667 5.30005 3.37408 5.30005 4V10.6667C5.30005 11.2926 5.80746 11.8 6.43338 11.8H6.96383L9.5254 7.60835C10.1791 6.5386 11.7172 6.49525 12.4302 7.52648ZM11.7667 11.8C12.3926 11.8 12.9 11.2926 12.9 10.6667V9.7301L11.7173 8.01934C11.3608 7.50372 10.5918 7.5254 10.2649 8.06027L7.97952 11.8H11.7667Z"
        fill={color}
      />
      <path
        d="M3.10008 5.56667C2.86076 5.56667 2.66675 5.76068 2.66675 6V12.2667C2.66675 13.4633 3.6368 14.4333 4.83341 14.4333H10.4334C10.6727 14.4333 10.8667 14.2393 10.8667 14C10.8667 13.7607 10.6727 13.5667 10.4334 13.5667H4.83341C4.11544 13.5667 3.53341 12.9846 3.53341 12.2667V6C3.53341 5.76068 3.3394 5.56667 3.10008 5.56667Z"
        fill={color}
      />
    </svg>
  );
};

const NoImageSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.19525 5.43331C6.19525 6.35378 6.94144 7.09998 7.86191 7.09998C8.78239 7.09998 9.52858 6.35378 9.52858 5.43331C9.52858 4.51283 8.78239 3.76664 7.86191 3.76664C6.94144 3.76664 6.19525 4.51283 6.19525 5.43331ZM7.06191 5.43331C7.06191 5.87514 7.42009 6.23331 7.86191 6.23331C8.30374 6.23331 8.66191 5.87514 8.66191 5.43331C8.66191 4.99148 8.30374 4.63331 7.86191 4.63331C7.42009 4.63331 7.06191 4.99148 7.06191 5.43331Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.7667 4C13.7667 2.89543 12.8713 2 11.7667 2H6.43341C5.32884 2 4.43341 2.89543 4.43341 4V10.6667C4.43341 11.7712 5.32884 12.6667 6.43341 12.6667H6.43423L6.43341 12.668H11.7667C12.8713 12.668 13.7667 11.7726 13.7667 10.668V4ZM12.4302 7.52648L12.9 8.2061V4C12.9 3.37408 12.3926 2.86667 11.7667 2.86667H6.43338C5.80746 2.86667 5.30005 3.37408 5.30005 4V10.6667C5.30005 11.2926 5.80746 11.8 6.43338 11.8H6.96383L9.5254 7.60835C10.1791 6.5386 11.7172 6.49525 12.4302 7.52648ZM11.7667 11.8C12.3926 11.8 12.9 11.2926 12.9 10.6667V9.7301L11.7173 8.01934C11.3608 7.50372 10.5918 7.5254 10.2649 8.06027L7.97952 11.8H11.7667Z"
        fill={color}
      />
      <path
        d="M3.10008 5.56667C2.86076 5.56667 2.66675 5.76068 2.66675 6V12.2667C2.66675 13.4633 3.6368 14.4333 4.83341 14.4333H10.4334C10.6727 14.4333 10.8667 14.2393 10.8667 14C10.8667 13.7607 10.6727 13.5667 10.4334 13.5667H4.83341C4.11544 13.5667 3.53341 12.9846 3.53341 12.2667V6C3.53341 5.76068 3.3394 5.56667 3.10008 5.56667Z"
        fill={color}
      />
    </svg>
  );
};

export const NoImageIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={NoImageOutlined}
      solidSvg={NoImageSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
