import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const EyeOpenedOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3061 9.08066C11.6823 8.99158 12 9.3134 12 9.7V10.7C12 11.418 12.5821 12 13.3 12H14.3C14.6866 12 15.0085 12.3178 14.9194 12.6939C14.6062 14.0162 13.418 15 12 15C10.3432 15 9.00003 13.6569 9.00003 12C9.00003 10.582 9.98382 9.39379 11.3061 9.08066Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C7.11931 5 3.86625 8.65464 2.43688 10.6825C1.8739 11.4812 1.8739 12.5188 2.43688 13.3175C3.86626 15.3454 7.11931 19 12 19C16.8808 19 20.1338 15.3454 21.5632 13.3175C22.1262 12.5188 22.1262 11.4812 21.5632 10.6825C20.1338 8.65464 16.8808 5 12 5ZM5.70874 8.92728C4.76185 9.77768 4.02109 10.6914 3.49945 11.4315C3.25305 11.7811 3.25305 12.2189 3.49945 12.5685C4.02109 13.3086 4.76185 14.2223 5.70873 15.0727C5.25483 14.1451 5.00004 13.1023 5.00004 12C5.00004 10.8977 5.25483 9.85489 5.70874 8.92728ZM12 17.7C8.85202 17.7 6.30004 15.148 6.30004 12C6.30004 8.85198 8.85202 6.3 12 6.3C15.1481 6.3 17.7 8.85198 17.7 12C17.7 15.148 15.1481 17.7 12 17.7ZM18.2913 8.92728C19.2382 9.77768 19.979 10.6914 20.5006 11.4315C20.747 11.7811 20.747 12.2189 20.5006 12.5685C19.979 13.3086 19.2382 14.2223 18.2913 15.0727C18.7452 14.1451 19 13.1023 19 12C19 10.8977 18.7452 9.85489 18.2913 8.92728Z"
        fill={color}
      />
    </svg>
  );
};

const EyeOpenedSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 9.7C12 9.3134 11.6823 8.99158 11.3061 9.08066C9.9838 9.39379 9.00001 10.582 9.00001 12C9.00001 13.6569 10.3432 15 12 15C13.418 15 14.6062 14.0162 14.9193 12.6939C15.0084 12.3178 14.6866 12 14.3 12H13.3C12.582 12 12 11.418 12 10.7V9.7Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.77321 10.7185C4.13585 8.69823 7.27545 5 12 5C16.7246 5 19.8642 8.69823 21.2268 10.7185C21.7548 11.5014 21.7548 12.4986 21.2268 13.2815C19.8642 15.3018 16.7246 19 12 19C7.27545 19 4.13585 15.3018 2.77321 13.2815C2.24519 12.4986 2.24519 11.5014 2.77321 10.7185ZM7.35002 12C7.35002 9.43185 9.43189 7.34998 12 7.34998C14.5681 7.34998 16.65 9.43185 16.65 12C16.65 14.5681 14.5681 16.65 12 16.65C9.43189 16.65 7.35002 14.5681 7.35002 12Z"
        fill={color}
      />
    </svg>
  );
};

export const EyeOpenedIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={EyeOpenedOutlined}
      solidSvg={EyeOpenedSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
