import React from 'react';

export const MenuLogo: React.VFC = () => (
  <svg width="80" height="18" viewBox="0 0 80 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.4565 1.80176V16.2002H28.5966V11.6601H31.4043C34.5652 11.6601 36.7591 9.68445 36.7591 6.73094C36.7591 3.76745 34.638 1.80176 31.5602 1.80176H25.4565ZM28.5966 4.18653H30.7283C32.5272 4.18653 33.5669 5.10451 33.5669 6.74092C33.5669 8.3873 32.5168 9.30529 30.7179 9.30529H28.5966V4.18653Z"
      fill="#2B313B"
    />
    <path d="M38.8586 16.2002H41.8949V1.80176H38.8586V16.2002Z" fill="#2B313B" />
    <path
      d="M48.6686 14.2644C47.6393 14.2644 46.9529 13.7655 46.9529 12.9673C46.9529 12.2089 47.5977 11.72 48.731 11.6402L51.0811 11.5005V12.2788C51.0811 13.4363 49.9891 14.2644 48.6686 14.2644ZM47.689 16.2002C49.0824 16.2002 50.4467 15.7013 51.0706 14.5638H51.1329V16.2002H54.0652V8.84634C54.0652 6.69107 52.204 5.27417 49.3446 5.27417C46.3914 5.27417 44.551 6.70104 44.4367 8.7765H47.2129C47.3584 7.98822 48.0761 7.4494 49.2094 7.4494C50.3532 7.4494 51.0811 8.02814 51.0811 9.02594V9.7344L48.2736 9.89403C45.4972 10.0537 43.9375 11.1812 43.9375 13.1269C43.9375 15.0527 45.568 16.2002 47.689 16.2002Z"
      fill="#2B313B"
    />
    <path
      d="M56.1489 16.2062H59.1851V10.2294C59.1851 8.72266 60.0585 7.8446 61.5558 7.8446C61.9925 7.8446 62.5016 7.85102 62.7512 7.9508V5.46267C62.5432 5.40279 62.1485 5.34009 61.795 5.34009C60.4849 5.34009 59.5386 6.07847 59.1435 7.43548H59.081V5.50971H56.1489V16.2062Z"
      fill="#2B313B"
    />
    <path
      d="M64.599 16.1787H67.6352V5.50363H64.599V16.1787ZM66.1171 4.81515C67.0323 4.81515 67.7393 4.14661 67.7393 3.30845C67.7393 2.46032 67.0323 1.80176 66.1171 1.80176C65.2021 1.80176 64.4951 2.46032 64.4951 3.30845C64.4951 4.14661 65.2021 4.81515 66.1171 4.81515Z"
      fill="#2B313B"
    />
    <path
      d="M69.6541 16.2039H72.6903V10.0674C72.6903 8.68047 73.5532 7.72257 74.9154 7.72257C76.2775 7.72257 76.9637 8.52081 76.9637 9.91775V16.2039H79.9999V9.30909C79.9999 6.80458 78.6065 5.30786 76.1318 5.30786C74.4161 5.30786 73.2413 6.08616 72.6486 7.41323H72.5862V5.50742H69.6541V16.2039Z"
      fill="#2B313B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.2561 3C5.81768 3 3.03027 5.75956 3.03027 9.16363V15H8.92553C12.364 15 15.1514 12.2404 15.1514 8.83637V3H9.2561ZM0 9.16363C0 4.10271 4.14411 0 9.2561 0H18.1816V8.83637C18.1816 13.8973 14.0375 18 8.92553 18H0V9.16363Z"
      fill="#214ecb"
    />
    <path
      d="M6.58594 9.21305C6.58594 7.74029 7.79189 6.54639 9.27951 6.54639H11.9731V9.21305C11.9731 10.6858 10.7671 11.8797 9.27951 11.8797C7.79189 11.8797 6.58594 10.6858 6.58594 9.21305Z"
      fill="#7FC222"
    />
  </svg>
);
