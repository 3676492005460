import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const UpdateOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.1792 8.24895C13.048 8.12571 12.972 7.9583 12.9731 7.76725C12.9831 7.40614 13.2784 7.12101 13.6322 7.12411L15.7846 7.1079L15.795 7.09669C14.7462 6.2838 13.4296 5.79993 12 5.79993C8.57583 5.79993 5.8 8.57577 5.8 11.9999C5.8 12.1841 5.80803 12.3665 5.82377 12.5466C5.85289 12.88 5.65987 13.202 5.33927 13.2982C4.96965 13.409 4.58203 13.1785 4.54161 12.7947C4.5141 12.5335 4.5 12.2684 4.5 11.9999C4.5 7.8578 7.85786 4.49993 12 4.49993C13.7642 4.49993 15.3862 5.1091 16.667 6.12856L16.4805 4.09214C16.4481 3.73236 16.7215 3.42669 17.0813 3.39423C17.4342 3.36905 17.7467 3.6352 17.7792 3.99499L18.0669 7.6467C18.1012 8.06303 17.7725 8.41287 17.3549 8.40469L13.6163 8.42632C13.4394 8.42477 13.2958 8.3585 13.1792 8.24895Z"
        fill={color}
      />
      <path
        d="M10.2902 15.2935C10.4214 15.4168 10.4974 15.5842 10.4963 15.7752C10.4864 16.1363 10.1911 16.4215 9.83724 16.4184L7.68488 16.4346L7.67643 16.4437C8.7933 17.5305 10.3185 18.1999 12 18.1999C15.4242 18.1999 18.2 15.4241 18.2 11.9999C18.2 11.6179 18.1654 11.2439 18.0993 10.881C18.0377 10.5429 18.2109 10.196 18.5338 10.0785C18.8872 9.95005 19.2786 10.145 19.3527 10.5136C19.4493 10.994 19.5 11.4911 19.5 11.9999C19.5 16.1421 16.1421 19.4999 12 19.4999C9.98183 19.4999 8.14984 18.7028 6.8017 17.4062L6.98893 19.4503C7.02139 19.8101 6.74796 20.1158 6.38817 20.1482C6.03524 20.1734 5.72272 19.9073 5.69026 19.5475L5.40252 15.8958C5.3683 15.4794 5.69695 15.1296 6.1146 15.1378L9.85316 15.1162C10.0301 15.1177 10.1736 15.184 10.2902 15.2935Z"
        fill={color}
      />
    </svg>
  );
};

const UpdateSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM14.8802 7.13922L14.6524 6.27222C14.5539 5.92522 14.7434 5.56108 15.0904 5.46262C15.4374 5.36416 15.8016 5.55363 15.9 5.90063L16.6117 8.41998C16.7296 8.81305 16.4926 9.21892 16.0974 9.3008L13.6669 9.78448C13.3163 9.86927 12.9723 9.64525 12.8875 9.29466C12.8307 9.13412 12.8632 8.97937 12.9439 8.8412C13.0296 8.6944 13.1808 8.57426 13.3673 8.53259L14.3291 8.32601C13.4572 7.77269 12.3871 7.52662 11.289 7.70853C9.07627 8.0751 7.53145 10.0522 7.65625 12.2415C7.67668 12.5999 7.40269 12.907 7.04429 12.9274C6.68589 12.9479 6.37878 12.6739 6.35835 12.3155C6.19617 9.4703 8.20156 6.90228 11.0766 6.42601C12.443 6.19964 13.776 6.48432 14.8802 7.13922ZM17.6416 11.6846C17.6211 11.3262 17.314 11.0522 16.9556 11.0726C16.5972 11.0931 16.3233 11.4002 16.3437 11.7586C16.4685 13.9479 14.9237 15.925 12.7109 16.2915C11.6128 16.4735 10.5428 16.2274 9.67085 15.6741L10.6328 15.4675C10.8192 15.4258 10.9704 15.3057 11.0562 15.1589C11.1369 15.0207 11.1693 14.866 11.1125 14.7054C11.0277 14.3548 10.6838 14.1308 10.3332 14.2156L7.90264 14.6993C7.50743 14.7812 7.27043 15.187 7.3883 15.5801L8.10002 18.0995C8.19848 18.4464 8.56262 18.6359 8.90962 18.5375C9.25661 18.439 9.44609 18.0749 9.34763 17.7279L9.1199 16.8609C10.224 17.5158 11.5571 17.8004 12.9234 17.5741C15.7984 17.0978 17.8038 14.5298 17.6416 11.6846Z"
        fill={color}
      />
    </svg>
  );
};

export const UpdateIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={UpdateOutlined}
      solidSvg={UpdateSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
