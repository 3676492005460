import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const EurOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.71691 11.4871C7.69843 11.6555 7.68894 11.8267 7.68894 12C7.68894 12.1734 7.69843 12.3445 7.71691 12.5129H6.98886C6.62988 12.5129 6.33886 12.8039 6.33886 13.1629C6.33886 13.5219 6.62988 13.8129 6.98886 13.8129H8.0556C8.7622 15.4803 10.414 16.65 12.3389 16.65C13.5178 16.65 14.5956 16.2105 15.4149 15.4873C15.684 15.2498 15.7097 14.839 15.4721 14.5699C15.2345 14.3007 14.8238 14.2751 14.5546 14.5127C13.9637 15.0343 13.189 15.35 12.3389 15.35C11.1568 15.35 10.1177 14.7377 9.5214 13.8129H12.697C13.056 13.8129 13.347 13.5219 13.347 13.1629C13.347 12.8039 13.056 12.5129 12.697 12.5129H9.02796C9.00227 12.3457 8.98894 12.1744 8.98894 12C8.98894 11.8256 9.00227 11.6543 9.02796 11.4871L12.697 11.4871C13.056 11.4871 13.347 11.1961 13.347 10.8371C13.347 10.4781 13.056 10.1871 12.697 10.1871H9.52139C10.1177 9.26233 11.1568 8.65001 12.3389 8.65001C13.189 8.65001 13.9637 8.96576 14.5546 9.48734C14.8238 9.72489 15.2345 9.69928 15.4721 9.43013C15.7097 9.16099 15.684 8.75023 15.4149 8.51268C14.5956 7.78953 13.5178 7.35001 12.3389 7.35001C10.414 7.35001 8.7622 8.5197 8.0556 10.1871H6.98886C6.62988 10.1871 6.33886 10.4781 6.33886 10.8371C6.33886 11.1961 6.62988 11.4871 6.98886 11.4871H7.71691Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM12 4.3C7.74741 4.3 4.3 7.74741 4.3 12C4.3 16.2526 7.74741 19.7 12 19.7C16.2526 19.7 19.7 16.2526 19.7 12C19.7 7.74741 16.2526 4.3 12 4.3Z"
        fill={color}
      />
    </svg>
  );
};

const EurSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM7.58542 11.4871C7.56694 11.6555 7.55746 11.8267 7.55746 12C7.55746 12.1734 7.56694 12.3445 7.58542 12.5129H6.85737C6.49839 12.5129 6.20737 12.8039 6.20737 13.1629C6.20737 13.5219 6.49839 13.8129 6.85737 13.8129H7.92412C8.63072 15.4803 10.2825 16.65 12.2075 16.65C13.3863 16.65 14.4641 16.2105 15.2834 15.4873C15.5526 15.2498 15.5782 14.839 15.3406 14.5699C15.1031 14.3007 14.6923 14.2751 14.4232 14.5127C13.8322 15.0343 13.0575 15.35 12.2075 15.35C11.0253 15.35 9.98618 14.7377 9.38991 13.8129H12.5655C12.9245 13.8129 13.2155 13.5219 13.2155 13.1629C13.2155 12.8039 12.9245 12.5129 12.5655 12.5129H8.89648C8.87078 12.3457 8.85746 12.1744 8.85746 12C8.85746 11.8256 8.87078 11.6543 8.89647 11.4871L12.5655 11.4871C12.9245 11.4871 13.2155 11.1961 13.2155 10.8371C13.2155 10.4781 12.9245 10.1871 12.5655 10.1871H9.3899C9.98617 9.26233 11.0253 8.65001 12.2075 8.65001C13.0575 8.65001 13.8322 8.96576 14.4232 9.48734C14.6923 9.72489 15.1031 9.69928 15.3406 9.43013C15.5782 9.16099 15.5526 8.75023 15.2834 8.51268C14.4641 7.78953 13.3863 7.35001 12.2075 7.35001C10.2825 7.35001 8.63071 8.5197 7.92411 10.1871H6.85737C6.49839 10.1871 6.20737 10.4781 6.20737 10.8371C6.20737 11.1961 6.49839 11.4871 6.85737 11.4871H7.58542Z"
        fill={color}
      />
    </svg>
  );
};

export const EurIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={EurOutlined}
      solidSvg={EurSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
