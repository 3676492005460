import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const RightDoubleOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.51681 7.99882C8.64681 7.85882 8.81681 7.78882 8.99681 7.78882C9.15681 7.78882 9.30681 7.83882 9.43681 7.95882L13.4199 11.5451C13.7299 11.8251 13.7299 12.3051 13.4199 12.5851L9.44071 16.0429C9.17071 16.2829 8.76071 16.2629 8.52071 16.0029C8.28071 15.7329 8.29071 15.3229 8.56071 15.0829L12.0499 12.0651L8.55681 8.91882C8.29681 8.67882 8.27681 8.26882 8.51681 7.99882Z"
        fill={color}
      />
      <path
        d="M12.5168 7.99882C12.6468 7.85882 12.8168 7.78882 12.9968 7.78882C13.1568 7.78882 13.3068 7.83882 13.4368 7.95882L17.4199 11.5451C17.7299 11.8251 17.7299 12.3051 17.4199 12.5851L13.4407 16.0429C13.1707 16.2829 12.7607 16.2629 12.5207 16.0029C12.2807 15.7329 12.2907 15.3229 12.5607 15.0829L16.0499 12.0651L12.5568 8.91882C12.2968 8.67882 12.2768 8.26882 12.5168 7.99882Z"
        fill={color}
      />
    </svg>
  );
};

const RightDoubleSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM13.0378 15.3312C12.8578 15.3312 12.6878 15.2612 12.5578 15.1212C12.3178 14.8512 12.3378 14.4412 12.5978 14.2012L15.0976 11.9533L12.602 9.79702C12.332 9.55702 12.322 9.14702 12.562 8.87702C12.802 8.61702 13.212 8.59702 13.482 8.83702L16.4676 11.4333C16.7776 11.7133 16.7776 12.1933 16.4676 12.4733L13.4778 15.1612C13.3478 15.2812 13.1978 15.3312 13.0378 15.3312ZM9.13792 15.3312C8.95792 15.3312 8.78792 15.2612 8.65792 15.1212C8.41792 14.8512 8.43792 14.4412 8.69792 14.2012L11.1977 11.9533L8.70207 9.79704C8.43207 9.55704 8.42207 9.14704 8.66207 8.87704C8.90207 8.61704 9.31207 8.59704 9.58207 8.83704L12.5677 11.4333C12.8777 11.7133 12.8777 12.1933 12.5677 12.4733L9.57792 15.1612C9.44792 15.2812 9.29792 15.3312 9.13792 15.3312Z"
        fill={color}
      />
    </svg>
  );
};

export const RightDoubleIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={RightDoubleOutlined}
      solidSvg={RightDoubleSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
