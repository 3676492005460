import { SvgIcon } from '@mui/material';
import React from 'react';
import { TSvgProps, IconWrapper, IIconProps, iconColors } from './_utils';

const SortDownOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.76922 10.4865L11.9969 12.8981L14.2277 10.4865L9.76922 10.4865ZM11.4824 14.257C11.7594 14.5569 12.2333 14.5571 12.5105 14.2574L16.1139 10.3619C16.5285 9.91363 16.2106 9.18652 15.6 9.18652L8.39816 9.18652C7.78781 9.18652 7.46982 9.91317 7.88397 10.3615L11.4824 14.257Z"
        fill={color}
      />
    </svg>
  );
};

const SortDownSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.5105 14.2574C12.2333 14.5571 11.7594 14.5569 11.4824 14.257L7.88397 10.3615C7.46982 9.91317 7.78781 9.18652 8.39816 9.18652L15.6 9.18652C16.2106 9.18652 16.5285 9.91363 16.1139 10.3619L12.5105 14.2574Z"
        fill={color}
      />
    </svg>
  );
};

// эта иконка нужна для компонента с типом иконки строго React.ElementType
export const SortDownSolidET: React.ElementType = props => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path
      d="M12.5105 14.2574C12.2333 14.5571 11.7594 14.5569 11.4824 14.257L7.88397 10.3615C7.46982 9.91317 7.78781 9.18652 8.39816 9.18652L15.6 9.18652C16.2106 9.18652 16.5285 9.91363 16.1139 10.3619L12.5105 14.2574Z"
      fill={iconColors.main}
    />
  </SvgIcon>
);

export const SortDownSolidETDisabled: React.ElementType = props => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none">
    <path
      d="M12.5105 14.2574C12.2333 14.5571 11.7594 14.5569 11.4824 14.257L7.88397 10.3615C7.46982 9.91317 7.78781 9.18652 8.39816 9.18652L15.6 9.18652C16.2106 9.18652 16.5285 9.91363 16.1139 10.3619L12.5105 14.2574Z"
      fill={iconColors.disabled}
    />
  </SvgIcon>
);

export const SortDownIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={SortDownOutlined}
      solidSvg={SortDownSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
