import { CircularProgress } from '@mui/material';
import clsx from 'clsx';
import React from 'react';
import classes from './style.module.scss';

type PreloaderProps = {
  className?: string;
  size?: string | number;
  thickness?: number;
};

export const Preloader = ({ className, size, thickness }: PreloaderProps) => (
  <div className={clsx(classes.preloaderWrapper, className)}>
    <CircularProgress size={size} thickness={thickness} />
  </div>
);
