import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const ArrowsVerticaleOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.4739 10.4106C12.4739 10.5906 12.5439 10.7606 12.6839 10.8906C12.9539 11.1306 13.3639 11.1106 13.6039 10.8506L15.0864 9.29617L15.0693 17.3723C15.0687 17.7318 15.3599 18.0235 15.7193 18.0235C16.0779 18.0235 16.3687 17.7331 16.3694 17.3746C16.3763 14.0147 16.3894 12.6599 16.3894 9.30005L17.7639 10.8506C18.0039 11.1206 18.4139 11.1306 18.6839 10.8906C18.9439 10.6506 18.9639 10.2406 18.7239 9.97061L16.2594 7.26062C15.9794 6.95062 15.4994 6.95062 15.2194 7.26062L12.6439 9.97061C12.5239 10.1006 12.4739 10.2506 12.4739 10.4106Z"
        fill={color}
      />
      <path
        d="M5.12539 12.6447C5.12539 12.4647 5.19539 12.2947 5.33539 12.1647C5.60539 11.9247 6.01539 11.9447 6.25539 12.2047L7.73787 13.7592L7.72075 6.67896C7.72014 6.31953 8.01135 6.02783 8.37078 6.02783C8.72933 6.02783 9.02016 6.31815 9.0208 6.6767C9.02679 10.0379 9.0409 10.3941 9.0409 13.7553L10.4154 12.2047C10.6554 11.9347 11.0654 11.9247 11.3354 12.1647C11.5954 12.4047 11.6154 12.8147 11.3754 13.0847L8.9109 15.7947C8.6309 16.1047 8.1509 16.1047 7.8709 15.7947L5.29539 13.0847C5.17539 12.9547 5.12539 12.8047 5.12539 12.6447Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.724 18.1233C10.9684 18.1233 11.1665 17.8811 11.1665 17.5823C11.1665 17.2835 10.9684 17.0413 10.724 17.0413L5.60897 17.0413C5.3646 17.0413 5.1665 17.2835 5.1665 17.5823C5.1665 17.8811 5.3646 18.1233 5.60897 18.1233L10.724 18.1233Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 5.96339C18.283 5.96339 18.5123 5.72117 18.5123 5.42237C18.5123 5.12357 18.283 4.88135 18 4.88135L13.0247 4.88135C12.7417 4.88135 12.5123 5.12357 12.5123 5.42237C12.5123 5.72117 12.7417 5.96339 13.0247 5.96339L18 5.96339Z"
        fill={color}
      />
    </svg>
  );
};

export const ArrowsVerticale2Icon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={ArrowsVerticaleOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
