import { TColor } from './types';
import '../../scss/color-classes.scss';

export function getColorClass(color: TColor): string {
  return `${color}Color`;
}

export function getBackgroundColorClass(color: TColor): string {
  return `${color}BgColor`;
}
