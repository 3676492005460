import React from 'react';

export const BlackMenuLogo: React.VFC = () => (
  <svg width="82" height="18" viewBox="0 0 82 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.0938 1.80273V16.2011H29.3123V11.6611H32.1902C35.4301 11.6611 37.6788 9.68543 37.6788 6.73192C37.6788 3.76843 35.5048 1.80273 32.35 1.80273H26.0938ZM29.3123 4.18751H31.4973C33.3412 4.18751 34.4069 5.10548 34.4069 6.7419C34.4069 8.38827 33.3305 9.30627 31.4866 9.30627H29.3123V4.18751Z"
      fill="#EFF2F5"
    />
    <path d="M39.8301 16.2011H42.9422V1.80273H39.8301V16.2011Z" fill="#EFF2F5" />
    <path
      d="M49.8845 14.2637C48.8295 14.2637 48.1259 13.7648 48.1259 12.9666C48.1259 12.2082 48.7868 11.7193 49.9485 11.6395L52.3573 11.4998V12.2781C52.3573 13.4355 51.2381 14.2637 49.8845 14.2637ZM48.8805 16.1995C50.3087 16.1995 51.7071 15.7006 52.3466 14.563H52.4104V16.1995H55.4161V8.84561C55.4161 6.69033 53.5083 5.27344 50.5774 5.27344C47.5504 5.27344 45.664 6.70031 45.5469 8.77577H48.3925C48.5416 7.98749 49.2772 7.44867 50.4389 7.44867C51.6113 7.44867 52.3573 8.02741 52.3573 9.02521V9.73366L49.4797 9.8933C46.6339 10.053 45.0352 11.1805 45.0352 13.1262C45.0352 15.052 46.7064 16.1995 48.8805 16.1995Z"
      fill="#EFF2F5"
    />
    <path
      d="M57.5527 16.206H60.6649V10.2291C60.6649 8.72241 61.56 7.84436 63.0947 7.84436C63.5424 7.84436 64.0642 7.85078 64.32 7.95056V5.46242C64.1069 5.40254 63.7023 5.33984 63.3399 5.33984C61.9971 5.33984 61.0272 6.07822 60.6222 7.43524H60.5582V5.50946H57.5527V16.206Z"
      fill="#EFF2F5"
    />
    <path
      d="M66.2139 16.1797H69.326V5.5046H66.2139V16.1797ZM67.77 4.81613C68.708 4.81613 69.4327 4.14759 69.4327 3.30943C69.4327 2.46129 68.708 1.80273 67.77 1.80273C66.8321 1.80273 66.1074 2.46129 66.1074 3.30943C66.1074 4.14759 66.8321 4.81613 67.77 4.81613Z"
      fill="#EFF2F5"
    />
    <path
      d="M71.3945 16.2047H74.5066V10.0682C74.5066 8.6812 75.3912 7.72331 76.7874 7.72331C78.1836 7.72331 78.8869 8.52154 78.8869 9.91848V16.2047H81.9991V9.30982C81.9991 6.80531 80.5708 5.30859 78.0342 5.30859C76.2757 5.30859 75.0715 6.08689 74.464 7.41397H74.4V5.50815H71.3945V16.2047Z"
      fill="#EFF2F5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.4875 3C5.96312 3 3.10603 5.75956 3.10603 9.16363V15H9.14867C12.6731 15 15.5301 12.2404 15.5301 8.83637V3H9.4875ZM0 9.16363C0 4.10271 4.24771 0 9.4875 0H18.6362V8.83637C18.6362 13.8973 14.3885 18 9.14867 18H0V9.16363Z"
      fill="#214ECB"
    />
    <path
      d="M6.75 9.21354C6.75 7.74077 7.9861 6.54688 9.51092 6.54688H12.2718V9.21354C12.2718 10.6863 11.0357 11.8802 9.51092 11.8802C7.9861 11.8802 6.75 10.6863 6.75 9.21354Z"
      fill="#7FC222"
    />
  </svg>
);
