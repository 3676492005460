import React from 'react';

export const VkAdsIntegrationsIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19.5" fill="white" stroke="#EFF2F5" />
    <path
      d="M8 19.4095C8 14.0316 8 11.3374 9.67135 9.67135C11.3427 8.00527 14.0316 8 19.4095 8H20.5905C25.9684 8 28.6573 8 30.3286 9.67135C32 11.3427 32 14.0316 32 19.4095V20.5905C32 25.9684 32 28.6573 30.3286 30.3286C28.6573 32 25.9684 32 20.5905 32H19.4095C14.0316 32 11.3427 32 9.67135 30.3286C8 28.6573 8 25.9684 8 20.5905V19.4095Z"
      fill="#0077FF"
    />
    <path
      d="M20 28C24.4183 28 28 24.4183 28 20C28 15.5817 24.4183 12 20 12C15.5817 12 12 15.5817 12 20C12 24.4183 15.5817 28 20 28Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.4439 11.0097V12.5504H28.995C29.1918 12.5493 29.3846 12.6051 29.5491 12.7108C29.7136 12.8166 29.8426 12.9676 29.9198 13.1448C29.9971 13.3221 30.0191 13.5178 29.9833 13.7073C29.9474 13.8968 29.8551 14.0717 29.7181 14.21L27.5929 16.3724C27.4996 16.4681 27.3874 16.5443 27.2631 16.5963C27.1388 16.6484 27.005 16.6752 26.8698 16.6751H24.8605L22.7795 18.8807C22.9385 19.2521 23.0192 19.6511 23.0168 20.0538C23.0168 20.6365 22.8404 21.2061 22.5098 21.6906C22.1793 22.1751 21.7094 22.5527 21.1597 22.7757C20.61 22.9987 20.0051 23.0571 19.4215 22.9434C18.8379 22.8297 18.3019 22.5491 17.8811 22.1371C17.4604 21.725 17.1739 21.2001 17.0578 20.6286C16.9417 20.0571 17.0013 19.4647 17.229 18.9263C17.4567 18.388 17.8423 17.9278 18.337 17.6041C18.8318 17.2804 19.4134 17.1076 20.0084 17.1076C20.2845 17.1075 20.5592 17.1439 20.8254 17.2157L23.2156 14.6479V13.0261C23.2158 12.8925 23.2438 12.7603 23.2979 12.6376C23.352 12.5149 23.431 12.4043 23.5302 12.3126L25.7382 10.2637C25.881 10.1335 26.0594 10.0469 26.2516 10.0144C26.4438 9.98184 26.6415 10.0048 26.8206 10.0805C26.9998 10.1561 27.1525 10.2812 27.2602 10.4405C27.368 10.5997 27.426 10.7863 27.4273 10.9773L27.4439 11.0097Z"
      fill="#313131"
    />
  </svg>
);
