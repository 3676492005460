import React from 'react';
import { BasicIcon } from '../_utils/type';

export const EmptyTableFindIcon = ({ className }: BasicIcon) => {
  return (
    <svg
      width="99"
      height="99"
      viewBox="0 0 99 99"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M87.2393 77.8477L65.2683 55.8165C64.9166 55.4639 64.8665 54.9121 65.1365 54.4936C68.7779 48.8508 70.7075 42.2687 70.7075 35.3461C70.7075 25.9081 67.0064 16.9636 60.3443 10.3016C53.7439 3.70116 44.8611 0 35.3615 0C25.8618 0 17.1024 3.70116 10.317 10.3632C-3.43899 24.1192 -3.43899 46.5729 10.317 60.3289C16.9791 66.9909 25.8618 70.6921 35.3615 70.6921C42.2289 70.6921 48.8123 68.7035 54.5117 65.1079C54.9293 64.8444 55.4753 64.8979 55.8245 65.247L77.8631 87.2856C79.1585 88.581 80.8857 89.2596 82.6129 89.2596C84.3401 89.2596 86.0673 88.581 87.3627 87.2856C89.8919 84.6948 89.8918 80.4385 87.2393 77.8477Z"
        fill="url(#paint0_linear_3103_47227)"
      />
      <path
        d="M55.3101 54.4844C49.9928 59.7025 42.9641 62.5215 35.5076 62.5215C28.0512 62.5215 21.0225 59.7025 15.7052 54.4844C4.76494 43.7483 4.76494 26.2947 15.7052 15.5586C21.0225 10.3405 28.0512 7.52148 35.5076 7.52148C42.9641 7.52148 49.9928 10.4004 55.3101 15.5586C60.6274 20.7767 63.5 27.6742 63.5 34.9915C63.5 42.3688 60.6274 49.2663 55.3101 54.4844Z"
        fill="url(#paint1_linear_3103_47227)"
      />
      <path
        d="M77.2274 69.6695L69.5946 77.4437C68.4366 78.6231 68.4508 80.5169 69.6262 81.6789L83.0956 94.9943C85.0642 96.9404 88.2393 96.9167 90.1786 94.9414L95.009 90.0216C96.939 88.0559 96.9154 84.8995 94.9563 82.9628L81.4772 69.6378C80.296 68.4702 78.391 68.4844 77.2274 69.6695Z"
        fill="#57C3FF"
      />
      <path
        d="M20 35.5215C23.0376 35.5215 25.5 33.0591 25.5 30.0215C25.5 26.9839 23.0376 24.5215 20 24.5215C16.9624 24.5215 14.5 26.9839 14.5 30.0215C14.5 33.0591 16.9624 35.5215 20 35.5215Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3103_47227"
          x1="5.60819e-06"
          y1="8.5"
          x2="80"
          y2="89"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#638AD2" />
          <stop offset="0.828087" stopColor="#214ecb" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3103_47227"
          x1="19"
          y1="26"
          x2="61.0828"
          y2="69.6166"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F1F2F4" />
          <stop offset="0.741564" stopColor="#CFDEEF" />
        </linearGradient>
      </defs>
    </svg>
  );
};
