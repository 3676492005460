import React from 'react';
import { BasicIcon } from '../_utils/type';

export const ProposeIdeaSuccessIcon = ({ className }: BasicIcon) => (
  <svg className={className} width="81" height="81" viewBox="0 0 81 81" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.85773 60.7986C10.1288 59.6884 11.2499 59.003 12.3617 59.2678L31.8945 63.9196C33.0063 64.1844 33.6878 65.299 33.4167 66.4093C33.1456 67.5196 32.0245 68.205 30.9127 67.9402L29.0791 67.5035C27.7974 67.1983 26.4751 67.8492 25.9338 69.0518L25.6024 69.7879C24.5313 72.1674 21.915 73.4553 19.3791 72.8514C16.8432 72.2474 15.1089 69.9235 15.2537 67.3234L15.2986 66.519C15.3718 65.2049 14.4952 64.0303 13.2135 63.7251L11.3799 63.2884C10.2681 63.0236 9.58662 61.9089 9.85773 60.7986Z"
      fill="#FFBD58"
    />
    <path
      d="M35.0725 70.2356L6.61833 63.4591C3.77292 62.7814 2.02875 59.9286 2.72262 57.0871C3.04339 55.7735 3.85516 54.6295 4.99153 53.8895L8.04 51.9044C10.4752 50.3188 12.3786 48.0406 13.5023 45.3668L17.3445 36.224C18.1365 34.3392 19.9674 33.0931 22.0123 33.0469L23.3433 33.0169C25.2034 32.9749 26.7442 31.5499 26.9271 29.7024L26.9981 28.9851C27.1797 27.1501 28.9468 25.8921 30.742 26.3196C32.5371 26.7471 33.5293 28.6623 32.8441 30.3773L32.5762 31.0478C31.8863 32.7745 32.6 34.7364 34.2363 35.6111L35.4071 36.2369C37.206 37.1985 38.2604 39.1318 38.0955 41.1659L37.2956 51.0333C37.0617 53.9189 37.7044 56.8035 39.1401 59.3111L40.9373 62.4501C41.6072 63.6203 41.8017 65.0039 41.481 66.3175C40.7871 69.159 37.9179 70.9132 35.0725 70.2356Z"
      fill="url(#paint0_linear_4121_801287)"
    />
    <path
      d="M27.2935 20.4177C27.2935 16.5496 30.4687 13.4138 34.3856 13.4138L58.8729 13.4138C62.7898 13.4138 65.9651 16.5496 65.9651 20.4177L65.9651 41.9861C65.9651 45.8543 62.7898 48.99 58.8729 48.99L53.7335 48.99C53.1203 48.99 52.5284 49.2123 52.0702 49.6147L47.468 53.6559C46.9942 54.0719 46.2803 54.0723 45.806 53.6569L41.1877 49.6125C40.7298 49.2115 40.139 48.99 39.527 48.99L34.3856 48.99C30.4687 48.99 27.2935 45.8543 27.2935 41.9861L27.2935 20.4177Z"
      fill="url(#paint1_linear_4121_801287)"
    />
    <mask
      id="mask0_4121_801287"
      // style="mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="33"
      y="21"
      width="28"
      height="20"
    >
      <path
        d="M33.0005 24.349C33.0005 23.0516 34.0516 21.9998 35.3483 21.9998L57.6527 21.9998C58.9493 21.9998 60.0005 23.0516 60.0005 24.349L60.0005 38.6404C60.0005 39.9378 58.9493 40.9896 57.6527 40.9896L35.3483 40.9896C34.0516 40.9896 33.0005 39.9378 33.0005 38.6404L33.0005 24.349Z"
        fill="#E4E9F2"
        fill-opacity="0.5"
      />
    </mask>
    <g mask="url(#mask0_4121_801287)">
      <g filter="url(#filter0_b_4121_801287)">
        <path d="M33.0005 21.9998L60.0005 21.9998L60.0005 40.9896L33.0005 40.9896L33.0005 21.9998Z" fill="white" />
      </g>
      <g filter="url(#filter1_b_4121_801287)">
        <path
          d="M31.6304 21.4119L61.3695 21.4119L49.5817 31.7642C48.1495 33.022 46.0201 33.0645 44.5388 31.865L31.6304 21.4119Z"
          fill="url(#paint2_linear_4121_801287)"
        />
      </g>
    </g>
    <ellipse
      rx="3.79453"
      ry="3.79846"
      transform="matrix(-0.681858 0.731485 0.739934 0.67268 49.8077 60.9084)"
      fill="url(#paint3_linear_4121_801287)"
    />
    <ellipse
      rx="2.60874"
      ry="2.61144"
      transform="matrix(-0.681858 0.731485 0.739934 0.67268 46.4494 70.7963)"
      fill="url(#paint4_linear_4121_801287)"
    />
    <ellipse
      rx="1.89727"
      ry="1.89923"
      transform="matrix(-0.681858 0.731485 0.739934 0.67268 39.9456 77.3422)"
      fill="url(#paint5_linear_4121_801287)"
    />
    <defs>
      <filter
        id="filter0_b_4121_801287"
        x="13.0005"
        y="1.99976"
        width="67"
        height="58.99"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4121_801287" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4121_801287" result="shape" />
      </filter>
      <filter
        id="filter1_b_4121_801287"
        x="11.6304"
        y="1.41187"
        width="69.7393"
        height="51.325"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="10" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4121_801287" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4121_801287" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_4121_801287"
        x1="30.742"
        y1="26.3196"
        x2="21.077"
        y2="66.9025"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFEA7C" />
        <stop offset="1" stop-color="#FFD46F" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_4121_801287"
        x1="24.3522"
        y1="33.8014"
        x2="66.0373"
        y2="33.8014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3A83F1" />
        <stop offset="1" stop-color="#3C64B1" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4121_801287"
        x1="46.4999"
        y1="21.4119"
        x2="46.4999"
        y2="33.9413"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#7E9BCE" stop-opacity="0" />
        <stop offset="1" stop-color="white" stop-opacity="0.3" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4121_801287"
        x1="-0.577211"
        y1="3.81914"
        x2="7.60324"
        y2="3.81914"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3A83F1" />
        <stop offset="1" stop-color="#3C64B1" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_4121_801287"
        x1="-0.396833"
        y1="2.62566"
        x2="5.22723"
        y2="2.62566"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3A83F1" />
        <stop offset="1" stop-color="#3C64B1" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_4121_801287"
        x1="-0.288606"
        y1="1.90957"
        x2="3.80162"
        y2="1.90957"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#3A83F1" />
        <stop offset="1" stop-color="#3C64B1" />
      </linearGradient>
    </defs>
  </svg>
);
