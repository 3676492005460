import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

const CompletedOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6666 5C16.6666 3.61929 15.5473 2.5 14.1666 2.5L5.83887 2.5C4.45816 2.5 3.33887 3.61929 3.33887 5V15C3.33887 16.3807 4.45816 17.5 5.83887 17.5H14.1666C15.5473 17.5 16.6666 16.3807 16.6666 15V5ZM14.1666 3.58333H5.83887C5.05646 3.58333 4.4222 4.2176 4.4222 5V15C4.4222 15.7824 5.05646 16.4167 5.83887 16.4167H14.1666C14.949 16.4167 15.5832 15.7824 15.5832 15V5C15.5832 4.2176 14.949 3.58333 14.1666 3.58333Z"
        fill={color}
      />
      <path
        d="M9.40064 12.0127C9.63397 12.246 10.0173 12.2377 10.2423 11.996L13.1763 9.00706C13.3763 8.78205 13.3596 8.44039 13.1429 8.24039C12.9263 8.04039 12.5763 8.04872 12.3763 8.27372L9.80064 10.8794L8.4636 9.63055C8.25527 9.42221 7.90527 9.42221 7.69694 9.63055C7.4886 9.83888 7.4886 10.1889 7.69694 10.3972L9.40064 12.0127Z"
        fill={color}
      />
    </svg>
  );
};

const CompletedSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6666 5C16.6666 3.61929 15.5473 2.5 14.1666 2.5L5.83887 2.5C4.45816 2.5 3.33887 3.61929 3.33887 5V15C3.33887 16.3807 4.45816 17.5 5.83887 17.5H14.1666C15.5473 17.5 16.6666 16.3807 16.6666 15V5ZM14.1666 3.58333H5.83887C5.05646 3.58333 4.4222 4.2176 4.4222 5V15C4.4222 15.7824 5.05646 16.4167 5.83887 16.4167H14.1666C14.949 16.4167 15.5832 15.7824 15.5832 15V5C15.5832 4.2176 14.949 3.58333 14.1666 3.58333Z"
        fill={color}
      />
      <path
        d="M9.40064 12.0127C9.63397 12.246 10.0173 12.2377 10.2423 11.996L13.1763 9.00706C13.3763 8.78205 13.3596 8.44039 13.1429 8.24039C12.9263 8.04039 12.5763 8.04872 12.3763 8.27372L9.80064 10.8794L8.4636 9.63055C8.25527 9.42221 7.90527 9.42221 7.69694 9.63055C7.4886 9.83888 7.4886 10.1889 7.69694 10.3972L9.40064 12.0127Z"
        fill={color}
      />
    </svg>
  );
};

export const CompletedIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={CompletedOutlined}
      solidSvg={CompletedSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
