import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const DescriptionOutline = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.7917 10.4195C13.0908 10.4195 13.3333 10.177 13.3333 9.87788C13.3333 9.57873 13.0908 9.33622 12.7917 9.33622L7.20486 9.33622C6.90571 9.33622 6.6632 9.57873 6.6632 9.87788C6.6632 10.177 6.90571 10.4195 7.20486 10.4196L12.7917 10.4195Z"
        fill={color}
      />
      <path
        d="M13.3333 12.8028C13.3333 13.102 13.0908 13.3445 12.7917 13.3445L7.20486 13.3445C6.90571 13.3445 6.6632 13.102 6.6632 12.8028C6.6632 12.5037 6.90571 12.2612 7.20486 12.2612L12.7917 12.2611C13.0908 12.2611 13.3333 12.5037 13.3333 12.8028Z"
        fill={color}
      />
      <path
        d="M9.45834 7.49465C9.75749 7.49465 10 7.25214 10 6.95298C10 6.65383 9.75749 6.41132 9.45834 6.41132L7.20486 6.41131C6.90571 6.41131 6.6632 6.65382 6.6632 6.95298C6.6632 7.25213 6.90571 7.49464 7.20486 7.49464L9.45834 7.49465Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83334 2.5C4.45262 2.5 3.33334 3.61929 3.33334 5V15C3.33334 16.3807 4.45263 17.5 5.83334 17.5H14.1667C15.5474 17.5 16.6667 16.3807 16.6667 15V7.74975C16.6667 7.05872 16.3806 6.39852 15.8765 5.9259L12.9435 3.17616C12.4801 2.74175 11.8688 2.5 11.2336 2.5H5.83334ZM11.2336 3.58333L5.83334 3.58333C5.05093 3.58333 4.41667 4.2176 4.41667 5L4.41667 15C4.41667 15.7824 5.05093 16.4167 5.83334 16.4167H14.1667C14.9491 16.4167 15.5833 15.7824 15.5833 15L15.5833 7.74975C15.5833 7.66611 15.5759 7.58327 15.5615 7.50209L13.333 7.5021C12.4355 7.5021 11.708 6.77457 11.708 5.8771V3.66511C11.557 3.61145 11.3967 3.58333 11.2336 3.58333ZM12.7913 4.51847L14.8183 6.41876L13.333 6.41877C13.0338 6.41877 12.7913 6.17626 12.7913 5.8771V4.51847Z"
        fill={color}
      />
    </svg>
  );
};

const DescriptionSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33333 5C3.33333 3.61929 4.45262 2.5 5.83333 2.5H11.2336C11.8688 2.5 12.4801 2.74175 12.9435 3.17616L15.8765 5.9259C16.3806 6.39852 16.6667 7.05872 16.6667 7.74975V15C16.6667 16.3807 15.5474 17.5 14.1667 17.5H5.83333C4.45262 17.5 3.33333 16.3807 3.33333 15V5ZM15.1292 7.49999C15.389 7.49999 15.5192 7.18582 15.3354 7.00209L12.1646 3.83124C11.9808 3.6475 11.6667 3.77763 11.6667 4.03748V6.31232C11.6667 6.96826 12.1652 7.5 12.7801 7.5L15.1292 7.49999ZM9.45833 7.49465C9.75749 7.49465 10 7.25214 10 6.95298C10 6.65383 9.75749 6.41132 9.45833 6.41132L7.20486 6.41131C6.90571 6.41131 6.66319 6.65382 6.66319 6.95298C6.66319 7.25213 6.90571 7.49464 7.20486 7.49464L9.45833 7.49465ZM13.3333 9.87788C13.3333 10.177 13.0908 10.4195 12.7917 10.4195L7.20486 10.4196C6.90571 10.4195 6.66319 10.177 6.66319 9.87788C6.66319 9.57873 6.90571 9.33622 7.20486 9.33622L12.7917 9.33622C13.0908 9.33622 13.3333 9.57873 13.3333 9.87788ZM12.7917 13.3445C13.0908 13.3445 13.3333 13.102 13.3333 12.8028C13.3333 12.5037 13.0908 12.2611 12.7917 12.2611L7.20486 12.2612C6.90571 12.2612 6.66319 12.5037 6.66319 12.8028C6.66319 13.102 6.90571 13.3445 7.20486 13.3445H12.7917Z"
        fill={color}
      />
    </svg>
  );
};

export const DescriptionIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={DescriptionOutline}
      solidSvg={DescriptionSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
