import { SvgIcon } from '@mui/material';
import React from 'react';
import { BasicIcon } from '../../../_utils/type';

export const GmailIcon = ({ className }: BasicIcon) => (
  <SvgIcon className={className} viewBox="0 0 150 150">
    <g>
      <path fill="#4CAF50" d="M121.1,57.9L99.1,74.3v35.8h15.4c3.6,0,6.6-2.9,6.6-6.6V57.9z" />
      <path fill="#1E88E5" d="M28.9,57.9l21.9,16.5v35.8H35.5c-3.6,0-6.6-2.9-6.6-6.6V57.9z" />
      <polygon fill="#E53935" points="99.1,46.9 75,65 50.9,46.9 50.9,74.3 75,92.4 99.1,74.3  " />
      <path
        fill="#C62828"
        d="M28.9,49.3v8.6l21.9,16.5V46.9L44,41.8c-1.6-1.2-3.6-1.9-5.7-1.9l0,0C33.1,39.9,28.9,44.1,28.9,49.3z"
      />
      <path
        fill="#FBC02D"
        d="M121.1,49.3v8.6L99.1,74.3V46.9l6.9-5.1c1.6-1.2,3.6-1.9,5.7-1.9l0,0C116.9,39.9,121.1,44.1,121.1,49.3z"
      />
    </g>
  </SvgIcon>
);
