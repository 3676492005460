import React from 'react';

export const MyTargetIntegrationsIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20.5" cy="20" r="19.5" fill="white" stroke="#EFF2F5" />
    <path
      d="M18.5414 27.8167C15.4047 27.2846 13.3764 25.4506 12.4408 22.3872C12.3993 22.2459 12.2639 21.7739 12.195 21.6335C11.5428 20.3032 10.4705 20.2775 9.5854 20.9683C8.92614 21.4828 8.93292 22.2987 9.08293 23.0747C9.10621 23.1951 9.13022 23.28 9.1843 23.4416C9.23838 23.6033 10.2385 25.2391 10.491 25.6748C10.7243 26.0891 10.9846 26.4876 11.2695 26.8657C14.7518 31.4756 21.2787 32.3654 25.8468 28.8514C29.163 26.2748 30.4688 22.7993 29.8496 18.6151C29.8473 18.5154 29.8339 18.4181 29.8137 18.3207C29.6319 17.4852 28.813 16.9554 27.9851 17.1388C27.1571 17.3222 26.6321 18.1486 26.8139 18.9841C26.8341 19.2898 26.8744 19.5932 26.9059 19.8989C27.3546 24.4997 23.0557 28.5865 18.5414 27.8167Z"
      fill="#FA2C38"
    />
    <path
      d="M24.6105 11.9993C24.4804 10.9148 24.9269 9.84154 25.7817 9.17361C26.4167 8.60077 26.9843 7.93963 27.6148 7.35548C27.8504 7.19698 28.1107 7.07698 28.3822 7C28.4943 7.23774 28.5729 7.48906 28.6177 7.74944C28.6492 8.66417 28.6424 9.5789 28.6177 10.4982C28.6177 11.1389 28.8982 11.4219 29.5332 11.4174H32.3288C32.5622 11.4582 32.7888 11.5351 32.9997 11.6438C32.9391 11.8997 32.8381 12.1419 32.6968 12.3616C31.9541 13.1563 31.1621 13.9103 30.4082 14.6914C29.8899 15.2371 29.1405 15.4907 28.4024 15.3684C27.3703 15.2099 26.3359 15.6401 25.7144 16.4892C24.9201 17.4084 23.989 18.2031 23.1543 19.0975C22.9188 19.3669 22.7909 19.7156 22.7909 20.0734C22.9232 21.8553 21.5972 23.4062 19.8337 23.5375C18.0679 23.6711 16.531 22.333 16.4008 20.5534C16.3918 20.4424 16.3896 20.3292 16.3941 20.2183C16.4547 18.4658 17.9131 17.0937 19.6497 17.1548C19.7372 17.1571 19.8269 17.1639 19.9145 17.1752C20.3587 17.2499 20.8119 17.0846 21.1081 16.7405C21.9629 15.8416 22.8223 14.9427 23.7444 14.1118C24.4153 13.5344 24.8259 12.914 24.6105 11.9993Z"
      fill="#FA2C38"
    />
    <path
      d="M10.2012 15.9504C10.2887 15.6765 10.3987 15.4116 10.5288 15.1557C12.7456 11.3044 17.1185 9.26659 21.4623 10.0636C22.5213 10.2696 23.1204 10.9942 22.9678 11.893C22.8153 12.7919 22.0098 13.3285 20.9193 13.127C17.933 12.5746 14.9152 13.9195 13.3087 16.521C13.1763 16.7678 13.0215 17.001 12.8488 17.2184C12.4247 17.7233 11.7179 17.8795 11.1234 17.5965C10.5131 17.3361 10.19 16.8357 10.2012 15.9504Z"
      fill="#FA2C38"
    />
  </svg>
);
