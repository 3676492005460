import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const LockIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.65 13.6418C12.65 13.2828 12.359 12.9918 12 12.9918C11.641 12.9918 11.35 13.2828 11.35 13.6418V15.3583C11.35 15.7173 11.641 16.0083 12 16.0083C12.359 16.0083 12.65 15.7173 12.65 15.3583V13.6418Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.0156 7.08436L8.01561 9.00008H8C6.34315 9.00008 5 10.3432 5 12.0001V17.0001C5 18.6569 6.34315 20.0001 8 20.0001H16C17.6569 20.0001 19 18.6569 19 17.0001V12.0001C19 10.3432 17.6569 9.00008 16 9.00008H15.9602L15.9602 7.08435C15.9602 4.89051 14.1817 3.11206 11.9879 3.11206C9.79406 3.11206 8.0156 4.89052 8.0156 7.08436ZM11.9879 4.44206C10.5286 4.44206 9.3456 5.62506 9.3456 7.08435L9.34561 9.00008H14.6302L14.6302 7.08435C14.6302 5.62505 13.4472 4.44206 11.9879 4.44206ZM16 10.3001H8C7.06112 10.3001 6.3 11.0612 6.3 12.0001V17.0001C6.3 17.939 7.06112 18.7001 8 18.7001H16C16.9389 18.7001 17.7 17.939 17.7 17.0001V12.0001C17.7 11.0612 16.9389 10.3001 16 10.3001Z"
        fill={color}
      />
    </svg>
  );
};

const LockIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.01561 9.00008L8.0156 7.08436C8.0156 4.89052 9.79406 3.11206 11.9879 3.11206C14.1817 3.11206 15.9602 4.89051 15.9602 7.08435L15.9602 9.00008H16C17.6569 9.00008 19 10.3432 19 12.0001V17.0001C19 18.6569 17.6569 20.0001 16 20.0001H8C6.34315 20.0001 5 18.6569 5 17.0001V12.0001C5 10.3432 6.34315 9.00008 8 9.00008H8.01561ZM9.3456 7.08435C9.3456 5.62506 10.5286 4.44206 11.9879 4.44206C13.4472 4.44206 14.6302 5.62505 14.6302 7.08435L14.6302 9.00008H9.34561L9.3456 7.08435ZM12 12.9918C12.359 12.9918 12.65 13.2828 12.65 13.6418V15.3583C12.65 15.7173 12.359 16.0083 12 16.0083C11.641 16.0083 11.35 15.7173 11.35 15.3583V13.6418C11.35 13.2828 11.641 12.9918 12 12.9918Z"
        fill={color}
      />
    </svg>
  );
};

export const LockIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={LockIconOutlined}
      solidSvg={LockIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
