import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const UsdOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.2618 7.4294C10.087 7.71578 9.37368 8.6867 9.31712 9.74095C9.24829 11.0238 10.1414 12.2697 11.8851 12.559C12.6587 12.6874 13.1309 12.9635 13.3954 13.244C13.6554 13.5197 13.7433 13.8265 13.7176 14.1079C13.6673 14.6591 13.1259 15.3295 11.9915 15.3295C11.3869 15.3295 11.0101 15.0807 10.7721 14.798C10.5086 14.4848 10.4305 14.1483 10.4305 14.0552C10.4305 13.6962 10.1395 13.4052 9.78055 13.4052C9.42156 13.4052 9.13055 13.6962 9.13055 14.0552C9.13055 14.4931 9.34305 15.1188 9.7775 15.635C10.1198 16.0417 10.6107 16.3937 11.2618 16.5473V17.3528C11.2618 17.7118 11.5528 18.0028 11.9118 18.0028C12.2708 18.0028 12.5618 17.7118 12.5618 17.3528V16.5871C13.946 16.3762 14.9047 15.4044 15.0122 14.226C15.0725 13.5649 14.8508 12.8926 14.3412 12.3521C13.8361 11.8165 13.0786 11.4393 12.0979 11.2766C10.9436 11.085 10.5853 10.3692 10.6152 9.81059C10.6466 9.22668 11.0936 8.6444 11.9915 8.6444C12.5777 8.6444 12.9056 8.8796 13.1062 9.14925C13.3287 9.44845 13.414 9.82315 13.414 10.0594C13.414 10.4183 13.705 10.7094 14.064 10.7094C14.423 10.7094 14.714 10.4183 14.714 10.0594C14.714 9.58749 14.563 8.9297 14.1493 8.37345C13.8005 7.90443 13.277 7.52681 12.5618 7.39487V6.66301C12.5618 6.30402 12.2708 6.01301 11.9118 6.01301C11.5528 6.01301 11.2618 6.30402 11.2618 6.66301V7.4294Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM4.3 12C4.3 7.74741 7.74741 4.3 12 4.3C16.2526 4.3 19.7 7.74741 19.7 12C19.7 16.2526 16.2526 19.7 12 19.7C7.74741 19.7 4.3 16.2526 4.3 12Z"
        fill={color}
      />
    </svg>
  );
};

const UsdSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12.5618 7.39488V6.66302C12.5618 6.30403 12.2708 6.01302 11.9118 6.01302C11.5528 6.01302 11.2618 6.30403 11.2618 6.66302V7.4294C10.087 7.71578 9.37368 8.68669 9.31712 9.74095C9.24829 11.0238 10.1414 12.2697 11.8851 12.559C12.6587 12.6874 13.1309 12.9635 13.3954 13.244C13.6554 13.5197 13.7433 13.8265 13.7176 14.1079C13.6673 14.6591 13.1259 15.3295 11.9915 15.3295C11.3869 15.3295 11.0101 15.0807 10.7721 14.798C10.5086 14.4848 10.4305 14.1483 10.4305 14.0552C10.4305 13.6962 10.1395 13.4052 9.78055 13.4052C9.42156 13.4052 9.13055 13.6962 9.13055 14.0552C9.13055 14.4931 9.34305 15.1188 9.7775 15.635C10.1198 16.0417 10.6107 16.3937 11.2618 16.5473V17.3528C11.2618 17.7118 11.5528 18.0028 11.9118 18.0028C12.2708 18.0028 12.5618 17.7118 12.5618 17.3528V16.5871C13.946 16.3762 14.9047 15.4044 15.0122 14.226C15.0725 13.5649 14.8508 12.8926 14.3412 12.3521C13.8361 11.8165 13.0786 11.4393 12.0979 11.2766C10.9436 11.085 10.5853 10.3692 10.6152 9.81059C10.6466 9.22668 11.0936 8.6444 11.9915 8.6444C12.5777 8.6444 12.9056 8.8796 13.1062 9.14925C13.3287 9.44845 13.414 9.82315 13.414 10.0594C13.414 10.4183 13.705 10.7094 14.064 10.7094C14.423 10.7094 14.714 10.4183 14.714 10.0594C14.714 9.58749 14.563 8.9297 14.1493 8.37345C13.8005 7.90444 13.277 7.52681 12.5618 7.39488Z"
        fill={color}
      />
    </svg>
  );
};

export const UsdIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={UsdOutlined}
      solidSvg={UsdSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
