import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const PinIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.9266 9.7953L15.1683 9.7916L11.1474 13.8124L11.1581 15.5637C11.0522 16.4625 9.95618 16.8429 9.31623 16.203L6.94807 13.8348L3.19568 17.5872C2.98415 17.7987 2.64118 17.7987 2.42965 17.5872C2.21811 17.3757 2.21811 17.0327 2.42965 16.8212L6.18204 13.0688L3.79698 10.6837C3.15703 10.0438 3.53739 8.94773 4.43619 8.84178L6.18749 8.85252L10.2083 4.83168L10.2046 3.07334C10.3106 2.17454 11.4066 1.79417 12.0466 2.43412L17.5658 7.95338C18.2058 8.59333 17.8254 9.68935 16.9266 9.7953ZM10.074 15.4286L4.5713 9.92597L6.63347 9.9386L6.63568 9.93639L10.0635 13.3643L10.0613 13.3665L10.074 15.4286ZM10.5203 6.0518L7.40171 9.17036L10.8296 12.5982L13.9481 9.47966L10.5203 6.0518ZM11.2863 5.28577L11.2926 5.27947L11.2883 3.20789L16.792 8.71168L14.7205 8.70733L14.7142 8.71363L11.2863 5.28577Z"
        fill={color}
      />
    </svg>
  );
};

const PinIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0466 2.43412C11.4066 1.79417 10.3106 2.17454 10.2046 3.07334L10.2083 4.83168L6.18749 8.85252L4.43619 8.84178C3.53739 8.94773 3.15703 10.0438 3.79698 10.6837L6.18204 13.0688L2.42965 16.8212C2.21811 17.0327 2.21811 17.3757 2.42965 17.5872C2.64118 17.7987 2.98415 17.7987 3.19568 17.5872L6.94807 13.8348L9.31623 16.203C9.95618 16.8429 11.0522 16.4625 11.1581 15.5637L11.1474 13.8124L15.1683 9.7916L16.9266 9.7953C17.8254 9.68935 18.2058 8.59333 17.5658 7.95338L12.0466 2.43412Z"
        fill={color}
      />
    </svg>
  );
};

export const PinIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={PinIconOutlined}
      solidSvg={PinIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
