import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const ChevronLeftOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5082 18.7543C14.6882 18.7543 14.8582 18.6843 14.9882 18.5443C15.2282 18.2743 15.2082 17.8643 14.9482 17.6243L8.57906 11.884L14.9882 6.33399C15.2582 6.09399 15.2682 5.68398 15.0282 5.41398C14.7882 5.15398 14.3782 5.13399 14.1082 5.37399L7.20906 11.364C6.89906 11.644 6.89906 12.124 7.20906 12.404L14.0682 18.5843C14.1982 18.7043 14.3482 18.7543 14.5082 18.7543Z"
        fill={color}
      />
    </svg>
  );
};

const ChevronLeftSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM14.1863 7.60592C14.0563 7.46592 13.8863 7.39592 13.7063 7.39592C13.5463 7.39592 13.3963 7.44592 13.2663 7.56592L9.22631 11.4896C8.91631 11.7696 8.91631 12.2496 9.22631 12.5296L13.2863 16.4359C13.5563 16.6759 13.9663 16.6559 14.2063 16.3959C14.4463 16.1259 14.4363 15.7159 14.1663 15.4759L10.5927 12.0024L14.1463 8.52592C14.4063 8.28592 14.4263 7.87592 14.1863 7.60592Z"
        fill={color}
      />
    </svg>
  );
};

export const ChevronLeftIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={ChevronLeftOutlined}
      solidSvg={ChevronLeftSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
