import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const TimeOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.65 8.15343C12.65 7.79445 12.359 7.50344 12 7.50344C11.641 7.50344 11.35 7.79445 11.35 8.15343V12.6534C11.35 13.0124 11.641 13.3034 12 13.3034H16C16.359 13.3034 16.65 13.0124 16.65 12.6534C16.65 12.2945 16.359 12.0034 16 12.0034H12.65V8.15343Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12 4.3C7.74741 4.3 4.3 7.74741 4.3 12C4.3 16.2526 7.74741 19.7 12 19.7C16.2526 19.7 19.7 16.2526 19.7 12C19.7 7.74741 16.2526 4.3 12 4.3Z"
        fill={color}
      />
    </svg>
  );
};

const TimeSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM12 7.50342C12.359 7.50342 12.65 7.79443 12.65 8.15342V12.0034H16C16.359 12.0034 16.65 12.2944 16.65 12.6534C16.65 13.0124 16.359 13.3034 16 13.3034H12C11.641 13.3034 11.35 13.0124 11.35 12.6534V8.15342C11.35 7.79443 11.641 7.50342 12 7.50342Z"
        fill={color}
      />
    </svg>
  );
};

export const TimeIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={TimeOutlined}
      solidSvg={TimeSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
