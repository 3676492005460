import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const SaveOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.48076 13.6667H11.1668C12.5475 13.6667 13.6668 12.5474 13.6668 11.1667V5.53557C13.6668 4.87253 13.4034 4.23665 12.9346 3.76781L10.2324 1.06561C9.76355 0.596766 9.12767 0.333374 8.46463 0.333374H2.82744C1.44951 0.336642 0.333496 1.45468 0.333496 2.83337V11.1667C0.333496 12.5474 1.45278 13.6667 2.8335 13.6667H3.27673C3.30977 13.673 3.34387 13.6763 3.37874 13.6763C3.41362 13.6763 3.44772 13.673 3.48076 13.6667ZM11.1754 12.5833V8.41673C11.1754 7.7954 10.6718 7.29172 10.0504 7.29173L3.96207 7.29174C3.34075 7.29174 2.83708 7.79542 2.83708 8.41674V12.5834H2.8335C2.05109 12.5834 1.41683 11.9491 1.41683 11.1667V2.83337C1.41683 2.05299 2.04782 1.41998 2.82744 1.41672V3.91669C2.82744 4.53801 3.33112 5.04169 3.95244 5.04169H7.54044C8.16176 5.04169 8.66544 4.53801 8.66544 3.91669V1.43101C8.96693 1.47417 9.24848 1.61376 9.46636 1.83164L12.1686 4.53384C12.4342 4.79952 12.5835 5.15985 12.5835 5.53557V11.1667C12.5835 11.9462 11.9539 12.5787 11.1754 12.5833ZM10.0921 12.5834V8.41673C10.0921 8.39371 10.0735 8.37506 10.0504 8.37506L3.96208 8.37507C3.93907 8.37507 3.92041 8.39373 3.92041 8.41674V12.5834H10.0921ZM7.58211 3.91669V1.41671H3.91077V3.91669C3.91077 3.9397 3.92943 3.95836 3.95244 3.95836H7.54044C7.56345 3.95836 7.58211 3.9397 7.58211 3.91669Z"
        fill={color}
      />
    </svg>
  );
};

const SaveSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 14 14" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.8335 0.333374C1.45278 0.333374 0.333496 1.45266 0.333496 2.83337V11.1667C0.333496 12.5474 1.45278 13.6667 2.8335 13.6667H11.1668C12.5475 13.6667 13.6668 12.5474 13.6668 11.1667V5.53557C13.6668 4.87253 13.4034 4.23665 12.9346 3.76781L10.2324 1.06561C9.76355 0.596766 9.12767 0.333374 8.46463 0.333374H2.8335ZM3.07438 2.00822C3.07438 1.68605 3.33555 1.42489 3.65771 1.42489H7.53851C7.86067 1.42489 8.12184 1.68605 8.12184 2.00822V4.2064C8.12184 4.52856 7.86067 4.78973 7.53851 4.78973H3.65772C3.33555 4.78973 3.07438 4.52856 3.07438 4.2064V2.00822ZM3.09391 8.12374C3.09391 7.80157 3.35508 7.54041 3.67725 7.54041H9.50928C9.83144 7.54041 10.0926 7.80157 10.0926 8.12374V11.9984C10.0926 12.3205 9.83144 12.5817 9.50928 12.5817H3.67725C3.35508 12.5817 3.09391 12.3205 3.09391 11.9984V8.12374Z"
        fill={color}
      />
    </svg>
  );
};

export const SaveIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={SaveOutlined}
      solidSvg={SaveSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
