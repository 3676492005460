type TextAvatarColor = 'FFFFFF' | '2b313b';

export const colorTextAvatar = (hexBG: string): TextAvatarColor => {
  const colorsToWhiteText = [
    'FEAFD5',
    'EE5D80',
    'ED6CCD',
    'EEAEE8',
    'CE5DE2',
    'BEB0FA',
    '845CF7',
    'ACB4F6',
    '5671FF',
    'AACCFF',
    '46A1FF',
    '99DDE4',
    '17C5D1',
    '24A79F',
    'A1DFA8',
    '3DC453',
    'D6E994',
    'D6E994',
    'BEDA13',
    'FFDBA9',
    'FFC040',
    'FDC3AA',
    'FC8D46',
    'F3ABAB',
    'EA4B4A',
  ];

  return colorsToWhiteText.includes(hexBG.toLocaleUpperCase()) ? '2b313b' : 'FFFFFF';
};
