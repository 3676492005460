import React from 'react';
import icon from '../../assets/icons/workflow-item-icon.png';

interface IWorkFlowIcon {
  icon?: React.ReactNode;
}

export const WorkflowItemIcon: React.VFC<IWorkFlowIcon> = function WorkflowItemIcon() {
  return (
    <img
      src={icon}
      alt=""
      style={{
        width: '32px',
        height: '32px',
        borderRadius: '50%',
      }}
    />
  );
};
