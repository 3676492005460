import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

export const CheckClearOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5038 16.5466C10.7838 16.8266 11.2438 16.8166 11.5138 16.5266L17.9908 9.54117C18.2308 9.27117 18.2108 8.86117 17.9508 8.62117C17.6908 8.38117 17.2708 8.39117 17.0308 8.66117L10.9838 15.1866L7.13899 11.3914C6.88899 11.1414 6.46899 11.1414 6.21899 11.3914C5.96899 11.6414 5.96899 12.0614 6.21899 12.3114L10.5038 16.5466Z"
        fill={color}
      />
    </svg>
  );
};

export const CheckClearIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={CheckClearOutlined}
      solidSvg={CheckClearOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
