import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const EyeOffOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.38181 2.20689C4.66633 1.98798 5.07443 2.04117 5.29334 2.32569L8.01359 5.86127C9.18902 5.3362 10.5197 4.99998 12 4.99998C16.8808 4.99998 20.1338 8.65462 21.5632 10.6825C22.1262 11.4812 22.1262 12.5187 21.5632 13.3174C20.7028 14.5382 19.1815 16.3483 17.0422 17.596L19.5944 20.9131C19.8133 21.1976 19.7601 21.6057 19.4756 21.8246C19.191 22.0435 18.7829 21.9903 18.564 21.7058L4.26301 3.11842C4.0441 2.8339 4.09729 2.4258 4.38181 2.20689ZM15.9203 16.1378C17.0164 15.099 17.7 13.6293 17.7 12C17.7 8.85195 15.1481 6.29998 12 6.29998C10.9013 6.29998 9.87512 6.61087 9.00474 7.14949L10.6245 9.25474C11.02 9.06756 11.4621 8.96287 11.9287 8.96287C13.6144 8.96287 14.981 10.3294 14.981 12.0151C14.981 12.198 14.9649 12.3771 14.9341 12.5511C14.9356 12.5974 14.9309 12.6451 14.9194 12.6939C14.8404 13.0274 14.7058 13.3393 14.5259 13.6193C14.4468 13.747 14.3586 13.8685 14.2622 13.9827L15.9203 16.1378ZM5.76496 7.19868C4.24441 8.34247 3.12767 9.70247 2.43688 10.6825C1.8739 11.4812 1.8739 12.5187 2.43688 13.3174C3.86626 15.3453 7.11931 19 12 19C12.916 19 13.7746 18.8713 14.5746 18.6488L13.6565 17.4555C13.1323 17.6145 12.5762 17.7 12 17.7C8.85202 17.7 6.30004 15.148 6.30004 12C6.30004 10.8997 6.61178 9.87226 7.15176 9.00113L5.76496 7.19868ZM11.8175 15.0654L11.7965 15.0645C11.7801 15.0287 11.7603 14.9939 11.7369 14.9606L11.8175 15.0654ZM5.70874 8.92726C4.76185 9.77766 4.02109 10.6914 3.49945 11.4315C3.25305 11.7811 3.25305 12.2189 3.49945 12.5685C4.02109 13.3085 4.76185 14.2223 5.70873 15.0727C5.25483 14.1451 5.00004 13.1023 5.00004 12C5.00004 10.8977 5.25483 9.85487 5.70874 8.92726ZM18.2913 8.92726C19.2382 9.77766 19.979 10.6914 20.5006 11.4315C20.747 11.7811 20.747 12.2189 20.5006 12.5685C19.979 13.3085 19.2382 14.2223 18.2913 15.0727C18.7452 14.1451 19 13.1023 19 12C19 10.8977 18.7452 9.85486 18.2913 8.92726Z"
        fill={color}
      />
    </svg>
  );
};

const EyeOffSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.38181 2.20689C4.66633 1.98798 5.07443 2.04117 5.29334 2.32569L8.05362 5.91329C9.21156 5.35851 10.529 4.99998 12 4.99998C16.7246 4.99998 19.8642 8.6982 21.2268 10.7185C21.7548 11.5014 21.7548 12.4986 21.2268 13.2814C20.4205 14.4769 18.992 16.2599 16.9811 17.5165L19.5944 20.9131C19.8133 21.1976 19.7601 21.6057 19.4756 21.8246C19.191 22.0435 18.7829 21.9903 18.564 21.7058L4.26301 3.11842C4.0441 2.8339 4.09729 2.4258 4.38181 2.20689ZM15.2758 15.3002C16.1245 14.4578 16.65 13.2902 16.65 12C16.65 9.43183 14.5681 7.34995 12 7.34995C11.1424 7.34995 10.3389 7.58214 9.64919 7.98709L10.6245 9.25474C11.02 9.06756 11.4621 8.96287 11.9287 8.96287C13.6144 8.96287 14.981 10.3294 14.981 12.0151C14.981 12.198 14.9649 12.3772 14.934 12.5512C14.9355 12.5974 14.9309 12.6452 14.9193 12.6939C14.8405 13.027 14.7061 13.3386 14.5265 13.6184C14.4473 13.7464 14.3589 13.8682 14.2622 13.9827L15.2758 15.3002ZM5.85411 7.31454C4.44756 8.44757 3.41394 9.76853 2.77321 10.7185C2.24519 11.5014 2.24519 12.4986 2.77321 13.2814C4.13585 15.3018 7.27545 19 12 19C12.9117 19 13.7644 18.8623 14.5567 18.6255L12.9603 16.5507C12.6505 16.6157 12.3292 16.65 12 16.65C9.43189 16.65 7.35002 14.5681 7.35002 12C7.35002 11.2464 7.52925 10.5348 7.8474 9.90528L5.85411 7.31454Z"
        fill={color}
      />
    </svg>
  );
};

export const EyeOffIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={EyeOffOutlined}
      solidSvg={EyeOffSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
