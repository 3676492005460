import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

const VKIconCommon = ({ color, size }: TSvgProps) => {
  return (
    <div
      style={{
        width: `${size}`,
        height: `${size}`,
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3618_19338)">
          <mask
            id="mask0_3618_19338"
            style={{ maskType: 'luminance' }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="20"
            height="20"
          >
            <path d="M19.9016 0H0.0996094V20H19.9016V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_3618_19338)">
            <path
              d="M0.0996094 9.6C0.0996094 5.07452 0.0996094 2.81178 1.49158 1.40589C2.88355 0 5.12389 0 9.60456 0H10.3966C14.8773 0 17.1176 0 18.5096 1.40589C19.9016 2.81178 19.9016 5.07452 19.9016 9.6V10.4C19.9016 14.9255 19.9016 17.1882 18.5096 18.5941C17.1176 20 14.8773 20 10.3966 20H9.60456C5.12389 20 2.88355 20 1.49158 18.5941C0.0996094 17.1882 0.0996094 14.9255 0.0996094 10.4V9.6Z"
              fill="#0077FF"
            />
            <path
              d="M10.6361 14.4084C6.12291 14.4084 3.54867 11.2834 3.44141 6.08337H5.70214C5.7764 9.90003 7.44303 11.5167 8.76315 11.85V6.08337H10.8919V9.37501C12.1956 9.23335 13.565 7.73337 14.0271 6.08337H16.1559C15.8011 8.11671 14.3159 9.61669 13.2598 10.2334C14.3159 10.7334 16.0074 12.0417 16.651 14.4084H14.3077C13.8044 12.825 12.5504 11.6 10.8919 11.4334V14.4084H10.6361Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_3618_19338">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export const VKIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={VKIconCommon}
      solidSvg={VKIconCommon}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
