import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

export const ShopCartOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.95825 3.33341C1.95825 3.03426 2.20076 2.79175 2.49992 2.79175H3.28196C4.29529 2.79175 5.17859 3.4814 5.42436 4.46448L5.83951 6.12508H16.6666C16.8389 6.12508 17.0009 6.20707 17.103 6.3459C17.2051 6.48473 17.235 6.66384 17.1836 6.82831L15.4657 12.3254C15.1776 13.2474 14.3238 13.8751 13.3579 13.8751H8.38455C7.37121 13.8751 6.48792 13.1854 6.24215 12.2023L4.37337 4.72723C4.24816 4.22642 3.79818 3.87508 3.28196 3.87508H2.49992C2.20076 3.87508 1.95825 3.63257 1.95825 3.33341ZM6.11034 7.20841L7.29314 11.9396C7.41834 12.4404 7.86832 12.7917 8.38455 12.7917H13.3579C13.85 12.7917 14.285 12.472 14.4317 12.0023L15.9298 7.20841H6.11034Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.12492 15.8334C6.12492 15.074 6.74053 14.4584 7.49992 14.4584C8.25931 14.4584 8.87492 15.074 8.87492 15.8334C8.87492 16.5928 8.25931 17.2084 7.49992 17.2084C6.74053 17.2084 6.12492 16.5928 6.12492 15.8334Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1666 14.4584C13.4072 14.4584 12.7916 15.074 12.7916 15.8334C12.7916 16.5928 13.4072 17.2084 14.1666 17.2084C14.926 17.2084 15.5416 16.5928 15.5416 15.8334C15.5416 15.074 14.926 14.4584 14.1666 14.4584Z"
        fill={color}
      />
    </svg>
  );
};

const ShopCartSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.50004 2.79175C2.20089 2.79175 1.95837 3.03426 1.95837 3.33341C1.95837 3.63257 2.20089 3.87508 2.50004 3.87508H3.28208C3.79831 3.87508 4.24829 4.22642 4.37349 4.72723L6.24227 12.2023C6.48804 13.1854 7.37134 13.8751 8.38467 13.8751H13.3581C14.3239 13.8751 15.1778 13.2474 15.4659 12.3254L17.1837 6.82831C17.2351 6.66384 17.2052 6.48473 17.1031 6.3459C17.0011 6.20707 16.839 6.12508 16.6667 6.12508H5.83963L5.42448 4.46448C5.17871 3.4814 4.29541 2.79175 3.28208 2.79175H2.50004Z"
        fill={color}
      />
      <path
        d="M6.12504 15.8334C6.12504 15.074 6.74065 14.4584 7.50004 14.4584C8.25943 14.4584 8.87504 15.074 8.87504 15.8334C8.87504 16.5928 8.25943 17.2084 7.50004 17.2084C6.74065 17.2084 6.12504 16.5928 6.12504 15.8334Z"
        fill={color}
      />
      <path
        d="M12.7917 15.8334C12.7917 15.074 13.4073 14.4584 14.1667 14.4584C14.9261 14.4584 15.5417 15.074 15.5417 15.8334C15.5417 16.5928 14.9261 17.2084 14.1667 17.2084C13.4073 17.2084 12.7917 16.5928 12.7917 15.8334Z"
        fill={color}
      />
    </svg>
  );
};

export const ShopCartIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={ShopCartOutlined}
      solidSvg={ShopCartSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
