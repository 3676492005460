import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const EditOutline = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.0454 3.88369C16.5531 3.37601 17.3762 3.37601 17.8839 3.88369L19.5809 5.58075C20.0886 6.08843 20.0886 6.91154 19.5809 7.41922L9.10856 17.8916C8.71098 18.2892 8.20968 18.567 7.66182 18.6934L5.93071 19.0929C4.99462 19.309 4.15566 18.47 4.37168 17.5339L4.77117 15.8028C4.8976 15.2549 5.17545 14.7536 5.57302 14.3561L16.0454 3.88369ZM16.9646 4.80293L18.6617 6.49999L17.0405 8.12112L15.3435 6.42407L16.9646 4.80293ZM14.4242 7.34331L6.49226 15.2753C6.26697 15.5006 6.10952 15.7847 6.03788 16.0951L5.63839 17.8262L7.3695 17.4267C7.67995 17.3551 7.96402 17.1976 8.18932 16.9724L16.1213 9.04036L14.4242 7.34331Z"
        fill={color}
      />
    </svg>
  );
};

const EditSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8838 3.88369C17.3761 3.37601 16.553 3.37601 16.0453 3.88369L14.9126 5.01638C14.6418 5.28718 14.6389 5.72532 14.9061 5.9997L16.9244 8.07252C17.1967 8.35211 17.645 8.3551 17.9209 8.07915L19.0404 6.95964C19.5481 6.45196 19.5481 5.62884 19.0404 5.12116L17.8838 3.88369Z"
        fill={color}
      />
      <path
        d="M16.0116 9.98843C16.2824 9.71763 16.2853 9.27949 16.0182 9.00511L13.9998 6.93229C13.7276 6.6527 13.2793 6.64972 13.0033 6.92566L5.57294 14.3561C5.17537 14.7536 4.89752 15.2549 4.77109 15.8028L4.3716 17.5339C4.15558 18.47 4.45413 18.8494 5.39022 18.6333L7.12132 18.2339C7.66918 18.1074 8.17049 17.8296 8.56807 17.432L16.0116 9.98843Z"
        fill={color}
      />
    </svg>
  );
};

export const EditIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={EditOutline}
      solidSvg={EditSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
