import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const SourceIconOutlined = ({ color = '#626F84', size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.783 13.5719C12.633 13.5719 12.4913 13.5135 12.383 13.3969C12.183 13.1719 12.1996 12.8302 12.4163 12.6302L15.3273 10.0083L12.4196 7.49348C12.1946 7.29348 12.1862 6.95181 12.3862 6.72681C12.5862 6.51015 12.9279 6.49348 13.1529 6.69348L16.4689 9.57498C16.7273 9.80832 16.7273 10.2083 16.4689 10.4417L13.1496 13.4302C13.0413 13.5302 12.9163 13.5719 12.783 13.5719Z"
        fill={color}
      />
      <path
        d="M7.24713 6.49072C7.39713 6.49072 7.53879 6.54905 7.64712 6.66572C7.84712 6.89072 7.83046 7.23239 7.61379 7.43239L4.70284 10.0543L7.61054 12.5691C7.83554 12.7691 7.84387 13.1108 7.64388 13.3358C7.44387 13.5524 7.10221 13.5691 6.87721 13.3691L3.56118 10.4876C3.30285 10.2543 3.30285 9.85427 3.56118 9.62094L6.88046 6.63239C6.98879 6.53239 7.11379 6.49072 7.24713 6.49072Z"
        fill={color}
      />
      <path
        d="M10.8493 5.75822C11.1383 5.83563 11.3098 6.13266 11.2323 6.42162L9.81243 13.9553C9.73499 14.2442 9.438 14.4157 9.14903 14.3383C8.86006 14.2609 8.68856 13.9638 8.76601 13.6749L10.1859 6.14123C10.2634 5.85227 10.5603 5.68081 10.8493 5.75822Z"
        fill={color}
      />
    </svg>
  );
};

export const SourceIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={SourceIconOutlined}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
