import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const SortUpOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2309 12.9445L12.0032 10.5329L9.77245 12.9445H14.2309ZM12.5177 9.174C12.2407 8.87411 11.7669 8.87395 11.4896 9.17364L7.88625 13.0692C7.47164 13.5174 7.78954 14.2445 8.40012 14.2445H15.602C16.2123 14.2445 16.5303 13.5179 16.1162 13.0695L12.5177 9.174Z"
        fill={color}
      />
    </svg>
  );
};

const SortUpSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4896 9.17364C11.7669 8.87395 12.2407 8.87411 12.5177 9.174L16.1162 13.0695C16.5303 13.5179 16.2123 14.2445 15.602 14.2445H8.40012C7.78954 14.2445 7.47164 13.5174 7.88625 13.0692L11.4896 9.17364Z"
        fill={color}
      />
    </svg>
  );
};

export const SortUpIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={SortUpOutlined}
      solidSvg={SortUpSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
