import React from 'react';

export const RubSingleIcon = () => (
  <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 6.13608H1.17113V0.247422C1.589 0.148453 2.03436 0.0824738 2.50722 0.0494839C2.99107 0.0164946 3.46942 0 3.94227 0C4.44811 0 4.94296 0.0494842 5.4268 0.148453C5.92165 0.247422 6.35601 0.428865 6.7299 0.692783C7.11478 0.956701 7.42268 1.31409 7.65361 1.76495C7.88454 2.21581 8 2.79313 8 3.49691C8 4.18969 7.87904 4.76701 7.63711 5.22887C7.40619 5.69072 7.09278 6.0646 6.69691 6.35051C6.31203 6.63643 5.86667 6.83986 5.36082 6.96082C4.86598 7.08179 4.36014 7.14777 3.8433 7.15876L2.54021 7.17526V8.34639H5.27835V9.36907H2.54021V11.9792H1.17113V9.36907H0V8.34639H1.17113V7.15876H0V6.13608ZM3.97526 1.15464C3.68935 1.15464 3.41443 1.16564 3.15052 1.18763C2.89759 1.19862 2.69416 1.22062 2.54021 1.25361V6.03711H3.79381C4.12371 6.03711 4.45361 6.00412 4.78351 5.93814C5.1134 5.87216 5.41031 5.7457 5.67423 5.55876C5.93814 5.37182 6.15258 5.1189 6.31753 4.8C6.48247 4.4701 6.55945 4.04124 6.54845 3.5134C6.55945 3.06254 6.49347 2.68866 6.35052 2.39175C6.21856 2.08385 6.03161 1.84192 5.78969 1.66598C5.55876 1.47904 5.28385 1.34708 4.96495 1.2701C4.65704 1.19313 4.32715 1.15464 3.97526 1.15464Z"
      fill="#626F84"
    />
  </svg>
);
