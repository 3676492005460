import React from 'react';
import { IIconProps } from './icons-kit/_utils';

const CircleCheckedHover = ({ className }: IIconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="4" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8ZM7.1933 10.1933C7.37997 10.3799 7.68663 10.3733 7.86663 10.1799L10.8133 6.97992C10.9733 6.79992 10.96 6.52659 10.7866 6.36659C10.6133 6.20659 10.3333 6.21326 10.1733 6.39326L7.5133 9.28659L5.9133 7.68659C5.74663 7.51992 5.46663 7.51992 5.29997 7.68659C5.1333 7.85326 5.1333 8.13326 5.29997 8.29992L7.1933 10.1933Z"
        fill="#214ECB"
      />
    </svg>
  );
};

const CircleChecked = ({ className }: IIconProps) => {
  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="4" fill="#214ECB" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8ZM7.1933 10.1933C7.37997 10.3799 7.68663 10.3733 7.86663 10.1799L10.8133 6.97992C10.9733 6.79992 10.96 6.52659 10.7866 6.36659C10.6133 6.20659 10.3333 6.21326 10.1733 6.39326L7.5133 9.28659L5.9133 7.68659C5.74663 7.51992 5.46663 7.51992 5.29997 7.68659C5.1333 7.85326 5.1333 8.13326 5.29997 8.29992L7.1933 10.1933Z"
        fill="#F4F6FB"
      />
    </svg>
  );
};

interface ICircleCheckedIcon extends IIconProps {
  isHover: boolean;
}

export const CircleCheckedIcon = ({ className, isHover }: ICircleCheckedIcon) => {
  return <>{isHover ? <CircleChecked className={className} /> : <CircleCheckedHover className={className} />}</>;
};
