import React from 'react';

export const VkAdsIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 9.50792C0 5.02636 0 2.78119 1.39279 1.39279C2.78559 0.00439366 5.02636 0 9.50792 0H10.4921C14.9737 0 17.2144 0 18.6072 1.39279C20 2.78559 20 5.02636 20 9.50792V10.4921C20 14.9737 20 17.2144 18.6072 18.6072C17.2144 20 14.9737 20 10.4921 20H9.50792C5.02636 20 2.78559 20 1.39279 18.6072C0 17.2144 0 14.9737 0 10.4921V9.50792Z"
      fill="#0077FF"
    />
    <path
      d="M9.99723 16.6663C13.6791 16.6663 16.6639 13.6816 16.6639 9.99964C16.6639 6.31774 13.6791 3.33298 9.99723 3.33298C6.31533 3.33298 3.33057 6.31774 3.33057 9.99964C3.33057 13.6816 6.31533 16.6663 9.99723 16.6663Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2033 2.50843V3.79233H17.4958C17.6598 3.79139 17.8205 3.8379 17.9576 3.92603C18.0947 4.01415 18.2022 4.13997 18.2665 4.28768C18.3309 4.43541 18.3493 4.59846 18.3194 4.75637C18.2895 4.91428 18.2126 5.06003 18.0984 5.17533L16.3274 6.9773C16.2497 7.05708 16.1562 7.12058 16.0526 7.16394C15.949 7.20731 15.8375 7.22963 15.7248 7.22957H14.0504L12.3162 9.06757C12.4488 9.37707 12.516 9.70957 12.514 10.0452C12.514 10.5307 12.367 11.0054 12.0915 11.4092C11.8161 11.8129 11.4245 12.1276 10.9664 12.3134C10.5083 12.4992 10.0042 12.5479 9.51792 12.4532C9.03158 12.3584 8.58492 12.1246 8.23429 11.7812C7.88367 11.4378 7.64491 11.0004 7.54817 10.5242C7.45144 10.0479 7.50108 9.55424 7.69083 9.10557C7.88058 8.65699 8.20192 8.27352 8.61417 8.00374C9.0265 7.73397 9.51117 7.58997 10.007 7.58997C10.2371 7.58993 10.466 7.62024 10.6878 7.68007L12.6797 5.54023V4.18876C12.6798 4.0774 12.7032 3.96724 12.7483 3.86501C12.7933 3.76277 12.8592 3.6706 12.9418 3.59412L14.7818 1.88675C14.9008 1.77828 15.0495 1.70609 15.2097 1.67898C15.3698 1.65186 15.5346 1.67099 15.6838 1.73405C15.8332 1.79711 15.9604 1.90135 16.0502 2.03406C16.14 2.16678 16.1883 2.32221 16.1894 2.4814L16.2033 2.50843Z"
      fill="#313131"
    />
  </svg>
);
