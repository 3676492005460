import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

const DownloadReport = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.05 8.31337C10.3426 8.3245 10.5739 8.56516 10.5734 8.85793L10.5682 11.8512L11.7266 10.5947C11.9345 10.3769 12.2762 10.3807 12.494 10.5886C12.7034 10.7962 12.7079 11.1383 12.5 11.356L10.373 13.5458C10.1306 13.7957 9.73087 13.7814 9.50688 13.515L7.44237 11.1817C7.34629 11.0698 7.3091 10.9434 7.31385 10.8102C7.31919 10.6603 7.38253 10.5208 7.50298 10.4167C7.73496 10.2248 8.07581 10.2536 8.26797 10.4773L9.48528 11.8185C9.48528 11.8185 9.47722 10.2726 9.48658 8.85113C9.48859 8.54485 9.74397 8.30174 10.05 8.31337Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.33334 5C3.33334 3.61929 4.45262 2.5 5.83334 2.5H11.2336C11.8688 2.5 12.4801 2.74175 12.9435 3.17616L15.8765 5.9259C16.3806 6.39852 16.6667 7.05872 16.6667 7.74975V15C16.6667 16.3807 15.5474 17.5 14.1667 17.5H5.83334C4.45263 17.5 3.33334 16.3807 3.33334 15V5ZM5.83334 3.58333L11.2336 3.58333C11.3967 3.58333 11.557 3.61145 11.708 3.66511V5.8771C11.708 6.77457 12.4355 7.5021 13.333 7.5021L15.5615 7.50209C15.5759 7.58327 15.5833 7.66611 15.5833 7.74975L15.5833 15C15.5833 15.7824 14.9491 16.4167 14.1667 16.4167H5.83334C5.05093 16.4167 4.41667 15.7824 4.41667 15L4.41667 5C4.41667 4.2176 5.05093 3.58333 5.83334 3.58333ZM14.8183 6.41876L12.7913 4.51847V5.8771C12.7913 6.17626 13.0338 6.41877 13.333 6.41877L14.8183 6.41876Z"
        fill={color}
      />
    </svg>
  );
};

export const DownloadReportIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={DownloadReport}
      solidSvg={DownloadReport}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
