import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const MobileIconOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8317 14.532C10.8317 14.9139 10.5742 15.2358 10.2233 15.3333C10.1523 15.3531 10.0774 15.3637 10 15.3637C9.92263 15.3637 9.84773 15.3531 9.77667 15.3333C9.42579 15.2358 9.16829 14.9139 9.16829 14.532C9.16829 14.0726 9.54066 13.7003 10 13.7003C10.4593 13.7003 10.8317 14.0726 10.8317 14.532Z"
        fill={color}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 2.5L7.48719 2.50003C6.79805 2.50349 6.17485 2.78578 5.72465 3.23985C5.2767 3.69165 5 4.31351 5 5V15C5 16.3807 6.11929 17.5 7.5 17.5H12.5C13.8807 17.5 15 16.3807 15 15V5C15 4.22598 14.6483 3.53414 14.0959 3.07557C13.666 2.71866 13.1145 2.50306 12.5128 2.50003L12.5 2.5H7.5ZM8.57532 3.58333H11.4247V4.11916C11.4247 4.41832 11.1822 4.66083 10.883 4.66083H9.11699C8.81784 4.66083 8.57532 4.41832 8.57532 4.11916V3.58333ZM12.508 4.11916C12.508 4.15564 12.5068 4.19183 12.5044 4.22771C12.4486 5.07456 11.744 5.74416 10.883 5.74416H9.11699C8.256 5.74416 7.5514 5.07456 7.49556 4.2277C7.49319 4.19183 7.49199 4.15563 7.49199 4.11916V3.58336C7.44582 3.58361 7.40018 3.58608 7.35515 3.59065C6.97516 3.62924 6.63957 3.818 6.40866 4.09666C6.20547 4.34186 6.08333 4.65667 6.08333 5V15C6.08333 15.7824 6.7176 16.4167 7.5 16.4167H12.5C13.2824 16.4167 13.9167 15.7824 13.9167 15V5C13.9167 4.65667 13.7945 4.34187 13.5913 4.09666C13.3331 3.78499 12.9438 3.58577 12.508 3.58336V4.11916Z"
        fill={color}
      />
    </svg>
  );
};

const MobileIconSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" fill={color} />
    </svg>
  );
};

export const MobileIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={MobileIconOutlined}
      solidSvg={MobileIconSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
