import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const SmallArrowRightOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.99632 16.2111C9.81632 16.2111 9.64632 16.1411 9.51632 16.0011C9.27632 15.7311 9.29632 15.3211 9.55632 15.0811L13.0495 11.9348L9.56022 8.91699C9.29022 8.67699 9.28022 8.26699 9.52022 7.99699C9.76022 7.73699 10.1702 7.71699 10.4402 7.95699L14.4195 11.4148C14.7295 11.6948 14.7295 12.1748 14.4195 12.4548L10.4363 16.0411C10.3063 16.1611 10.1563 16.2111 9.99632 16.2111Z"
        fill={color}
      />
    </svg>
  );
};

const SmallArrowRightSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12ZM10.3567 15.1778C10.4867 15.3178 10.6567 15.3878 10.8367 15.3878C10.9967 15.3878 11.1467 15.3378 11.2767 15.2178L14.2665 12.53C14.5765 12.25 14.5765 11.77 14.2665 11.49L11.2809 8.89368C11.0109 8.65368 10.6009 8.67368 10.3609 8.93368C10.1209 9.20368 10.1309 9.61368 10.4009 9.85368L12.8965 12.01L10.3967 14.2578C10.1367 14.4978 10.1167 14.9078 10.3567 15.1778Z"
        fill={color}
      />
    </svg>
  );
};

export const SmallArrowRightIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={SmallArrowRightOutlined}
      solidSvg={SmallArrowRightSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
