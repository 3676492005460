import React from 'react';
import { BasicIcon } from '../_utils/type';

export const SettingAlarm = ({ className }: BasicIcon) => (
  <svg
    className={className}
    width="100"
    height="83"
    viewBox="0 0 100 83"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.6097 9.5848L47.6744 1.01741C45.6083 0.696067 43.491 0.529297 41.3347 0.529297C39.1784 0.529297 37.0611 0.696067 34.995 1.01741L33.0598 9.58458C30.0725 10.358 27.2498 11.5405 24.6551 13.0686L17.229 8.3792C13.7963 10.8855 10.7686 13.9133 8.26226 17.346L12.9515 24.7719C11.4234 27.3667 10.2409 30.1894 9.46733 33.1768L0.900468 35.1119C0.579123 37.1781 0.412354 39.2954 0.412354 41.4516C0.412354 43.6079 0.579123 45.7252 0.900468 47.7914L9.46734 49.7265C10.2409 52.7139 11.4234 55.5366 12.9516 58.1314L8.26226 65.5573C10.7686 68.99 13.7963 72.0178 17.229 74.5241L24.6551 69.8347C27.2498 71.3628 30.0725 72.5452 33.0598 73.3187L34.995 81.8859C37.0611 82.2072 39.1784 82.374 41.3347 82.374C43.491 82.374 45.6083 82.2072 47.6744 81.8859L49.6097 73.3185C52.5968 72.5449 55.4193 71.3625 58.0139 69.8345L65.4404 74.5241C68.8731 72.0178 71.9009 68.99 74.4072 65.5573L69.7174 58.1307C71.2454 55.5362 72.4277 52.7137 73.2012 49.7267L81.7689 47.7914C82.0903 45.7252 82.2571 43.6079 82.2571 41.4516C82.2571 39.2954 82.0903 37.1781 81.7689 35.1119L73.2012 33.1766C72.4278 30.1895 71.2454 27.3671 69.7175 24.7725L74.4072 17.346C71.9009 13.9133 68.8731 10.8855 65.4404 8.3792L58.014 13.0688C55.4194 11.5408 52.5968 10.3583 49.6097 9.5848ZM41.3347 57.4647C50.1785 57.4647 57.3478 50.2954 57.3478 41.4516C57.3478 32.6079 50.1785 25.4386 41.3347 25.4386C32.4909 25.4386 25.3216 32.6079 25.3216 41.4516C25.3216 50.2954 32.4909 57.4647 41.3347 57.4647Z"
      fill="url(#paint0_linear_1059_42404)"
    />
    <path
      d="M56.2901 48.6215C56.4199 47.3975 57.5174 46.5105 58.7414 46.6403L79.9581 48.8902C81.1821 49.02 82.0692 50.1175 81.9394 51.3415C81.8096 52.5655 80.7121 53.4525 79.4881 53.3227L77.5424 53.1164C76.1326 52.9668 74.8171 53.8456 74.4153 55.2052L74.1546 56.0876C73.3681 58.7489 70.7933 60.4688 68.0337 60.1762C65.2741 59.8835 63.1172 57.6618 62.9064 54.8948L62.8365 53.9773C62.7288 52.5637 61.6269 51.4286 60.217 51.2791L58.2713 51.0728C57.0474 50.943 56.1603 49.8455 56.2901 48.6215Z"
      fill="#FFBD58"
    />
    <path
      d="M84.4271 55.0551L53.6002 51.7861C50.4604 51.4531 48.185 48.6379 48.518 45.4981C48.6707 44.0575 49.3647 42.7286 50.4596 41.7801L53.9896 38.7218C55.9511 37.0224 57.3591 34.7749 58.0323 32.2685L61.0165 21.1576C61.5824 19.0508 63.3379 17.4762 65.4936 17.1419L66.8575 16.9304C68.8336 16.624 70.2696 14.8901 70.2025 12.8916L70.1749 12.0689C70.109 10.1036 71.7962 8.53392 73.7517 8.74129C75.7071 8.94865 77.0276 10.8373 76.5509 12.7451L76.3514 13.5436C75.8667 15.4837 76.9071 17.4802 78.775 18.1942L80.0641 18.687C82.1018 19.466 83.488 21.3738 83.5994 23.5524L84.1871 35.0421C84.3196 37.6339 85.2249 40.1268 86.7864 42.1997L89.5964 45.9303C90.4681 47.0874 90.8679 48.5323 90.7151 49.9729C90.3822 53.1127 87.5669 55.3881 84.4271 55.0551Z"
      fill="url(#paint1_linear_1059_42404)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1059_42404"
        x1="41.7937"
        y1="13.9566"
        x2="41.7937"
        y2="103.904"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3A83F1" />
        <stop offset="1" stopColor="#3C64B1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1059_42404"
        x1="73.7517"
        y1="8.74129"
        x2="69.0137"
        y2="53.4206"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFEA7C" />
        <stop offset="1" stopColor="#FFD46F" />
      </linearGradient>
    </defs>
  </svg>
);
