import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const SmallCloseOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8028 9.11807C16.0589 8.86899 16.0665 8.4575 15.8197 8.19899C15.5729 7.94048 15.1653 7.93284 14.9092 8.18193L12 11.0388L9.09077 8.18193C8.83466 7.93284 8.427 7.94048 8.18024 8.19899C7.93347 8.4575 7.94104 8.86899 8.19714 9.11807L11.0719 11.9415L8.19711 14.7649C7.94101 15.0139 7.93344 15.4254 8.18021 15.6839C8.42698 15.9425 8.83463 15.9501 9.09074 15.701L12 12.8441L14.9092 15.701C15.1653 15.9501 15.573 15.9425 15.8197 15.6839C16.0665 15.4254 16.0589 15.0139 15.8028 14.7649L12.928 11.9415L15.8028 9.11807Z"
        fill={color}
      />
    </svg>
  );
};

const SmallCloseSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM14.8197 9.19899C15.0665 9.4575 15.0589 9.86899 14.8028 10.1181L12.928 11.9415L14.8028 13.7649C15.0589 14.014 15.0665 14.4254 14.8197 14.6839C14.573 14.9425 14.1653 14.9501 13.9092 14.701L12 12.8441L10.0907 14.701C9.83463 14.9501 9.42698 14.9425 9.18021 14.6839C8.93344 14.4254 8.94101 14.014 9.19711 13.7649L11.0719 11.9415L9.19714 10.1181C8.94104 9.86899 8.93347 9.4575 9.18024 9.19899C9.427 8.94048 9.83466 8.93284 10.0908 9.18193L12 11.0388L13.9092 9.18193C14.1653 8.93284 14.5729 8.94048 14.8197 9.19899Z"
        fill={color}
      />
    </svg>
  );
};

export const SmallCloseIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={SmallCloseOutlined}
      solidSvg={SmallCloseSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
