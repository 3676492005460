import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const ArrowDownOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.8208 11.5C5.8208 11.32 5.8908 11.15 6.0308 11.02C6.3008 10.78 6.7108 10.8 6.9508 11.06L11.3508 15.8956L11.3508 4.99998C11.3508 4.64099 11.6418 4.34998 12.0008 4.34998C12.3598 4.34998 12.6508 4.64099 12.6508 4.99998L12.6508 15.8956L17.0508 11.06C17.2908 10.79 17.7008 10.78 17.9708 11.02C18.2308 11.26 18.2508 11.67 18.0108 11.94L12.5208 17.98C12.2408 18.29 11.7608 18.29 11.4808 17.98L5.9908 11.94C5.8708 11.81 5.8208 11.66 5.8208 11.5Z"
        fill={color}
      />
    </svg>
  );
};

const ArrowDownSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3ZM7.44183 12.5C7.44183 12.32 7.51183 12.15 7.65183 12.02C7.92183 11.78 8.33183 11.8 8.57183 12.06L11.4055 14.9476V8C11.4055 7.64102 11.6965 7.35 12.0555 7.35C12.4145 7.35 12.7055 7.64102 12.7055 8V14.9406L15.5218 12.04C15.7618 11.77 16.1718 11.76 16.4418 12C16.7018 12.24 16.7218 12.65 16.4818 12.92L12.5755 16.98C12.2955 17.29 11.8155 17.29 11.5355 16.98L7.61182 12.94C7.49183 12.81 7.44183 12.66 7.44183 12.5Z"
        fill={color}
      />
    </svg>
  );
};

export const ArrowDownIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={ArrowDownOutlined}
      solidSvg={ArrowDownSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
