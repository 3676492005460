import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

export const DownloadOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.21874 11.2522C6.21874 11.0722 6.28874 10.9022 6.42874 10.7722C6.69874 10.5322 7.10874 10.5522 7.34874 10.8122L11.3509 14.6386L11.3509 4.65C11.3509 4.29101 11.6419 4 12.0009 4C12.3599 4 12.6509 4.29101 12.6509 4.65V14.5752L16.2987 10.7322C16.5387 10.4622 16.9487 10.4522 17.2187 10.6922C17.4787 10.9322 17.4987 11.3422 17.2587 11.6122L12.5209 16.63C12.2409 16.94 11.7609 16.94 11.4809 16.63L6.38874 11.6922C6.26874 11.5622 6.21874 11.4122 6.21874 11.2522Z"
        fill={color}
      />
      <path
        d="M4.3501 19.5C4.3501 19.141 4.64111 18.85 5.0001 18.85H19.0001C19.3591 18.85 19.6501 19.141 19.6501 19.5C19.6501 19.859 19.3591 20.15 19.0001 20.15H5.0001C4.64111 20.15 4.3501 19.859 4.3501 19.5Z"
        fill={color}
      />
    </svg>
  );
};

const DownloadSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12ZM12.6837 6.65088C12.6837 6.2919 12.3927 6.00088 12.0337 6.00088C11.6747 6.00088 11.3837 6.2919 11.3837 6.65088L11.3837 11.7852L9.42001 9.68999C9.18001 9.42999 8.77001 9.40999 8.50001 9.64999C8.36001 9.77999 8.29001 9.94999 8.29001 10.13C8.29001 10.29 8.34001 10.44 8.46001 10.57L11.5137 13.78C11.7937 14.09 12.2737 14.09 12.5537 13.78L15.54 10.57C15.78 10.3 15.76 9.88999 15.5 9.64999C15.23 9.40999 14.82 9.41999 14.58 9.68999L12.6837 11.7373L12.6837 6.65088ZM7.35 16.65C7.35 16.291 7.64102 16 8 16H16C16.359 16 16.65 16.291 16.65 16.65C16.65 17.009 16.359 17.3 16 17.3H8C7.64102 17.3 7.35 17.009 7.35 16.65Z"
        fill={color}
      />
    </svg>
  );
};

export const DownloadIcon = ({ size, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={DownloadOutlined}
      solidSvg={DownloadSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
