import React from 'react';

export const TooltipNoChanges = () => {
  return (
    <svg width="14" height="6" viewBox="0 0 14 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.999976 0.5C0.64099 0.5 0.349976 0.791016 0.349976 1.15C0.349976 1.50899 0.64099 1.8 0.999976 1.8L13 1.8C13.359 1.8 13.65 1.50899 13.65 1.15C13.65 0.791015 13.359 0.5 13 0.5H0.999976Z"
        fill="#8A96A8"
      />
      <path
        d="M0.999976 4.19995C0.64099 4.19995 0.349976 4.49097 0.349976 4.84995C0.349976 5.20894 0.64099 5.49995 0.999976 5.49995L13 5.49995C13.359 5.49995 13.65 5.20894 13.65 4.84995C13.65 4.49097 13.359 4.19995 13 4.19995L0.999976 4.19995Z"
        fill="#8A96A8"
      />
    </svg>
  );
};
