import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const EmailReadOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.7637 8.41687L14.1008 4.21825C12.7943 3.24958 11.0038 3.26181 9.71069 4.24824L4.43721 8.27992C3.57452 8.80565 3 9.75455 3 10.8405V17.5027C3 19.1612 4.34 20.5 6 20.5H18C19.66 20.5 21 19.1612 21 17.5027V10.8405C21 9.84314 20.5118 8.96138 19.7637 8.41687ZM13.3261 5.26128C12.4849 4.63762 11.3322 4.64549 10.4996 5.28059C10.029 5.63955 9.54002 6.01225 9.04464 6.38982C7.68386 7.42699 6.27491 8.50088 5.06713 9.42761L10.4858 13.5069C11.3231 14.1371 12.4769 14.1371 13.3142 13.5069L18.8384 9.34819L13.3261 5.26128ZM19.6354 10.3745L15.3527 13.5985L17.9233 15.5309C18.2101 15.7465 18.2677 16.1537 18.0519 16.4403C17.8361 16.7269 17.4286 16.7844 17.1417 16.5688L14.2724 14.4118L14.0965 14.5442C12.7961 15.5232 11.0039 15.5232 9.70352 14.5442L9.52769 14.4118L6.65838 16.5688C6.37152 16.7844 5.96401 16.7269 5.74818 16.4403C5.53235 16.1537 5.58993 15.7465 5.87678 15.5309L8.44734 13.5985L4.33371 10.5018C4.3116 10.6112 4.3 10.7245 4.3 10.8405V17.5027C4.3 18.4418 5.06 19.2012 6 19.2012H18C18.94 19.2012 19.7 18.4418 19.7 17.5027V10.8405C19.7 10.6789 19.6775 10.5225 19.6354 10.3745Z"
        fill={color}
      />
    </svg>
  );
};

const EmailReadSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.1008 4.19887C15.8145 5.47061 17.673 6.66059 19.3316 8.02293C19.6741 8.30427 19.6533 8.82869 19.299 9.09495L13.5618 13.4056C12.6366 14.1007 11.3634 14.1007 10.4382 13.4056L10.0506 13.1143C10.0233 13.09 9.99435 13.0683 9.96404 13.0493L4.69881 9.09329C4.34445 8.82705 4.32369 8.30266 4.66503 8.01992C6.27669 6.68493 8.0558 5.49521 9.71069 4.22889C11.0038 3.24158 12.7943 3.22934 14.1008 4.19887Z"
        fill={color}
      />
      <path
        d="M4.23265 10.3691C3.73026 9.99162 3 10.1987 3 10.8271V17.4951C3 19.1551 4.34 20.4951 6 20.4951H18C19.66 20.4951 21 19.1551 21 17.4951V10.8271C21 10.1985 20.2691 9.99206 19.7666 10.3696L15.465 13.6016L18.0091 15.5159C18.296 15.7317 18.3536 16.1392 18.1378 16.4261C17.9219 16.7129 17.5144 16.7705 17.2276 16.5547L14.3831 14.4145L14.3427 14.4449C12.9549 15.4876 11.0451 15.4876 9.6573 14.4449L9.61688 14.4145L6.77245 16.5547C6.48559 16.7705 6.07808 16.7129 5.86225 16.4261C5.64642 16.1392 5.704 15.7317 5.99085 15.5159L8.535 13.6017L4.23265 10.3691Z"
        fill={color}
      />
    </svg>
  );
};

export const EmailReadIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={EmailReadOutlined}
      solidSvg={EmailReadSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
