import clsx from 'clsx';
import React from 'react';
import './headline.scss';
import { getColorClass } from '../../../styles';
import { HeadlineProps } from './types';

export const Headline = function Headline({ props, level, children, size, bold, align, color }: HeadlineProps) {
  const Element: React.ElementType = `h${level}`;

  return (
    <Element
      {...props}
      className={clsx(
        props?.className,
        `headline_size_${size}`,
        typeof bold === 'boolean' && {
          headline_weight_bold: bold,
          headline_weight_normal: !bold,
        },
        align && `headline_align_${align}`,
        color && getColorClass(color),
      )}
    >
      {children}
    </Element>
  );
};
