import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

export const SmallArrowUpOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.2113 14.0038C16.2113 14.1838 16.1413 14.3538 16.0013 14.4838C15.7313 14.7238 15.3213 14.7038 15.0813 14.4438L11.935 10.9506L8.91724 14.4399C8.67724 14.7099 8.26724 14.7199 7.99724 14.4799C7.73724 14.2399 7.71724 13.8299 7.95724 13.5599L11.415 9.58064C11.695 9.27064 12.175 9.27064 12.455 9.58064L16.0413 13.5638C16.1613 13.6938 16.2113 13.8438 16.2113 14.0038Z"
        fill={color}
      />
    </svg>
  );
};

const SmallArrowUpSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM15.1778 13.6433C15.3178 13.5133 15.3878 13.3433 15.3878 13.1633C15.3878 13.0033 15.3378 12.8533 15.2178 12.7233L12.53 9.73354C12.25 9.42354 11.77 9.42354 11.49 9.73354L8.89368 12.7191C8.65368 12.9891 8.67368 13.3991 8.93368 13.6391C9.20368 13.8791 9.61368 13.8691 9.85368 13.5991L12.01 11.1035L14.2578 13.6033C14.4978 13.8633 14.9078 13.8833 15.1778 13.6433Z"
        fill={color}
      />
    </svg>
  );
};

export const SmallArrowUpIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={SmallArrowUpOutlined}
      solidSvg={SmallArrowUpSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
