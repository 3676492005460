import React from 'react';
import { IconWrapper, IIconProps, TSvgProps } from './_utils';

export const PostAutomatOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6497 10.6968C14.2631 10.6968 13.9497 11.0102 13.9497 11.3968V12.6031C13.9497 12.9897 14.2631 13.3031 14.6497 13.3031H16.6537C17.0403 13.3031 17.3537 12.9897 17.3537 12.6031V11.3968C17.3537 11.0102 17.0403 10.6968 16.6537 10.6968H14.6497Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 4C5.34315 4 4 5.34315 4 7V18.7C4 19.418 4.58203 20 5.3 20H18.7C19.418 20 20 19.418 20 18.7V7C20 5.34315 18.6569 4 17 4H7ZM17 5.3H13.3501C12.9635 5.3 12.6501 5.6134 12.6501 6V18C12.6501 18.3866 12.9635 18.7 13.3501 18.7H18C18.3866 18.7 18.7 18.3866 18.7 18V7C18.7 6.06112 17.9389 5.3 17 5.3ZM7 5.3H10.6501C11.0367 5.3 11.3501 5.6134 11.3501 6V8.21528C11.3501 8.60188 11.0367 8.91528 10.6501 8.91528L6 8.91528C5.6134 8.91528 5.3 8.60188 5.3 8.21528V7C5.3 6.06112 6.06112 5.3 7 5.3ZM6 10.2153C5.6134 10.2153 5.3 10.5287 5.3 10.9153V13.1307C5.3 13.5173 5.6134 13.8307 6 13.8307H10.6501C11.0367 13.8307 11.3501 13.5173 11.3501 13.1307V10.9153C11.3501 10.5287 11.0367 10.2153 10.6501 10.2153L6 10.2153ZM6 18.7C5.6134 18.7 5.3 18.3866 5.3 18V15.8307C5.3 15.4441 5.6134 15.1307 6 15.1307L10.6501 15.1307C11.0367 15.1307 11.3501 15.4441 11.3501 15.8307V18C11.3501 18.3866 11.0367 18.7 10.6501 18.7H6Z"
        fill={color}
      />
    </svg>
  );
};

const PostAutomatSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7 4H10.6501C11.0367 4 11.3501 4.3134 11.3501 4.7V8.21528C11.3501 8.60188 11.0367 8.91528 10.6501 8.91528L4.7 8.91528C4.3134 8.91528 4 8.60188 4 8.21528V7C4 5.34315 5.34315 4 7 4Z"
        fill={color}
      />
      <path
        d="M4.7 10.2153C4.3134 10.2153 4 10.5287 4 10.9153V13.1307C4 13.5173 4.3134 13.8307 4.7 13.8307H10.6501C11.0367 13.8307 11.3501 13.5173 11.3501 13.1307V10.9153C11.3501 10.5287 11.0367 10.2153 10.6501 10.2153L4.7 10.2153Z"
        fill={color}
      />
      <path
        d="M4 18.7V15.8307C4 15.4441 4.3134 15.1307 4.7 15.1307L10.6501 15.1307C11.0367 15.1307 11.3501 15.4441 11.3501 15.8307V19.3C11.3501 19.6866 11.0367 20 10.6501 20H5.3C4.58203 20 4 19.418 4 18.7Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7 20H13.3501C12.9635 20 12.6501 19.6866 12.6501 19.3V4.7C12.6501 4.3134 12.9635 4 13.3501 4H17C18.6569 4 20 5.34315 20 7V18.7C20 19.418 19.418 20 18.7 20ZM14.6497 10.1814C14.2631 10.1814 13.9497 10.4948 13.9497 10.8814V13.1185C13.9497 13.5051 14.2631 13.8185 14.6497 13.8185H18C18.3866 13.8185 18.7 13.5051 18.7 13.1185V10.8814C18.7 10.4948 18.3866 10.1814 18 10.1814H14.6497Z"
        fill={color}
      />
    </svg>
  );
};

export const PostAutomatIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={PostAutomatOutlined}
      solidSvg={PostAutomatSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
