import React from 'react';

export const VkIntegrationsIconDisabled = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="20" cy="20" r="19.5" fill="white" stroke="#EFF2F5" />
    <g clipPath="url(#clip0_4643_49424)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.32 8H23.68C30.4 8 32 9.6 32 16.32V23.68C32 30.4 30.4 32 23.68 32H16.32C9.6 32 8 30.4 8 23.68V16.32C8 9.6 9.6 8 16.32 8Z"
        fill="#C4CAD4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.6529 16.2683C27.7641 15.8975 27.6529 15.625 27.1237 15.625H25.3737C24.9287 15.625 24.7236 15.8604 24.6123 16.1199C24.6123 16.1199 23.7224 18.2891 22.4617 19.6982C22.0538 20.106 21.8684 20.2358 21.6459 20.2358C21.5347 20.2358 21.3737 20.106 21.3737 19.7352V16.2683C21.3737 15.8233 21.2445 15.625 20.8737 15.625H18.1237C17.8456 15.625 17.6784 15.8315 17.6784 16.0272C17.6784 16.4491 18.3087 16.5463 18.3737 17.7329V20.31C18.3737 20.875 18.2716 20.9774 18.0492 20.9774C17.4559 20.9774 16.0128 18.7986 15.1569 16.3053C14.9892 15.8208 14.8209 15.625 14.3737 15.625H12.6237C12.1237 15.625 12.0237 15.8604 12.0237 16.1199C12.0237 16.5834 12.617 18.8824 14.7861 21.923C16.2322 23.9994 18.2697 25.125 20.1237 25.125C21.2361 25.125 21.3737 24.875 21.3737 24.4444V22.875C21.3737 22.375 21.4791 22.2752 21.8313 22.2752C22.0909 22.2752 22.5358 22.405 23.5741 23.4062C24.7606 24.5927 24.9563 25.125 25.6237 25.125H27.3737C27.8737 25.125 28.1237 24.875 27.9795 24.3816C27.8216 23.8899 27.2551 23.1765 26.5034 22.3308C26.0955 21.8488 25.4837 21.3297 25.2983 21.0701C25.0387 20.7364 25.1129 20.5881 25.2983 20.2914C25.2983 20.2914 27.4304 17.288 27.6529 16.2683Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4643_49424">
        <rect width="24" height="24" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);
