import React from 'react';
import { TSvgProps, IconWrapper, IIconProps } from './_utils';

const LeftDoubleOutlined = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4833 7.99882C11.3533 7.85882 11.1833 7.78882 11.0033 7.78882C10.8433 7.78882 10.6933 7.83882 10.5633 7.95882L6.58016 11.5451C6.27016 11.8251 6.27016 12.3051 6.58016 12.5851L10.5594 16.0429C10.8294 16.2829 11.2394 16.2629 11.4794 16.0029C11.7194 15.7329 11.7094 15.3229 11.4394 15.0829L7.95015 12.0651L11.4433 8.91882C11.7033 8.67882 11.7233 8.26882 11.4833 7.99882Z"
        fill={color}
      />
      <path
        d="M15.4833 7.99882C15.3533 7.85882 15.1833 7.78882 15.0033 7.78882C14.8433 7.78882 14.6933 7.83882 14.5633 7.95882L10.5802 11.5451C10.2702 11.8251 10.2702 12.3051 10.5802 12.5851L14.5594 16.0429C14.8294 16.2829 15.2394 16.2629 15.4794 16.0029C15.7194 15.7329 15.7094 15.3229 15.4394 15.0829L11.9502 12.0651L15.4433 8.91882C15.7033 8.67882 15.7233 8.26882 15.4833 7.99882Z"
        fill={color}
      />
    </svg>
  );
};

const LeftDoubleSolid = ({ color, size }: TSvgProps) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM10.9621 15.3311C11.1421 15.3311 11.3121 15.2612 11.4421 15.1211C11.6821 14.8511 11.6621 14.4411 11.4021 14.2011L8.9023 11.9533L11.3979 9.79701C11.6679 9.55701 11.6779 9.14701 11.4379 8.87701C11.1979 8.61701 10.7879 8.59701 10.5179 8.83701L7.5323 11.4333C7.2223 11.7133 7.2223 12.1933 7.5323 12.4733L10.5221 15.1611C10.6521 15.2812 10.8021 15.3311 10.9621 15.3311ZM14.862 15.3312C15.042 15.3312 15.212 15.2612 15.342 15.1212C15.582 14.8512 15.562 14.4412 15.302 14.2012L12.8022 11.9533L15.2978 9.79703C15.5678 9.55703 15.5778 9.14703 15.3378 8.87703C15.0978 8.61703 14.6878 8.59703 14.4178 8.83703L11.4322 11.4333C11.1222 11.7133 11.1222 12.1933 11.4322 12.4733L14.422 15.1612C14.552 15.2812 14.702 15.3312 14.862 15.3312Z"
        fill={color}
      />
    </svg>
  );
};

export const LeftDoubleIcon = ({ size = 20, color, onClick, className, iconType, hasSafebox }: IIconProps) => {
  return (
    <IconWrapper
      outlinedSvg={LeftDoubleOutlined}
      solidSvg={LeftDoubleSolid}
      size={size}
      color={color}
      iconType={iconType}
      hasSafebox={hasSafebox}
      onClick={onClick}
      className={className}
    />
  );
};
